<template>
  <div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-md-3">
          <div>Recipient name*</div>
          <input type="text" class="inputfield" v-model.trim="recipient.name">
          <div v-if="$v.recipient.name.$error">
            <div class="form-field-error" v-if="!$v.recipient.name.required">Recipient name is required</div>
          </div>
        </div>
        <div class="col-md-3">
          <div>Recipient email*</div>
          <input type="text" class="inputfield" v-model.trim="recipient.email">
          <div v-if="$v.recipient.email.$error">
            <div class="form-field-error" v-if="!$v.recipient.email.required">Email is required</div>
            <div class="form-field-error" v-if="!$v.recipient.email.email">Email seems invalid</div>
            <div class="form-field-error" v-else-if="!$v.recipient.email.validChars">å, ä, ö are not allowed characters</div>
          </div>
        </div>
        <div class="col-md-3">
          <div>Recipient phone*</div>
          <input type="text" class="inputfield" v-model.trim="recipient.phone">
          <div v-if="$v.recipient.phone.$error">
            <div class="form-field-error" v-if="!$v.recipient.phone.required">Phone number is required</div>
            <div class="form-field-error" v-if="!$v.recipient.phone.phoneFormat">Phone number format seems invalid</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-4">
          <hr />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-3">
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveRecipient(create=false)" v-if="editRecipient">Save changes</button>
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveRecipient(create=true)" v-else>Create new recipient</button>
        </div>
        <div class="col-md-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'addresses', action: 'edit', itemId: address.id }}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to address</button>
          </router-link>
        </div>
        <div class="col-md-3">
        </div>
        <div class="col-md-3">
          <button v-if="editRecipient" class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="$emit('deleteRecipientModal', recipient.id)">Delete recipient</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditRecipient',
  props: [
    "address",
    "recipientData"
  ],
  data() {
    return {
      recipient: {
        delivery_address: this.address.id,
        name: null,
        email: null,
        phone: null,
      },
      updating: false
    }
  },
  validations: {
    recipient: {
      name: {
        required
      },
      email: {
        email,
        required,
        validChars(email){
          if(email === "" || email === null){
            return true
          }
          // Check email doesn't contain å, ä, ö
          return !['å', 'ä', 'ö'].some(e => email.includes(e))
        },
      },
      phone: {
        required,
        phoneFormat(phone){
          if(phone === "" || phone === null){
            return true
          }
          return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)
        }
      }
    }
  },
  mounted(){
    if(this.editRecipient && this.recipientData && this.recipientData.id){
      // make a copy of recipientData
      this.recipient = {...this.recipientData}
    }
  },
  computed: {
    editRecipient(){
      return this.$route.params.recipient != 'new';
    }
  },
  methods: {
    saveRecipient(create){
      this.updating = true;
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.updating = false;
        return;
      }
      const vm = this;
      let data = {...this.recipient};
      const storeId = this.$store.getters.activeStore.id;
      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + storeId + '/addresses/' + this.address.id + '/recipients/' + this.recipient.id + '/';
      let method = 'PUT';
      if(create) {
        api_url = process.env.VUE_APP_API_URL + '/api/stores/' + storeId + '/addresses/' + this.address.id + '/recipients/';
        method = 'POST';
      }
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        if(create){
          // add new recipient to VUEX
          vm.$store.dispatch('addRecipientToAddress', response.data)
          vm.$toastr.s("New recipient added.");
          setTimeout(function() {
            vm.updating = false;
            vm.$router.push({ name: 'storemanagement', params: { tab: 'addresses', action: 'edit', itemId: vm.address.id }});
          },2500);
        } else {
          // update recipient to VUEX
          vm.$store.dispatch('updateRecipientToAddress', response.data)
          vm.$toastr.s("Your changes have been saved.");
          vm.updating = false;
          vm.$emit('refreshAddress');
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
  }
}

</script>
