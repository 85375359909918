<template>
  <div v-if="createEditRecipient">
    <DeleteRecipientModal v-if="showDeleteRecipientModal" v-bind:delRecipient="delRecipient" @closeDeleteRecipientModal="closeDeleteRecipientModal" @deleteRecipient="deleteRecipient" />
    <CreateEditRecipient v-bind:address="address"  v-bind:recipientData="recipientData" @refreshAddress="refreshAddress" @deleteRecipientModal="deleteRecipientModal" />
  </div>
  <div v-else>
    <!-- Modal  -->
    <div v-if="showModal" class="modal fade in show modal-active modal-open" id="deleteMemberModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Delete delivery address</h4>
            <button type="button" class="close" v-on:click="hideModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Are you sure you want to delete the delivery address {{ address.name }}?
              </div>
            </div>
          </div><!-- Modal body end -->
          <!-- Modal footer -->
          <div class="modal-footer">
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-outline-primary btn-block" v-on:click="hideModal()">Cancel</button></div>
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="deleteAddress()">Delete address</button></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop show"></div>
    <div class="row" v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-md-3">
          <div>Show only to user group(s)</div>
          <v-select
            label="name"
            v-model="address.groups"
            :options="$store.getters.nonAdminGroups"
            :reduce="s => s.id"
            :clearable="true"
            multiple
            placeholder="All user groups"
          ></v-select>
        </div>
        <div class="col-md-3">
          <div>Address name*</div>
          <input type="text" class="inputfield" v-model.trim="address.name">
          <div v-if="$v.address.name.$error">
            <div class="form-field-error" v-if="!$v.address.name.required">Address name is required</div>
          </div>
          <div class="mt-4">Street address*</div>
          <input type="text" class="inputfield" v-model.trim="address.address">
          <div v-if="$v.address.address.$error">
            <div class="form-field-error" v-if="!$v.address.address.required">Street address is required</div>
          </div>
          <div class="mt-4">Entrance, apt, floor, etc. (optional)</div>
          <input type="text" class="inputfield" v-model="address.address_extra">
          <div class="mt-4">Recipient name*</div>
          <input type="text" class="inputfield" v-model.trim="address.recipient_name">
          <div v-if="$v.address.recipient_name.$error">
            <div class="form-field-error" v-if="!$v.address.recipient_name.required">Recipient name is required</div>
          </div>
        </div>
        <div class="col-md-3">
          <div>Postal code*</div>
          <input type="text" class="inputfield" :maxlength="12" v-model.trim="address.zip_code">
          <div v-if="$v.address.zip_code.$error">
            <div class="form-field-error" v-if="!$v.address.zip_code.required">Postal code is required</div>
            <div class="form-field-error" v-if="!$v.address.zip_code.maxlength && $v.address.zip_code.required">Max length 12 characters</div>
          </div>
          <div class="mt-4">City*</div>
          <input type="text" class="inputfield" v-model.trim="address.city">
          <div v-if="$v.address.city.$error">
            <div class="form-field-error" v-if="!$v.address.city.required">City is required</div>
          </div>
          <div class="mt-4">Country*</div>
          <v-select
            label="name"
            v-model="address.country.code"
            :options="countries"
            :reduce="s => s.value"
            :clearable="false"
            placeholder="Select country"
          ></v-select>
          <div v-if="$v.address.country.code.$error">
            <div class="form-field-error" v-if="!$v.address.country.code.required">Country is required</div>
          </div>
        </div>
        <div class="col-md-3">
          <div>Recurring delivery notes</div>
          <div><textarea rows=10 v-model="address.notes" /></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-4">
          <hr />
        </div>
      </div>
      <div v-if="editAddress">
        <div class="row">
          <div class="col-md-3">
            Recipient name
          </div>
          <div class="col-md-3">
            Recipient email
          </div>
          <div class="col-md-3">
            Recipient phone
          </div>
          <div class="col-md-3">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr />
          </div>
        </div>
        <div v-for="recipient in address.recipients" v-bind:key="recipient.id">
          <div class="row">
            <div class="col-md-3">
              {{recipient.name}}
            </div>
            <div class="col-md-3">
              {{recipient.email}}
            </div>
            <div class="col-md-5">
              {{recipient.phone}}
            </div>
            <div class="col-md-1">
              <router-link :to="{ name: 'recipientediting', params: {  tab: 'addresses', action: 'edit', itemId: address.id, recipient: recipient.id  }}">
                Edit
              </router-link>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <router-link :to="{ name: 'recipientediting', params: {  tab: 'addresses', action: 'edit', itemId: address.id, recipient: 'new'  }}">
              <button class="btn btn-lg btn-outline-primary btn-block mt-2">+ Add new recipient</button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row" v-if="editAddress">
        <div class="col-md-12 mt-2">
          <hr />
        </div>
      </div>
      <div class="row" :class="{'mb-5': !editAddress}">
        <div class="col-md-3">
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveAddress(create=false)" v-if="editAddress">Save changes</button>
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveAddress(create=true)" v-else>Create new address</button>
        </div>
        <div class="col-md-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'addresses' }}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to delivery addresses</button>
          </router-link>
        </div>
      </div>
      <div v-if="editAddress">
        <div class="row">
          <div class="col-md-12">
            <hr class="mt-4" />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-3">
            <button @click="showModal = true" class="btn btn-lg btn-outline-primary btn-block mt-3">Delete address</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import CreateEditRecipient from './CreateEditRecipient.vue'
import DeleteRecipientModal from './DeleteRecipientModal.vue'

export default {
  name: 'CreateEditAddress',
  props: [
    "editAddress",
    "addressData"
  ],
  components: {
    CreateEditRecipient,
    DeleteRecipientModal
  },
  data() {
    return {
      address: {country: {}},
      newAddress: {
        name: null,
        address: null,
        address_extra: null,
        recipient_name: null,
        zip_code: null,
        city: null,
        country: {code: 'FI'},
        notes: null,
        groups: []
      },
      updating: false,
      showModal: false,
      delRecipient: {},
      showDeleteRecipientModal: false,
    }
  },
  validations: {
    address: {
      name: {
        required
      },
      address: {
        required
      },
      recipient_name: {
        required
      },
      zip_code: {
        required,
        maxLength: maxLength(12)
      },
      city: {
        required
      },
      country: {
        code: {
          required
        }
      }
    }
  },
  mounted(){
    if(this.editAddress && this.addressData && this.addressData.id){
      // make a copy of addressData
      this.address = {...this.addressData}
    } else {
      this.address = {...this.newAddress}
    }
  },
  computed: {
    countries(){
      if(!this.$store.getters['admin/countriesFetched']){
        this.$store.dispatch('admin/fetchCountries')
      }
      const countries = this.$store.getters['admin/countries'].map(c => ({name: c[1], value: c[0]}))
      // remove duplicates
      return countries.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i);
    },
    createEditRecipient(){
      return this.$route.params.recipient;
    },
    recipientData(){
      if(this.$route.params.recipient && this.address && this.address.recipients){
        const recipientId = this.$route.params.recipient;
        const recipient = this.address.recipients.find(r => r.id == recipientId);
        return recipient;
      }
      return {};
    }
  },
  watch: {
    addressData: function(newVal){
      // Watch if addressData is updated later after page refresh
      if(this.editAddress && !this.address.id){
        this.address = {...newVal};
      }
    }
  },
  methods: {
    saveAddress(create){
      this.updating = true;
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.updating = false;
        return;
      }
      const vm = this;
      let data = {...this.address};

      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/addresses/' + this.address.id + '/';
      let method = 'PUT';
      if(create) {
        api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/addresses/';
        method = 'POST';
        data.store = this.$store.getters.activeStore.id;
      }
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        if(create){
          // add address to VUEX
          vm.$store.dispatch('addAddress', response.data)
          vm.$toastr.s("New delivery address created.");
          document.body.style.cursor='default';
          setTimeout(function() {
            vm.updating = false;
            vm.$router.push({ name: 'storemanagement', params: { tab: 'addresses' }});
          },2500);
        } else {
          // update address to VUEX
          vm.$store.dispatch('updateAddress', response.data)
          if(vm.address.name != vm.addressData.name){
            // address name changed, refresh orders to get updated names
            vm.$store.dispatch('fetchOrders')
          }
          vm.$toastr.s("Your changes have been saved.");
          vm.updating = false;
          document.body.style.cursor='default';
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    hideModal(){
      this.showModal = false;
    },
    deleteAddress(){
      const vm = this;
      const address_id = this.address.id;
      if(!address_id){ return; }
      this.updating = true;
      this.hideModal();
      this.$http({
        method: 'delete',
        url: process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/addresses/' + address_id + '/',
      })
      .then(function () {
        document.body.style.cursor='default';
        vm.$store.dispatch('removeAddress', {address_id: address_id});
        vm.$toastr.s(
          "Address removed."
        );
        // redirect to address listing after deleting
        setTimeout(function() {
          vm.$router.push({ name: 'storemanagement', params: { tab: 'addresses' }});
        },2500);
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    refreshAddress(){
      this.address = {...this.addressData}
    },
    deleteRecipientModal(recipientId){
      this.delRecipient = {...this.address.recipients.find(r => r.id == recipientId)}
      this.showDeleteRecipientModal = true;
    },
    closeDeleteRecipientModal(){
      this.delRecipient = {};
      this.showDeleteRecipientModal = false;
    },
    deleteRecipient(){
      this.updating = true;
      const recipientId = this.delRecipient.id;
      const vm = this;
      const storeId = this.$store.getters.activeStore.id;
      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + storeId + '/addresses/' + this.address.id + '/recipients/' + recipientId + '/';
      const method = 'delete';
      this.$http({
        method: method,
        url: api_url
      })
      .then(function () {
        // update VUEX
        vm.$store.dispatch('deleteRecipientFromAddress', {addressId: vm.address.id, recipientId: recipientId})
        vm.$toastr.s("Recipient deleted.");
        vm.address.recipients = vm.address.recipients.filter(r => r.id != recipientId);
        vm.updating = false;
        vm.$router.push({ name: 'storemanagement', params: { tab: 'addresses', action: 'edit', itemId: vm.address.id }});
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    }
  }
}

</script>
