<template>
  <div>
    <SamAccordion
        v-if="windowWidth < mobileView"
        :title="`${filteredCarriers.length > 1 ? 'Carriers': 'Carrier'} (${filteredCarriers.length}) `"
        title-payload="filters"
        accordionId="samAccordianCarriers"
    >
      <v-select
          class="sam-input sam-select mb-4"
          v-model="nameFilter"
          :options="nameList"
          :reduce="u => u.value"
          :searchable="true"
          placeholder="All carriers"
      ></v-select>
      <v-select
          class="sam-input sam-select mb-4"
          v-model="statusFilter"
          :options="$store.getters['admin/storeCarrierStatuses'].map(s => s[0].value)"
          :custom-label="opt => $store.getters['admin/storeCarrierStatuses'].find(s => s[0] === opt)[1]"
          placeholder="Status"
          :searchable="true"
          :multiple="true"
          :showLabels="true"
      ></v-select>
    </SamAccordion>
    <SamTableRow v-else headerRow>
      <SamTableColumn>
        <v-select
            class="sam-input sam-select"
            v-model="nameFilter"
            :options="nameList"
            :reduce="u => u.value"
            :searchable="true"
            placeholder="All carriers"
            :disabled="!filteredCarriers.length && nameFilter == null"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
            class="sam-input sam-select"
            v-model="statusFilter"
            :options="statusList"
            :reduce="u => u.value"
            placeholder="Status"
            :searchable="true"
        ></v-select>
      </SamTableColumn>
      <!--<SamTableColumn>
        <SamParagraph text="Used in regions"/>
      </SamTableColumn>-->
    </SamTableRow>
    <div
        @click="openEdit(carrier)"
        v-for="carrier in filteredCarriers"
        v-bind:key="carrier.id"
    >
      <SamTableRow hoverable noSpace clickableRow>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Carrier name" bolded/>
          <div class="d-flex align-items-center">
            <img :src="`${env.VUE_APP_IMAGE_HOST}${carrier.logo}`" class="mr-2" width="30" height="30"/>
            <SamParagraph :text="carrier.name" tableItem/>
          </div>
        </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Connection status" bolded/>
          <div v-else>
            <component :is="renderSAMTag(carrier.id).component" v-bind="renderSAMTag(carrier.id).props"/>
          </div>
        </SamTableColumn>
        <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg'
             @click="openEdit(carrier)"/>
      </SamTableRow>

    </div>
  </div>
</template>


<script>
import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import mixin from '../mixins/mixin'

export default {
  name: 'CarrierListing',
  mixins: [mixin],
  components: {
    SamAccordion, SamTableRow, SamTableColumn, SamParagraph,
  },
  props: [
    'carriers',
    'storeCarriers'
  ],
  data() {
    return {
      nameFilter: null,
      statusFilter: null,
      carrierName: null,
      env: process.env,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted: function () {
    // Fetch productoptions if not fetched
    if (!this.$store.getters['admin/productOptsFetched']) {
      this.$store.dispatch('admin/fetchProductOptions')
    }
    setTimeout(() => this.isFetching = true, 1000);
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    nameList() {
      const names = this.carriers.map(a => {
        return {label: a.name, value: a.id}
      })
      return [...names]
    },
    statusList() {
      if (this.$store.getters['admin/storeCarrierStatuses']) {
        const statusList = this.$store.getters['admin/storeCarrierStatuses'].map(a => {
          return {label: a[1], value: a[0]}
        })
        return statusList
      } else {
        return []
      }
    },
    filteredCarriers() {
      let carriers = this.carriers;
      if (this.nameFilter) {
        carriers = carriers.filter(a => a.id == this.nameFilter)
      }
      if (this.statusFilter !== null) {
        let storecarriers = this.storeCarriers.filter(c => c.status == this.statusFilter)
        carriers = carriers.filter((elem) => {
          return storecarriers.some((item) => item.carrier === elem.id);
        });

      }
      return carriers;
    }
  },
  methods: {
    openEdit(item) {
      this.$router.push({
        name: 'storemanagement',
        params: {tab: 'carriers', action: 'edit', itemId: item.id}
      }).catch(() => {
      });
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    storeCarrierStatus(carrierId) {
      let carrierData = this.storeCarriers.find(t => t.carrier == carrierId);
      if (carrierData) {
        return carrierData.status
      } else {
        return 0
      }
    },
    getStatus(value) {
      return this.$store.getters['admin/storeCarrierStatuses'].find(c => c[0] == value)[1]
    },
  }
}
</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}

.sam-table-icon:hover {
  opacity: 1;
}

</style>
