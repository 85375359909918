import { render, staticRenderFns } from "./SupplierList.vue?vue&type=template&id=5ed8edb6&scoped=true&"
import script from "./SupplierList.vue?vue&type=script&lang=js&"
export * from "./SupplierList.vue?vue&type=script&lang=js&"
import style0 from "./SupplierList.vue?vue&type=style&index=0&id=5ed8edb6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed8edb6",
  null
  
)

export default component.exports