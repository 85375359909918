<template>
  <div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-md-3">
          <div>User group name*</div>
          <input type="text" class="inputfield" v-model="group.name" :disabled="group.admin_group">
          <div class="mt-3">Group description*</div>
          <div><textarea rows=6 v-model="group.description" :disabled="group.admin_group" /></div>
        </div>
        <div class="col-md-3">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="group.management" id="storeManCheck" :disabled="group.admin_group" @click="updateManagement($event)">
            <label class="custom-control-label mt-3 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="storeManCheck">Access to Manage store</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_users" id="manUsersCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manUsersCheck">Edit users</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_groups" id="manGroupsCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manGroupsCheck">Edit User groups</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_products" id="manProdsCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manProdsCheck">Edit Products</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_lease_products" id="manLeaseCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manLeaseCheck">Edit Lease products</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_tags" id="manTagCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manTagCheck">Edit Product tags</label>
          </div>
           <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_carriers" id="manCarrierCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manCarrierCheck">Edit Carriers</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_locations" id="manAddrCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manAddrCheck">Edit Delivery addresses</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_cost_centers" id="manCCCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manCCCheck">Edit Cost centers</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.management_settings" id="manSettCheck" :disabled="group.admin_group" @click="manPermissionOn($event)">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="manSettCheck">Edit Settings</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="group.order_history" id="orderHistCheck" :disabled="group.admin_group">
            <label class="custom-control-label mt-3 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="orderHistCheck">Access to Order history page<br />(view own orders only)</label>
          </div>
          <div class="custom-control custom-checkbox pl-4">
            <input type="checkbox" class="custom-control-input" v-model="group.order_history_all_users" id="orderHistAllCheck" :disabled="group.admin_group">
            <label class="custom-control-label mt-1 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="orderHistAllCheck">View orders, leases and analytics from all users</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="group.order_create_addresses" id="createAddresses" :disabled="group.admin_group">
            <label class="custom-control-label mt-3 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="createAddresses">Allow to save new Delivery Addresses</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" v-model="group.campaign_create" id="createCampaigns" :disabled="group.admin_group">
            <label class="custom-control-label mt-3 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="createCampaigns">Allow to create Campaigns and send Magic Links</label>
          </div>
        </div>
        <div class="col-md-3">
          <div v-if="!group.admin_group">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" v-model="group.order_approval_required" id="orderFlow" :disabled="group.admin_group">
              <label class="custom-control-label mt-3 mb-0" :class="{'cursor-not-allowed': group.admin_group}" for="orderFlow">Approve orders before delivery</label>
            </div>
            <div v-if="group.order_approval_required" class="mt-2">
              <div>Admins to approve orders</div>
              <div>
                <v-select
                  v-model="group.order_approval_admins"
                  :options="userNameList"
                  :reduce="u => u.value"
                  :searchable="true"
                  multiple
                  placeholder="Select admins"
                ></v-select>
              </div>
              <div v-if="adminSelectionRequired">
                <div class="form-field-error">Please select at least one admin</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div>Users in this group ({{groupUsers.length}})</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3" v-for="user in groupUsers" v-bind:key="user.id">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'users', action: 'edit', itemId: user.id }}">
            {{user.lname}}, {{user.fname}}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div>Products visible to this user group</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3" v-for="product in groupProducts" v-bind:key="product.id">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'products', action: 'edit', itemId: product.id }}">
            {{product.name}}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div>Delivery addresses available for this group ({{groupAddresses.length}})</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-3" v-for="address in groupAddresses" v-bind:key="address.id">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'addresses', action: 'edit', itemId: address.id }}">
            {{address.name}}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-sm-3">
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveGroup()" v-if="editGroup">Save changes</button>
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveGroup()" v-else>Create new user group</button>
        </div>
        <div class="col-sm-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'user-groups' }}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to user group listing</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateEditGroup",
  props: [
    "editGroup",
    "groupData"
  ],
  data() {
    return {
      group: {},
      newGroup: {
        name: null,
        description: null,
        admin_group: false,
        store: null,
        members: [],
        management: false,
        management_users: false,
        management_groups: false,
        management_products: false,
        management_lease_products: false,
        management_tags: false,
        management_carriers: false,
        management_locations: false,
        management_suppliers: true,
        management_cost_centers: false,
        management_settings: false,
        order_history: false,
        order_history_all_users: false,
        order_approval_required: false,
        order_approval_admins: false,
        order_create_addresses: false,
        campaign_create: false
      },
      updating: false,
      adminSelectionRequired: false,
    }
  },
  mounted(){
    if(this.editGroup && this.groupData && this.groupData.id){
      // make a copy of groupData
      this.group = {...this.groupData}
    } else {
      this.group = {...this.newGroup}
      this.group.store = this.$store.getters.activeStore.id;
    }
  },
  computed: {
    groupUsers(){
      let profiles = []
      if(this.group && this.group.members){
        const memberIds = this.group.members;
        const members = this.$store.getters['admin/storeMembers'].filter(m => memberIds.includes(m.id));

        const userIds = members.map(m => m.user);
        profiles = this.$store.getters['admin/storeProfiles'].filter(p => userIds.includes(p.user.id));
      }
      return profiles.map(p => ({'fname': p.user.first_name, 'lname': p.user.last_name, 'id': p.user.id}));
    },
    groupProducts(){
      if(this.group.admin_group){
        return this.$store.state.products;
      }
      return this.$store.state.products.filter(p => p.groups.includes(this.group.id) || p.groups.length == 0);
    },
    groupAddresses(){
      if(this.group.admin_group){
        return this.$store.state.addresses;
      }
      return this.$store.state.addresses.filter(a => a.groups.includes(this.group.id) || a.groups.length == 0);
    },
    userNameList(){
      const adminGroup = this.$store.state.groups.find(g => g.admin_group == true)
      const adminMembers = this.$store.getters['admin/storeMembers'].filter(m => adminGroup.members.includes(m.id));
      const adminUserIds = adminMembers.map(m => m.user);
      const adminProfiles = this.$store.getters['admin/storeProfiles'].filter(p => adminUserIds.includes(p.user.id));
      // use member id as value
      const names = adminProfiles.map(p => ({label: p.user.first_name + ' ' + p.user.last_name, value: adminMembers.find(m => m.user == p.user.id).id }))
      return [...names]
    },
  },
  watch: {
    groupData: function(newVal){
      // Watch if groupData is updated later after page refresh
      if(this.editGroup && !this.group.id){
        this.group = {...newVal};
      }
    }
  },
  methods: {
    saveGroup(){
      this.updating = true;
      // check approval admins
      if(this.group.order_approval_required && this.group.order_approval_admins.length == 0){
        this.adminSelectionRequired = true;
        this.$toastr.e("Select at least one admin user for order approval flow.");
        this.updating = false;
        return;
      } else if(!this.group.order_approval_required) {
        // clear admin group
        this.group.order_approval_admins = [];
        this.adminSelectionRequired = false;
      }
      // this.$v.$touch()
      // if(this.$v.$invalid) {
      //   this.updating = false;
      //   return;
      // }
      const vm = this;
      let data = {...this.group};
      let api_url = process.env.VUE_APP_API_URL + "/api/stores/" + this.$store.getters.activeStore.id + "/usergroups/" + this.group.id + "/";
      let method = 'PUT';
      if(!this.editGroup) {
        api_url = process.env.VUE_APP_API_URL + "/api/stores/" + this.$store.getters.activeStore.id + "/usergroups/";
        method = 'POST';
        data.store = this.$store.getters.activeStore.id;
      }
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        if(!vm.editGroup){
          // add new group to VUEX
          vm.$store.dispatch('addGroup', response.data)
          vm.$toastr.s("New user group created.");
          setTimeout(function() {
            vm.updating = false;
            vm.$router.push({ name: 'storemanagement', params: { tab: 'user-groups' }});
          },2500);
        } else {
          // update group to VUEX
          vm.$store.dispatch('updateGroup', response.data)
          vm.$toastr.s("Your changes have been saved.");
          vm.updating = false;
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    updateManagement(event){
      let allChecked = false;
      if(event.target.checked){
        // turn on all selections
        allChecked = true;
      } else {
        // turn off all selections
        allChecked = false;
      }
      this.group.management_users = allChecked;
      this.group.management_groups = allChecked;
      this.group.management_products = allChecked;
      this.group.management_lease_products = allChecked;
      this.group.management_tags = allChecked;
      this.group.management_carriers = allChecked;
      this.group.management_locations = allChecked;
      this.group.management_cost_centers = allChecked;
      this.group.management_settings = allChecked;
    },
    manPermissionOn(event){
      if(event.target.checked && !this.group.management){
        this.group.management = true;
      }
    }
  }
}
</script>
