<script>

import SamAccordion from '@/components/SamAccordion.vue'
import SamTableRow from '@/components/table/SamTableRow.vue'
import SamParagraph from '@/components/SamParagraph.vue'
import SamSecondaryButton from '@/components/SamSecondaryButton.vue'
import SamTableColumn from '@/components/table/SamTableColumn.vue'

export default {
  name: "SupplierList",
  components: {
    SamTableColumn,
    SamSecondaryButton,
    SamParagraph,
    SamTableRow,
    SamAccordion
  },
  props: {
    suppliers: {
      type: Array,
      default: new Array()
    },
    supplierTypes: {
      type: Array
    }
  },
  data() {
    return {
      supplierFilter: null,
      typeFilter: null,
      contactFilter: null,
      productFilter: null,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  computed: {
    supplierFilters() {
      return this.suppliers.map(s => ({label: s.name, value: s.name})).filter((obj, index, self) => {
        return self.findIndex(item => {
          return item.value === obj.value
        }) === index
      })
    },
    contactFilters() {
      return this.suppliers.map(c => ({label: c.email, value: c.email})).filter((obj, index, self) => {
        return self.findIndex(item => {
          return item.value === obj.value
        }) === index
      })
    },
    productFilters() {
      return this.suppliers.flatMap(s => [...(s.products || []), ...(s.lease_products || [])]).filter((obj, index, self) => {
        return self.findIndex(item => {
          return item.name === obj.name
        }) === index
      })
    },
    filteredSuppliers() {
      if (this.supplierFilter) {
        return this.suppliers.filter(s => (s.name === this.supplierFilter))
      }
      if (this.typeFilter) {
        return this.suppliers.filter(s => (s.type === this.typeFilter))
      }
      if (this.contactFilter) {
        return this.suppliers.filter(s => (
          s.email === this.contactFilter
        ))
      }
      if (this.productFilter) {
        return this.suppliers.filter(s => (
          s.products.some(p => p.name === this.productFilter) || s.lease_products.some(lp => lp.name === this.productFilter)
        ))
      }
      return this.suppliers
    },
  },
  methods: {
    getSupplierTypeName(type) {
      const supplierType = this.supplierTypes.find(t => t.value === type)
      return supplierType ? supplierType.label : null
    },
    getSupplierProducts(supplier) {
      return [...(supplier.products || []), ...(supplier.stock_products || []), ...(supplier.lease_products || [])]
    },
    addNewSupplier() {
      this.$router.push({ name: 'storemanagement', params: { tab: 'suppliers', action: 'new' }});
    },
    editSupplier(supplier) {
      this.$router.push({ name: 'storemanagement', params: { tab: 'suppliers', action: 'edit', itemId: supplier.id }})
    },
  }
}
</script>

<template>
  <div>
    <SamAccordion
      v-if="windowWidth < mobileView"
      title="Suppliers"
      title-payload="filters"
      accordionId="samAccordianSupplierList"
    >
      <v-select
        class="sam-input sam-select mb-4"
        v-model="supplierFilter"
        :options="supplierFilters"
        :reduce="s => s.value"
        :clearable="supplierFilter !== null"
        :searchable="true"
        placeholder="All suppliers"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="typeFilter"
        :options="supplierTypes"
        :reduce="t => t.value"
        :clearable="typeFilter !== null"
        :searchable="true"
        placeholder="All types"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="contactFilter"
        :options="contactFilters"
        :reduce="c => c.value"
        :clearable="contactFilter !== null"
        :searchable="true"
        placeholder="All contacts"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="productFilter"
        :options="productFilters"
        label="name"
        :reduce="p => p.name"
        :clearable="productFilter !== null"
        :searchable="true"
        placeholder="All products"
      ></v-select>
      <SamSecondaryButton @buttonAction="addNewSupplier()"  text="+ Add new supplier" noSpace/>
    </SamAccordion>
    <SamTableRow v-else headerRow>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="supplierFilter"
          :options="supplierFilters"
          :reduce="s => s.value"
          :searchable="true"
          placeholder="All suppliers"
          :disabled="false"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="typeFilter"
          :options="supplierTypes"
          :reduce="t => t.value"
          :searchable="true"
          placeholder="All types"
          :disabled="false"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="contactFilter"
          :options="contactFilters"
          :reduce="c => c.value"
          :searchable="true"
          placeholder="All contacts"
          :disabled="false"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="productFilter"
          :options="productFilters"
          label="name"
          :reduce="p => p.name"
          :searchable="true"
          placeholder="All products"
          :disabled="false"
        ></v-select>
      </SamTableColumn>
    </SamTableRow>
    <div
      v-for="supplier in filteredSuppliers"
      @click="editSupplier(supplier)"
      :key="supplier.id"
    >
      <SamTableRow hoverable noSpace clickableRow>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Supplier" bolded/>
          <SamParagraph :text="supplier.name" tableItem/>
        </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Types" bolded/>
          <SamParagraph :text="getSupplierTypeName(supplier.type)" tableItem/>
        </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Contact" bolded/>
          <SamParagraph :text="supplier.email" tableItem/>
        </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Products" bolded/>
          <SamParagraph v-for="product in getSupplierProducts(supplier)" :key="supplier.is + '_' + product.id" :text="product.name" tableItem/>
        </SamTableColumn>
        <SamTableColumn v-if="windowWidth < mobileView">
          <SamSecondaryButton @buttonAction="editSupplier(supplier)" text="Edit" noSpace/>
        </SamTableColumn>
        <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="editSupplier(supplier)" />
      </SamTableRow>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>
