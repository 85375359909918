// mixin.js
import SamTag from "@/components/SamTag.vue";
export default {
    components: {
        SamTag
    },
    methods: {
    renderSAMTag(id) {
            const status = this.storeCarrierStatus(id)
            let tagText;
            let alert;
            let backgroundColor = null;
            if (status === 0) {
                tagText = 'Inactive';
                alert = false;
            } else if (status === 1) {
                tagText = 'Initiated'
                alert = true;
                backgroundColor = "#3f7dbf";
            } else {
                tagText = 'Active';
                alert = true
                backgroundColor = "#3fbf52";
            }

            return {
                component: 'SamTag',
                props: {
                    text: tagText,
                    alert: alert,
                    style: `background-color: ${backgroundColor !== null? backgroundColor:''}`
                },
            };
        }
  }
};
