<template>
  <div>
    <SamAccordion
        v-if="windowWidth < mobileView"
        :class="'.d-none .d-sm-block .d-md-none'"
        :title="`${filteredTags.length > 1 ? 'Tags': 'Tag'} (${filteredTags.length}) `"
        title-payload="filters"
        accordionId="samAccordianTags"
      >
       <v-select
          class="sam-input sam-select mb-4"
          v-model="tagName"
          :options="tagNameList"
          :searchable="true"
          placeholder="All product tags"
        ></v-select>
        <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new tag" noSpace/>
     </SamAccordion>
    <SamTableRow v-else headerRow>
      <SamTableColumn>
         <v-select
          class="sam-input sam-select"
          v-model="tagName"
          :options="tagNameList"
          :searchable="true"
          placeholder="All product tags"
          :disabled="!filteredTags.length && tagName == null"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <SamParagraph text="Products with this tag"/>
      </SamTableColumn>
     </SamTableRow>
      <div 
       @click="openEdit(tag)"
        v-for="tag in filteredTags" 
        v-bind:key="tag.id"
      >
      <SamTableRow hoverable noSpace clickableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Tag name" bolded/>
            <SamParagraph :text="tag.name" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Products with this tag" bolded/>
            <SamParagraph :text="`${tag.products.length} product${tag.products.length != 1 ? 's' : ''} ${$store.getters.leaseProductsEnabled ? `and ${tag.lease_products.length} lease product${tag.lease_products.length != 1 ? 's' : ''} with this tag` : '' } `" tableItem/>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="openEdit(tag)"  text="Edit" noSpace/>
          </SamTableColumn>
          <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(tag)" />
        </SamTableRow>
     </div>
  </div>
</template>


<script>

import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'

export default {
  name: 'TagListing',
  components: {
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamParagraph,
    SamSecondaryButton
  },
  props: [
    'tags'
  ],
    data() {
    return {
      tagName: null,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted: function () {
     //Check window width
    setTimeout(() => this.isFetching = true, 1000);
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    tagNameList(){
      const names = this.tags.map(a => a.name)
      return [...names]
    },

    filteredTags(){
      let tags = this.tags;
      
      if(this.tagName != null){
        tags = tags.filter(p => p.name == this.tagName)
      }
      return tags;
    },

  },
  methods: {
    addNewItem() { 
      this.$router.push({ name: 'storemanagement', params: { tab: 'tags', action: 'new' }}).catch(() => {});
    },
     openEdit(item) {
       this.$router.push({ name: 'storemanagement', params: { tab: 'tags', action: 'edit', itemId: item.id }}).catch(() => {});
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
}
</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>
