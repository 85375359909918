<template>
  <div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <SamModal
        :showExportModal="showExportModal"
        title="Export product list."
        id="exportProductsModal"
        @close="closeExportProductsModal()"
        @save="exportList()"
        :modalIsClosing="modalIsClosing"
        saveButtonText="Export via email"
      >
        <div class="row mb-4">
          <div class="col-12">
            Do you want to export product list?
          </div>
        </div>
      </SamModal>
       <SamAccordion
          v-if="windowWidth < mobileView"
          :title="`${$store.state.admin.totalProdCount > 1 ? 'Products': 'Product'} (${$store.state.admin.totalProdCount}) `"
          title-payload="filters"
          accordionId="samAccordianProductList"
        >
            <v-select
              v-model="productNameFilter"
              class="sam-input sam-select mb-4"
              label="name"
              :reduce="p => p.name"
              :options="productNameList"
              :searchable="true"
              :disabled="!$store.state.admin.prodsFetched || !$store.state.admin.optionsFetched"
              placeholder="All products"
            ></v-select>
            <v-select
              class="sam-input sam-select mb-4"
              v-model="statusFilter"
              :options="statusList"
              :reduce="s => s.value"
              :searchable="true"
              :disabled="!$store.state.admin.prodsFetched || !$store.state.admin.optionsFetched"
              placeholder="All statuses"
            ></v-select>
               <v-select
                class="sam-input sam-select mb-4"
                label="name"
                v-model="groupFilter"
                :options="$store.getters.nonAdminGroups"
                :reduce="g => g.id"
                :searchable="true"
                :disabled="!$store.state.admin.prodsFetched || !$store.state.admin.optionsFetched"
                placeholder="Product visible to"
              ></v-select>
              <SamSecondaryButton @buttonAction="showExportProductsModal()"  text="Export as XLS"/>
              <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new products" noSpace/>
        </SamAccordion>
        <SamTableRow v-else headerRow>
          <SamTableColumn>
              <v-select
                class="sam-input sam-select"
                v-model="productNameFilter"
                label="name"
                :reduce="p => p.name"
                :options="productNameList"
                :searchable="true"
                :disabled="!$store.state.admin.prodsFetched || !$store.state.admin.optionsFetched"
                placeholder="All products"
              ></v-select>
          </SamTableColumn>
          <SamTableColumn>
              <v-select
                class="sam-input sam-select"
                v-model="statusFilter"
                :options="statusList"
                :reduce="s => s.value"
                :searchable="true"
                :disabled="!$store.state.admin.prodsFetched || !$store.state.admin.optionsFetched"
                placeholder="All statuses"
              ></v-select>
          </SamTableColumn>
          <SamTableColumn>
               <v-select
                label="name"
                class="sam-input sam-select"
                v-model="groupFilter"
                :options="$store.getters.nonAdminGroups"
                :reduce="g => g.id"
                :searchable="true"
                :disabled="!$store.state.admin.prodsFetched || !$store.state.admin.optionsFetched"
                placeholder="Product visible to"
              ></v-select>
          </SamTableColumn>
          <SamTableColumn>
            <SamSecondaryButton
              text="Export as XLS"
              noSpace
              toggleModal
              modalId="exportProductsModal"
              @buttonAction="showExportProductsModal()"
            />
          </SamTableColumn>
        </SamTableRow>
      <div v-if="!$store.state.admin.prodsFetched">
        <div class="loader-container">
          <div class="row justify-content-center">
            <div class="col-md-4 d-flex justify-content-center">
              <div class="loader">Loading...</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="windowWidth < mobileView">
          <SamTableRow
            v-for="product in productList"
            v-bind:key="product.id"
          >
            <SamTableColumn mobileColumn>
              <SamThreeColumnImage
                :imageUrl="getImageUrl(product.primary_thumb_big)"
                :additionalClass="windowWidth > mobileView ? 'mr-3' : ''"
                mobileView
                :emptyImage="!product.primary_thumb_small || product.primary_thumb_small.legth == 0"
              />
            </SamTableColumn>
            <SamTableColumn mobileColumn noPadding>
              <SamTableColumn>
                <SamParagraph :text="tableHeader[0]" bolded/>
                <SamParagraph :text="product.name" bolded/>
              </SamTableColumn>
              <SamTableColumn spaceOnTop>
                <SamParagraph :text="tableHeader[1]" bolded/>
                <SamParagraph :text="`${product.price}${$store.getters.currencyStr}`" price/>
              </SamTableColumn>
              <SamTableColumn spaceOnTop>
                <SamParagraph :text="tableHeader[2]" bolded/>
                <SamParagraph :text="getStatus(product.status)"/>
              </SamTableColumn>
              <SamTableColumn spaceOnTop>
                <SamParagraph :text="tableHeader[3]" bolded/>
                <SamParagraph v-if="product.groups.length == 0" text="All user groups"/>
                <SamParagraph
                  v-for="(g, index) of product.groups"
                  v-bind:key="g.id"
                  :text="`${$store.getters.groupNameDict[g]} ${index != product.groups.length - 1 ? ',' : 'only'}`"
                />
              </SamTableColumn>
              <SamTableColumn spaceOnTop>
                <SamParagraph :text="tableHeader[4]" bolded/>
                <SamTag
                  v-if="product.stock_management && getStockLevels(product).length == 1 && getStockLevels(product)[0][1]==0"
                  text="Out of stock"
                  alert
                />
                <SamTag
                  v-else-if="product.stock_management && getStockLevels(product).length == 1 && getStockLevels(product)[0][1]>0"
                  :text="`${getStockLevels(product)[0][1]} in stock`"
                />
                <SamTag
                  v-else-if="product.stock_management && getStockLevels(product).length > 1 && getStockLevels(product)"
                  v-for="(v, index) in getStockLevels(product)"
                  v-bind:key="index"
                  :alert="v[1]==0"
                  :text="`${v[0]} ${v[1]}`"
                  :spaceRight="getStockLevels(product).length > 1"
                />
                <SamTag
                  v-else
                  text="Produced after order"
                />

              </SamTableColumn>
            </SamTableColumn>
            <SamTableColumn>
              <router-link :to="{ name: 'storemanagement', params: { tab: 'products', action: 'edit', itemId: product.id }}">
                <SamSecondaryButton text="Edit" noSpace/>
              </router-link>
            </SamTableColumn>
          </SamTableRow>
        </div>
        <draggable
          v-if="windowWidth >= mobileView"
          v-model="products"
          group="productList"
          @start="drag=true"
          @end="drag=false"
          direction="vertical"
          handle=".drag-handle"
          dragClass="sam-table-drag-class"
          :scroll-sensitivity="150"
          :force-fallback="true"
        >
          <div v-for="product in productList" v-bind:key="product.id" @click="openEdit(product)">
            <SamTableRow hoverable noSpace desktopView>
              <SamTableColumn imageColumn>
                <SamOneColumnImage :imageUrl="getImageUrl(product.primary_thumb_small)" :emptyImage="!product.primary_thumb_small || product.primary_thumb_small.legth == 0" additionalClass="mr-3 tableItem" />
                <div>
                  <SamParagraph :text="product.name" bolded/>
                  <SamParagraph :text="`${product.price}${$store.getters.currencyStr}`" price/>
                </div>
              </SamTableColumn>
              <SamTableColumn>
                <SamParagraph :text="getStatus(product.status)" tableItem/>
              </SamTableColumn>
              <SamTableColumn>
                <SamParagraph
                  v-if="product.groups.length == 0"
                  text="All user groups"
                  tableItem
                />
                <SamParagraph
                  v-for="(g, index) of product.groups"
                  v-bind:key="g.id"
                  :text="`${$store.getters.groupNameDict[g]} ${index != product.groups.length - 1 ? ',' : 'only'}`"
                  tableItem
                />
              </SamTableColumn>
              <SamTableColumn spaceRight>
                <SamTag
                  v-if="product.stock_management && getStockLevels(product).length == 1 && getStockLevels(product)[0][1]==0"
                  text="Out of stock"
                  alert
                />
                <SamTag
                  v-else-if="product.stock_management && getStockLevels(product).length == 1 && getStockLevels(product)[0][1]>0"
                  :text="`${getStockLevels(product)[0][1]} in stock`"
                />
                <SamTag
                  v-else-if="product.stock_management && getStockLevels(product).length > 1 && getStockLevels(product)"
                  v-for="(v, index) in getStockLevels(product)"
                  v-bind:key="index"
                  :alert="v[1]==0"
                  :text="`${v[0]} ${v[1]}`"
                  :spaceRight="getStockLevels(product).length > 1"
                />
                <SamTag
                  v-else
                  text="Produced after order"
                />
              </SamTableColumn>
              <img v-if="windowWidth > tabletView" class="sam-table-icon grabbable drag-handle" src='@/assets/images/Drag.svg'/>
              <img v-if="windowWidth >= mobileView && windowWidth <= tabletView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(product)" />
            </SamTableRow>
          </div>
        </draggable>
        <div class="row" v-if="$store.state.admin.moreProductsAvailable">
          <div class="col-5">
          </div>
          <div class="col-2">
            <div class="row justify-content-center">
              <div class="mini-loader">Loading...</div>
            </div>
            <div class="mt-2">
              Loading more results..
            </div>
          </div>
          <div class="col-5">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import router from '@/router'

// import ExportProductsModal from './ExportProductsModal.vue'
import SamAccordion from '../../../components/SamAccordion.vue'
// import SamSelect from '../../../components/SamSelect.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamThreeColumnImage from '../../../components/SamThreeColumnImage.vue'
import SamOneColumnImage from '../../../components/SamOneColumnImage.vue'
import SamTag from '../../../components/SamTag.vue'
import SamModal from '../../../components/SamModal.vue'

export default {
  name: 'ProductListing',
  components: {
    draggable,
    // ExportProductsModal,
    SamAccordion,
    // SamSelect,
    SamTableRow,
    SamTableColumn,
    SamSecondaryButton,
    SamParagraph,
    SamThreeColumnImage,
    SamTag,
    SamOneColumnImage,
    SamModal
  },
  data() {
    return {
      showHandles: true,
      productNameFilter: null,
      statusFilter: null,
      groupFilter: null,
      showExportModal: false,
      updating: false,
      modalIsClosing: false,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
      tableHeader: ["Name", 'Price', 'Status',  'Visible to', 'Stock']
    }
  },
  created: function () {
    this.setFiltersWithHash(window.location.hash.substring(1));
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted(){
     //Check window width

    setTimeout(() => this.isFetching = true, 1000);
    window.addEventListener('resize', this.getDimensions);

    // Fetch first 25 products in mount
    if(this.productList.length < 25 && !this.$store.state.admin.prodsFetched){
      // get products
      if(this.filterParams){
        this.$store.dispatch('admin/fetchProducts', {append: false, filters: this.filterParams});
      } else {
        this.$store.dispatch('admin/fetchProducts', {append: false});
      }
    } else {
      // already got products, check/set filters
      if(this.$store.state.admin.productFilters != this.filterParams){
        // Different filters, fetch orders
        this.$store.dispatch('admin/clearAndFetchProducts', {append: false, filters: this.filterParams});
      }
    }

  },
   unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    products: {
      get() {
        // use filtered products to make sure archived prods are removed from the list
        return this.productList;
      },
      set(value) {
        const vm = this;
        this.showHandles = false;
        this.$store.dispatch('admin/reorderProductList', value);
        this.$store.dispatch('reorderProductList', value)
        .then(function(){
          vm.showHandles = true;
        })
      }
    },
    productNameList(){
      if(!this.$store.state.admin.optionsFetched){
        this.$store.dispatch('admin/fetchManagementOptions');
      }
      return this.$store.state.admin.managementOptions.products;
    },
    statusList(){
      const statuses = this.$store.getters['admin/productOptions'].statuses
      return statuses ? statuses.filter(s => s[0] != 3).map(s => ({label: s[1], value: s[0]})) : [];
    },
    anyFilters(){
      return this.productNameFilter || this.statusFilter || this.groupFilter;
    },
    productList(){
      return this.$store.state.admin.products;
    },
    filterParams(){
      let params = ""
      params = this.productNameFilter ? params + "n=" + encodeURIComponent(this.productNameFilter) : params;
      params = this.statusFilter ? params + "&s=" + encodeURIComponent(this.statusFilter) : params;
      params = this.groupFilter ? params + "&g=" + encodeURIComponent(this.groupFilter) : params;
      if(params.startsWith("&")){
        params = params.slice(1);
      }
      return params != "" ? params : null;
    }
  },
  watch: {
    filterParams: function(newVal, oldVal){
      if(newVal != oldVal){
        let hash = window.location.hash.substring(1);
        if(newVal != hash){
          window.location.hash = newVal && newVal.length > 1 ? newVal : "";
          this.hash = newVal ? newVal : "";
        }
        // Clear orders from VUEX and fetch first batch from back end
        this.$store.dispatch('admin/clearAndFetchProducts', {append: false, filters: newVal});
      }
    }
  },
  methods: {

    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },

    getImageUrl(thumb_url){
      if(thumb_url == null){
        return "";
      }
      if(thumb_url && thumb_url.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return thumb_url;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + thumb_url;
      }
    },
    getStatus(status){
      return status == 1 ? "Draft" : status == 2 ? "Published" : "Archived";
    },
    getStockLevels(product){
      return product.variants.map(v => [v.name, v.stock_quantity])
    },
    showExportProductsModal() {
      this.showExportModal = true;
    },
    closeExportProductsModal(){
      this.showExportModal = false;
    },
     openEdit(product) {
      router.push({ name: 'storemanagement', params: { tab: 'products', action: 'edit', itemId: product.id }});
    },
    exportList(){
      this.updating = true;
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/products/export/';
      let method = 'post';
      const data = {}
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function () {
        vm.updating = false;
        vm.showExportModal = false;
        vm.$toastr.s(
          "Product listing has been exported as XLS file and will be sent to you via email shortly."
        );
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
        vm.showExportModal = false;
      });
    },
    getHashParams(hash){
      var params = {};
      hash.split('&').map(hk => {
        let temp = hk.split('=');
        params[temp[0]] = temp[1];
      });
      return params;
    },
    setFiltersWithHash(hash){
      var params = this.getHashParams(hash)
      window.hashparams = params;
      this.hash = hash;
      this.productNameFilter = params['n'] ? decodeURIComponent(params['n']) : null;
      this.statusFilter = params['s'] ? Number(decodeURIComponent(params['s'])) : null;
      this.groupFilter = params['g'] ? Number(decodeURIComponent(params['g'])) : null;
    },
    handleScroll(){
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight
      if(bottomOfWindow){
        this.$store.dispatch('admin/fetchProducts', {append: true});
      }
    },
    addNewItem() {
      this.$router.push({ name: 'storemanagement', params: { tab: 'products', action: 'new' }});
    }
  }
}

</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.sam-table-draggable {
  margin-bottom: 3rem;
}

@media (max-width: 1044px) {
  .sam-table-icon {
  right: 15px;
}
}

.sam-table-drag-class {
  opacity: 1 !important;
  background-color: $hoverColor;
  border-radius: $mainRadius;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

</style>

