<template>
  <div v-can="'management_carriers'">
    <CreateEditCarrier v-if="showCreateEditCarrier" v-bind:editstoreCarrier=editCarrier v-bind:carrierData=carrierData
                       v-bind:storeCarrierData=storeCarrierData />
    <CarrierListing v-else v-bind:carriers=carriers v-bind:storeCarriers=storeCarriers />
  </div>
</template>

<script>
import CarrierListing from '../components/CarrierListing.vue';
import CreateEditCarrier from '../components/CreateEditCarrier.vue';

export default {
  name: 'CarrierManagement',
  components: {
    CarrierListing,
    CreateEditCarrier
  },
  data() {
    return {}
  },
  computed: {
    showCreateEditCarrier() {
      return this.$route.params.tab == "carriers" && this.$route.params.action == "edit";
    },
    carriers() {
      if (!this.$store.state.carriersFetched) {
        this.$store.dispatch('fetchCarriers');
      }
      return this.$store.getters.carriers;
    },
    storeCarriers() {
      if (!this.$store.state.storeCarriersFetched) {
        this.$store.dispatch('fetchStoreCarriers');
      }
      return this.$store.getters.storeCarriers;
    },
    editCarrier() {
      return this.$route.params.action == "editCarrier" ? true : false;
    },
    carrierData() {
      if (this.$route.params.action == "edit") {
        const carrierId = this.$route.params.itemId;
        const carrier = this.carriers.find(t => t.id == carrierId);
        return carrier;
      }
      return {};
    },
    storeCarrierData() {
      if (this.$route.params.action == "edit") {
        const carrierId = this.$route.params.itemId;
        const storeCarrier = this.storeCarriers.find(t => t.carrier == carrierId);
        if (storeCarrier) {
          return storeCarrier;
        } else {
          return {}
        }
      }
      return {}
    }
  },

  methods: {}
}

</script>