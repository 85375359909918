<template>
  <div>
    <!-- Modal  -->
    <div v-if="showModal" class="modal fade in show modal-active modal-open" id="deleteMemberModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Delete store user</h4>
            <button type="button" class="close" v-on:click="hideModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Are you sure you want to delete user {{ userData.profile.user.first_name }} {{ userData.profile.user.last_name }} from this store?
              </div>
            </div>
          </div><!-- Modal body end -->
          <!-- Modal footer -->
          <div class="modal-footer">
              <div class="col-sm-4"><button type="button" class="btn btn-lg btn-outline-primary btn-block" v-on:click="hideModal()">Cancel</button></div>
              <div class="col-sm-4"><button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="deleteUser()">Delete user</button></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop show"></div>
    <div v-if="updating || (editUser && !$store.getters['admin/profilesFetched'])">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-sm-3">
          <div>User first name*</div>
          <input v-if="editUser" type="text" class="inputfield" v-model.trim="userData.profile.user.first_name">
          <input v-else type="text" class="inputfield" v-model.trim="user.first_name">
          <div v-if="$v.userData.profile.user.first_name.$error">
            <div class="form-field-error" v-if="!$v.userData.profile.user.first_name.required">First name is required</div>
          </div>
          <div v-if="$v.user.first_name.$error">
            <div class="form-field-error" v-if="!$v.user.first_name.required">First name is required</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div>User last name*</div>
          <input v-if="editUser" type="text" class="inputfield" v-model.trim="userData.profile.user.last_name">
          <input v-else type="text" class="inputfield" v-model.trim="user.last_name">
          <div v-if="$v.user.last_name.$error">
            <div class="form-field-error" v-if="!$v.user.last_name.required">Last name is required</div>
          </div>
          <div v-if="$v.userData.profile.user.last_name.$error">
            <div class="form-field-error" v-if="!$v.userData.profile.user.last_name.required">Last name is required</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div>Username (email)*</div>
          <input v-if="editUser" type="text" class="inputfield gray" v-model.trim="userData.profile.user.email" disabled>
          <input v-else type="text" class="inputfield" v-model.trim="user.email">
          <div v-if="$v.user.email.$error">
            <div class="form-field-error" v-if="!$v.user.email.required">Email is required</div>
            <div class="form-field-error" v-else-if="!$v.user.email.email">Email seems invalid</div>
            <div class="form-field-error" v-else-if="!$v.user.email.validChars">å, ä, ö are not allowed characters</div>
          </div>
          <div v-if="$v.userData.profile.user.email.$error">
            <div class="form-field-error" v-if="!$v.userData.profile.user.email.required">Email is required</div>
            <div class="form-field-error" v-else-if="!$v.userData.profile.user.email.email">Email seems invalid</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div>User phone number*</div>
          <input v-if="editUser" type="text" class="inputfield" v-model.trim="userData.profile.phone">
          <input v-else type="text" class="inputfield" v-model.trim="user.phone">
          <div v-if="$v.user.phone.$error">
            <div class="form-field-error" v-if="!$v.user.phone.required">Phone number is required</div>
            <div class="form-field-error" v-else-if="!$v.user.phone.phoneFormat">Phone number format seems invalid</div>
          </div>
          <div v-if="$v.userData.profile.phone.$error">
            <div class="form-field-error" v-if="!$v.userData.profile.phone.required">Phone number is required</div>
            <div class="form-field-error" v-else-if="!$v.userData.profile.phone.phoneFormat">Phone number format seems invalid</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-3">
          <div>User group*</div>
          <v-select
            v-if="editUser"
            label="name"
            :options="groups"
            :reduce="g => g.id"
            :clearable="false"
            v-model="userData.member.group"
          ></v-select>
          <v-select
            v-else
            label="name"
            :options="groups"
            :reduce="g => g.id"
            :clearable="false"
            v-model="user.group"
          ></v-select>
          <div v-if="$v.user.group.$error">
            <div class="form-field-error" v-if="!$v.user.group.required">User group is required</div>
          </div>
          <div v-if="$v.userData.member.group.$error">
            <div class="form-field-error" v-if="!$v.userData.member.group.required">User group is required</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div>Allowed cost center(s)*</div>
          <v-select v-if="editUser" multiple label="name" :options="ccs" :reduce="cc => cc.id" v-model="userData.member.costcenters" ></v-select>
          <v-select v-else multiple label="name" :options="ccs" :reduce="cc => cc.id" v-model="user.costcenters" ></v-select>
          <div v-if="$v.userData.member.costcenters.$error && editUser">
            <div class="form-field-error" v-if="!$v.userData.member.costcenters.required">Cost center is requireds</div>
          </div>
          <div v-if="$v.user.costcenters.$error && !editUser">
            <div class="form-field-error" v-if="!$v.user.costcenters.required">Cost center is required</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div>Preferred delivery address</div>
          <v-select
            v-if="editUser"
            label="name"
            :options="addresses"
            :reduce="a => a.id"
            v-model="userData.member.default_address"
          ></v-select>
          <v-select
            v-else
            label="name"
            :options="addresses"
            :reduce="a => a.id"
            v-model="user.default_address"
          ></v-select>
        </div>
        <div class="col-sm-3">
          <div v-if="editUser">Status</div>
          <input v-if="editUser" type="text" class="inputfield gray" v-bind:value="is_active ? 'Active' : 'Inactive'" disabled>
        </div>
        <div class="col-sm-3">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <hr class="mt-4" />
        </div>
      </div>
      <div class="row" :class="{'mb-5': !editUser}">
        <div class="col-sm-3">
          <button v-if="editUser" class="btn btn-lg btn-primary btn-block mt-3" v-on:click="save()">Save changes</button>
          <button v-else class="btn btn-lg btn-primary btn-block mt-3" v-on:click="create()">Create and invite user</button>
        </div>
        <div class="col-sm-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'users' }}"><button class="btn btn-lg btn-outline-primary btn-block mt-3">Back to all users</button></router-link>
        </div>
        <div class="col-sm-3">
          &nbsp;
        </div>
        <div class="col-sm-3">
          <button v-if="editUser && !is_active" class="btn btn-lg btn-outline-primary btn-block mt-3" v-on:click="create(resend=true)">Resend activation link</button>
        </div>
      </div>
      <div v-if="editUser">
        <div class="row">
          <div class="col-sm-12">
            <hr class="mt-4" />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-sm-3">
            <button @click="showModal = true" class="btn btn-lg btn-outline-primary btn-block mt-3">Delete user</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf, email } from 'vuelidate/lib/validators'

export default {
  name: 'CreateUser',
  props: [
    "editUser",
    "userData"
  ],
  data() {
    return {
      user: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        role: 2,
        group: null,
        default_store: false,
        costcenters: [],
        default_address: null
      },
      updating: false,
      showModal: false,
      roleOptions: [{label: 'Store manager', value: 1}, {label: 'User', value: 2}]
    }
  },
  validations: {
    user: {
      first_name: {
        required: requiredIf(function(){
          return !this.editUser;
        }),
      },
      last_name: {
        required: requiredIf(function(){
          return !this.editUser;
        }),
      },
      email: {
        required: requiredIf(function(){
          return !this.editUser;
        }),
        validChars(email){
          if(email == "" || email == null){
            return true;
          }
          // Check email doesn't contain å, ä, ö
          return !['å', 'ä', 'ö'].some(e => email.includes(e));
        },
        email
      },
      phone: {
        required: requiredIf(function(){
          return !this.editUser;
        }),
        phoneFormat(phone){
          if(phone == "" || phone == null){
            return true;
          }
          return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)
        }
      },
      costcenters: {
        required: requiredIf(function(){
          return !this.editUser;
        }),
      },
      group: {
        required: requiredIf(function(){
          return !this.editUser;
        }),
      }
    },
    userData: {
      profile: {
        user: {
          first_name: {
            required: requiredIf(function(){
              return this.editUser;
            }),
          },
          last_name: {
            required: requiredIf(function(){
              return this.editUser;
            }),
          },
          email: {
            required: requiredIf(function(){
              return this.editUser;
            }),
          }
        },
        phone: {
          required: requiredIf(function(){
            return this.editUser;
          }),
          phoneFormat(phone){
            if(phone == "" || phone == null){
              return true;
            }
            return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)
          }
        }
      },
      member: {
        group: {
          required: requiredIf(function(){
            return this.editUser;
          }),
        },
        costcenters: {
         required: requiredIf(function(){
          return this.editUser;
        }),
        },
      }
    }
  },
  computed: {
    ccs(){
      if(this.$store.getters.costcenters.length < 1 && !this.$store.getters.ccsFetched){
        this.$store.dispatch('fetchCostCenters');
      }
      return this.$store.getters.costcenters;
    },
    is_active(){
      if(this.userData.profile.user && this.userData.profile.user.is_active){
        return true;
      }
      return false;
    },
    addresses () {
      if(this.$store.getters.addresses.length < 1 && !this.$store.getters.addressesFetched){
        this.$store.dispatch('fetchAddresses');
      }
      return this.$store.getters.addresses;
    },
    groups(){
      if(this.$store.getters.groups.length < 1 && !this.$store.getters.groupsFetched){
        this.$store.dispatch('fetchGroups');
      }
      return this.$store.state.groups;
    }
  },
  methods: {
    create(resend=false){
      if(!resend){
        this.$v.$touch()
        if(this.$v.$invalid) {
          this.updating = false;
          return;
        }
      } else {
        this.user.email = this.userData.profile.user.email;
      }
      this.updating = true
      const storeId = this.$store.getters.activeStore.id;
      const data = {store: storeId, is_new_user: true};
      data['user'] = this.user;
      const vm = this;
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + storeId + "/add_member/",
        method: 'POST',
        data: data
      })
      .then(function () {
        vm.$store.dispatch('admin/fetchMembers')
        // fetch users & members
        if(vm.resend){
          vm.$toastr.s(
            "Activation email resent to the user."
          );
        } else {
          vm.$toastr.s(
            "New user was created."
          );
        }
        setTimeout(function() {
          vm.$router.push({ name: 'storemanagement', params: { tab: 'users' }});
        },2500);
      })
      .catch(err => {
        vm.updating = false;
        if (err.response && err.response.data){
          vm.$toastr.e(
            JSON.stringify(err.response.data)
          );
        }
      })
    },
    save(){
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.updating = false;
        return;
      }
      this.updating = true
      const storeId = this.$store.getters.activeStore.id;
      const data = this.userData.profile;
      const vm = this;
      // update profile
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/users/details/",
        method: 'put',
        data: data
      })
      .then(function () {
        // update membership
        const memData = vm.userData.member;
        vm.$http({
          url: process.env.VUE_APP_API_URL + "/api/stores/" + storeId + "/members/" + memData.id + "/",
          method: 'put',
          data: memData
        })
        .then(function () {
          vm.$toastr.s(
            "Your changes have been saved."
          );
          vm.updating = false
          // Check if own profile and update if needed
          if(memData.id == vm.$store.getters.membership.id){
            //updated own profile/membership => fetch updated
            vm.$store.dispatch('fetchProfile');
          }
        })
        .catch(err => {
          vm.updating = false;
          if (err.response && err.response.data){
            vm.$toastr.e(
              err.response.data
            );
          }
        })
      })
      .catch(err => {
        vm.updating = false;
        if (err.response && err.response.data){
          vm.$toastr.e(
            err.response.data
          );
        }
      })
    },
    hideModal(){
      this.showModal = false;
    },
    deleteUser(){
      const vm = this;
      const member_id = this.userData.member.id;
      const profile_id = this.userData.profile.id;
      const storeId = this.$store.getters.activeStore.id;
      this.updating = true;
      this.hideModal();
      this.$http({
        method: 'delete',
        url: process.env.VUE_APP_API_URL + '/api/stores/' + storeId + '/members/' + member_id + '/',
      })
      .then(function () {
        document.body.style.cursor='default';
        vm.$store.dispatch('admin/removeMember', {member_id: member_id, profile_id: profile_id});
        vm.$toastr.s(
          "User removed."
        );
        // redirect to userlisting after deleting
        setTimeout(function() {
          vm.$router.push({ name: 'storemanagement', params: { tab: 'users' }});
        },2500);
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    }
  }
}

</script>