<template>
  <div v-can="'management_groups'">
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <CreateEditGroup v-if="showCreateEditGroup" v-bind:editGroup=editGroup v-bind:groupData=groupData />
      <GroupListing v-else v-bind:groups=groups />
    </div>
  </div>
</template>

<script>

import GroupListing from '../components/GroupListing.vue'
import CreateEditGroup from '../components/CreateEditGroup.vue'

export default {
  name: 'GroupManagement',
  components: {
    GroupListing,
    CreateEditGroup
  },
  data() {
    return {
    }
  },
  mounted: function(){
    if(!this.$store.getters.groupsFetched){
      this.$store.dispatch('fetchGroups');
    }
    if(!this.$store.getters.addressesFetched){
      this.$store.dispatch('fetchAddresses');
    }
  },
  computed: {
    showCreateEditGroup(){
      return this.$route.params.tab == "user-groups" && (this.$route.params.action == "new" || this.$route.params.action == "edit");
    },
    groups(){
      return this.$store.getters.groups;
    },
    editGroup(){
      return this.$route.params.action == "edit" ? true : false;
    },
    groupData(){
      if(this.$route.params.action == "edit"){
        const groupId = this.$route.params.itemId;
        const group = this.groups.find(g => g.id == groupId);
        return group;
      }
      return {};
    },
    updating(){
      if(this.$store.getters.groupsFetched && !this.$store.getters['admin/profilesFetched']){
        this.$store.dispatch('admin/fetchMembers')
      }
      return !this.$store.getters.groupsFetched || !this.$store.getters['admin/profilesFetched'] ||
        !this.$store.getters.addressesFetched;
    }
  },
}
</script>
