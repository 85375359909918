<template>
  <div>
     <SamAccordion
        v-if="windowWidth < mobileView"
        :title="`${filteredAddresses.length > 1 ? 'Addresses': 'Address'} (${filteredAddresses.length}) `"
        title-payload="filters"
        accordionId="samAccordianAddresses"
      >
       <v-select
          class="sam-input sam-select mb-4"
          v-model="nameFilter"
          :options="nameList"
          :reduce="u => u.value"
          :searchable="true"
          placeholder="All addresses"
        ></v-select>
        <v-select
          class="sam-input sam-select mb-4"
          v-model="addressFilter"
          :options="addressList"
          :searchable="true"
          placeholder="All street addresses"
        ></v-select>
         <v-select
          class="sam-input sam-select mb-4"
          v-model="cityFilter"
          :options="cityList"
          :searchable="true"
          placeholder="All cities"
        ></v-select>
        <v-select
          class="sam-input sam-select mb-4"
          v-model="countryFilter"
          :options="countryList"
          :searchable="true"
          placeholder="All countries"
        ></v-select>
        <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new address" noSpace/>
     </SamAccordion>
     <SamTableRow v-else headerRow>
      <SamTableColumn>
         <v-select
          class="sam-input sam-select"
          v-model="nameFilter"
          :options="nameList"
          :reduce="u => u.value"
          :searchable="true"
          placeholder="All addresses"
          :disabled="!filteredAddresses.length && nameFilter == null"

        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="addressFilter"
          :options="addressList"
          :searchable="true"
          placeholder="All street addresses"
          :disabled="!filteredAddresses.length && addressFilter == null"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="cityFilter"
          :options="cityList"
          :searchable="true"
          placeholder="All cities"
          :disabled="!filteredAddresses.length && cityFilter == null"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
         class="sam-input sam-select"
          v-model="countryFilter"
          :options="countryList"
          :searchable="true"
          placeholder="All countries"
          :disabled="!filteredAddresses.length && countryFilter == null"
        ></v-select>
      </SamTableColumn>
     </SamTableRow>
     <div 
       @click="openEdit(address)"
        v-for="address in filteredAddresses" 
        v-bind:key="address.id"
      >
        <SamTableRow hoverable noSpace clickableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Address name" bolded/>
            <SamParagraph :text="address.name" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Street address" bolded/>
            <SamParagraph :text="address.address" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="City" bolded/>
            <SamParagraph :text="address.address" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Country" bolded/>
            <SamParagraph :text="address.city" tableItem/>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="openEdit(address)"  text="Edit" noSpace/>
          </SamTableColumn>
          <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(profile)" />
        </SamTableRow>

     </div>
  </div>
</template>

<script>
import router from '@/router'

import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'


export default {
  name: 'AddressListing',
  components: {
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamParagraph,
    SamSecondaryButton
  },
  props: [
    'addresses'
  ],
  data(){
    return {
      nameFilter: null,
      cityFilter: null,
      countryFilter: null,
      addressFilter: null,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted: function () {
     //Check window width
    setTimeout(() => this.isFetching = true, 1000);
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    nameList(){
      const names = this.addresses.map(a => ({label: a.name, value: a.id}))
      return [...names]
    },
    addressList(){
      const addresses = this.addresses.map(a => a.address)
      return [...addresses]
    },
    cityList(){
      const cities = this.addresses.map(a => a.city)
      const uniqueCities = new Set([...cities]);
      return [...uniqueCities]
    },
    countryList(){
      const countries = this.addresses.map(a => a.country.name)
      const uniqueCountries = new Set([...countries]);
      return [...uniqueCountries]
    },
    filteredAddresses(){
      let addresses = this.addresses;

      if(this.nameFilter){
        addresses = addresses.filter(a => a.id == this.nameFilter)
      }
      if(this.addressFilter){
        addresses = addresses.filter(a => a.address == this.addressFilter)
      }
      if(this.cityFilter){
        addresses = addresses.filter(a => a.city == this.cityFilter)
      }
      if(this.countryFilter){
        addresses = addresses.filter(a => a.country.name == this.countryFilter)
      }
      return addresses;
    }
  },
  methods: {
    addNewItem() {
      this.$router.push({ name: 'storemanagement', params: { tab: 'addresses', action: 'new' }});
    },
     openEdit(item) {
      router.push({ name: 'storemanagement', params: { tab: 'addresses', action: 'edit', itemId: item.id }});
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
}
</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>
