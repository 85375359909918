<template>
  <div v-can="'management_lease_products'">
    <CreateEditLeaseProduct v-if="showCreateEditProduct" v-bind:editProduct=editProduct v-bind:productData=productData />
    <LeaseProductListing v-else v-bind:products=products />
  </div>
</template>

<script>

import LeaseProductListing from '../components/LeaseProductListing.vue'
import CreateEditLeaseProduct from '../components/CreateEditLeaseProduct.vue'

export default {
  name: 'LeaseProductManagement',
  components: {
    LeaseProductListing,
    CreateEditLeaseProduct
  },
  data() {
    return {
    }
  },
  mounted: function () {
    // Fetch productoptions if not fetched
    if(!this.$store.getters['admin/productOptsFetched']){
      this.$store.dispatch('admin/fetchProductOptions')
    }
    // Fetch groups if not fetched
    if(!this.$store.getters.groupsFetched){
      this.$store.dispatch('fetchGroups');
    }
    // fetch filter options, product names etc
    if(!this.$store.state.admin.optionsFetched){
      this.$store.dispatch('admin/fetchManagementOptions');
    }
  },
  computed: {
    products(){
      return this.$store.state.admin.leaseProducts;
    },
    showCreateEditProduct(){
      return this.$route.params.tab == "leased-products" && (this.$route.params.action == "new" || this.$route.params.action == "edit");
    },
    productData(){
      if(this.$route.params.action == "edit"){
        const productId = this.$route.params.itemId;
        const product = this.products.find(p => p.id == productId);
        return product;
      }
      return {};
    },
    editProduct(){
      return this.$route.params.action == "edit" ? true : false;
    },
  },
  methods: {
  }
}

</script>