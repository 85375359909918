<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="deleteRecipientModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Delete product tag</h4>
            <button type="button" class="close" v-on:click="closeModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Are you sure you want to delete the product tag {{delTag.name}}?
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
            <button type="button" class="btn btn-lg btn-outline-primary mt-2" v-on:click="closeModal()">Cancel</button>
            <button type="button" class="btn btn-lg btn-primary mt-2" v-on:click="deleteTag()">Delete product tag</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>  
</template>

<script>
export default {
  name: "DeleteTagModal",
  props: [
    'delTag'
  ],
  data(){
    return {
    }
  },
  methods: {
    closeModal(){
      this.$emit('closeDeleteTagModal');
    },
    deleteTag(){
      this.$emit('deleteTag');
    }
  }
}
</script>
