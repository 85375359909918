<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="stockLevelModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Send current stock update to all users via email?</h4>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Would you like to send all {{$store.state.activeStore.name}} store users an email update of {{product.name}} current stock value(s)?
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
            <div class="col-3 pr-0">
              <button type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="closeModal()">Cancel</button>
            </div>
            <div class="col-3 pl-0">
              <button type="button" class="btn btn-lg btn-primary mt-2 w-100" v-on:click="sendUpdate(true)">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>  
</template>

<script>
export default {
  name: "StockLevelNotificationModal",
  props: [
    "product"
  ],
  data(){
    return {
    }
  },
  methods: {
    closeModal(){
      this.$emit('closeStockLevelModal');
    },
    sendUpdate(send){
      this.$emit('sendUpdate', send);
    }
  }
}
</script>
