<template>
  <div>
    <SamAccordion
          v-if="windowWidth < mobileView"
          :title="`${filteredCount > 1 ? 'Leased products': 'Leased product'} (${filteredCount}) `"
          title-payload="filters"
          accordionId="samAccordianProductList"
        >
          <v-select
            class="sam-input sam-select mb-4"
            v-model="productNameFilter"
            :options="productNameList"
            label="name"
            :reduce="p => p.name"
            :searchable="true"
            :disabled="!$store.state.admin.leaseProdsFetched || !$store.state.admin.optionsFetched"
            placeholder="All products"
          ></v-select>
          <v-select
            class="sam-input sam-select mb-4"
            v-model="statusFilter"
            :options="statusList"
            :reduce="s => s.value"
            :searchable="true"
            :disabled="!$store.state.admin.leaseProdsFetched || !$store.state.admin.optionsFetched"
            placeholder="All statuses"
          ></v-select>
          <v-select
            class="sam-input sam-select mb-4"
            label="name"
            v-model="groupFilter"
            :options="groupList"
            :reduce="g => g.id"
            :searchable="true"
            :disabled="!$store.state.admin.leaseProdsFetched || !$store.state.admin.optionsFetched"
            placeholder="Visible to"
          ></v-select>
          <v-select
            class="sam-input sam-select mb-4"
            v-model="leaseStatusFilter"
            :options="leaseStatusList"
            :reduce="s => s.value"
            :searchable="true"
            :disabled="!$store.state.admin.leaseProdsFetched || !$store.state.admin.optionsFetched"
            placeholder="All lease statuses"
          ></v-select>
          <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new lease product" noSpace/>
        </SamAccordion>
        <SamTableRow v-else headerRow>
          <SamTableColumn>
              <v-select
                class="sam-input sam-select"
                v-model="productNameFilter"
                :options="productNameList"
                label="name"
                :reduce="p => p.name"
                :searchable="true"
                :disabled="!$store.state.admin.leaseProdsFetched || !$store.state.admin.optionsFetched"
                placeholder="All products"
              ></v-select>
          </SamTableColumn>
          <SamTableColumn>
              <v-select 
                class="sam-input sam-select"
                v-model="statusFilter"
                :options="statusList"
                :reduce="s => s.value"
                :searchable="true"
                :disabled="!$store.state.admin.leaseProdsFetched || !$store.state.admin.optionsFetched"
                placeholder="All statuses"
              ></v-select>
          </SamTableColumn>
          <SamTableColumn>
               <v-select
                class="sam-input sam-select"
                label="name"
                v-model="groupFilter"
                :options="groupList"
                :reduce="g => g.id"
                :searchable="true"
                :disabled="!$store.state.admin.leaseProdsFetched || !$store.state.admin.optionsFetched"
                placeholder="Visible to"
              ></v-select>
          </SamTableColumn>
          <SamTableColumn>
             <v-select
              class="sam-input sam-select"
              v-model="leaseStatusFilter"
              :options="leaseStatusList"
              :reduce="s => s.value"
              :searchable="true"
              :disabled="!$store.state.admin.leaseProdsFetched || !$store.state.admin.optionsFetched"
              placeholder="All lease statuses"
            ></v-select>
          </SamTableColumn>
      </SamTableRow>
    <div v-if="!$store.state.admin.leaseProdsFetched">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div 
       @click="openEdit(product)"
        v-for="product in products" 
        v-bind:key="product.id"
      >
      <SamTableRow v-if="windowWidth < mobileView" hoverable noSpace clickableRow>
          <SamTableColumn mobileColumn v-if="windowWidth < mobileView">
             <SamThreeColumnImage
                :imageUrl="getImageUrl(product.primary_thumb_big)" 
                mobileView
                :emptyImage="!product.primary_thumb_small || product.primary_thumb_small.legth == 0"
              />
          </SamTableColumn>
          <SamTableColumn mobileColumn noPadding>
            <SamTableColumn :mobileColumn="windowWidth < mobileView" :imageColumn="windowWidth > mobileView">
              <SamOneColumnImage v-if="windowWidth >= mobileView" :imageUrl="getImageUrl(product.primary_thumb_small)" :emptyImage="!product.primary_thumb_small || product.primary_thumb_small.legth == 0" additionalClass="mr-3 tableItem" />
              <div>
              <SamParagraph v-if="windowWidth < mobileView" text="Product name" bolded/>
              <SamParagraph :text="product.name" tableItem bolded/>
              </div>
            </SamTableColumn>
            <SamTableColumn :mobileColumn="windowWidth < mobileView">
              <SamParagraph v-if="windowWidth < mobileView" text="Status" bolded/>
              <SamParagraph :text="getStatus(product.status)" tableItem/>
            </SamTableColumn>
            <SamTableColumn :mobileColumn="windowWidth < mobileView">
              <SamParagraph v-if="windowWidth < mobileView" text="User groups" bolded/>
              <SamParagraph v-if="product.groups.length == 0" text="All user groups" tableItem/>
              <SamParagraph 
                v-for="(g, index) of product.groups" 
                v-bind:key="g.id" 
                :text="`${$store.getters.groupNameDict[g]} ${index != product.groups.length - 1 ? ', ' : 'only'}`" 
                tableItem 
                inline
              />
            </SamTableColumn>
            <SamTableColumn :mobileColumn="windowWidth < mobileView">
              <SamParagraph v-if="windowWidth < mobileView" text="Lease status" bolded/>
              <SamParagraph :text="getLeaseStatus(product.lease_status)" tableItem/>
            </SamTableColumn>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="openEdit(product)"  text="Edit" noSpace/>
          </SamTableColumn>
        </SamTableRow>

        <SamTableRow v-else hoverable noSpace clickableRow>
            <SamTableColumn :mobileColumn="windowWidth < mobileView" :imageColumn="windowWidth > mobileView">
              <SamOneColumnImage v-if="windowWidth >= mobileView" :imageUrl="getImageUrl(product.primary_thumb_small)" :emptyImage="!product.primary_thumb_small || product.primary_thumb_small.legth == 0" additionalClass="mr-3 tableItem" />
              <div>
              <SamParagraph v-if="windowWidth < mobileView" text="Product name" bolded/>
              <SamParagraph :text="product.name" tableItem bolded/>
              </div>
            </SamTableColumn>
            <SamTableColumn :mobileColumn="windowWidth < mobileView">
              <SamParagraph v-if="windowWidth < mobileView" text="Status" bolded/>
              <SamParagraph :text="getStatus(product.status)" tableItem/>
            </SamTableColumn>
            <SamTableColumn :mobileColumn="windowWidth < mobileView">
              <SamParagraph v-if="windowWidth < mobileView" text="User groups" bolded/>
              <SamParagraph v-if="product.groups.length == 0" text="All user groups" tableItem/>
              <SamParagraph 
                v-for="(g, index) of product.groups" 
                v-bind:key="g.id" 
                :text="`${$store.getters.groupNameDict[g]} ${index != product.groups.length - 1 ? ', ' : 'only'}`" 
                tableItem 
                inline
              />
            </SamTableColumn>
            <SamTableColumn :mobileColumn="windowWidth < mobileView">
              <SamParagraph v-if="windowWidth < mobileView" text="Lease status" bolded/>
              <SamParagraph :text="getLeaseStatus(product.lease_status)" tableItem/>
            </SamTableColumn>
          <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(group)" />
        </SamTableRow>
     </div>
    </div>
  </div>
</template>

<script>
import SamAccordion from '../../../components/SamAccordion.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamThreeColumnImage from '../../../components/SamThreeColumnImage.vue'
import SamOneColumnImage from '../../../components/SamOneColumnImage.vue'

export default {
  name: 'LeaseProductListing',
  props: [
    'products'
  ],
    components: {
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamSecondaryButton,
    SamParagraph,
    SamThreeColumnImage,
    SamOneColumnImage
  },
  data() {
    return {
      productNameFilter: null,
      skuFilter: null,
      statusFilter: null,
      leaseStatusFilter: null,
      groupFilter: null,
      hash: "",
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  created: function () {
    this.setFiltersWithHash(window.location.hash.substring(1));
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted(){
     //Check window width

    setTimeout(() => this.isFetching = true, 1000);
    window.addEventListener('resize', this.getDimensions);

    // Fetch first 25 orders in mount
    if(this.products.length < 25 && !this.$store.state.admin.leaseProdsFetched){
      // get orders and then set update to false
      if(this.filterParams){
        this.$store.dispatch('admin/fetchLeaseProducts', {append: false, filters: this.filterParams});
      } else {
        this.$store.dispatch('admin/fetchLeaseProducts', {append: false});
      }
    } else {
      // already got products, check/set filters
      if(this.$store.state.admin.leaseProductFilters != this.filterParams){
        // Different filters, fetch orders
        this.$store.dispatch('admin/clearAndFetchLPs', {append: false, filters: this.filterParams});
      }
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    options(){
      if(this.$store.getters['admin/productOptions'].length == 0){
        this.$store.dispatch('admin/fetchProductOptions')
      }
      return this.$store.getters['admin/productOptions'];
    },
    productNameList(){
      if(!this.$store.state.admin.optionsFetched){
        this.$store.dispatch('admin/fetchManagementOptions');
      }
      return this.$store.state.admin.managementOptions.lease_products;
    },
    skuList(){
      return this.$store.state.admin.managementOptions.lease_skus;
    },
    groupList(){
      return this.$store.state.admin.managementOptions.groups;
    },
    statusList(){
      const statuses = this.$store.getters['admin/productOptions'].statuses
      return statuses ? statuses.filter(s => s[0] != 3).map(s => ({label: s[1], value: s[0]})) : [];
    },
    leaseStatusList(){
      const statuses = this.$store.getters['admin/productOptions'].lease_statuses
      return statuses ? statuses.map(s => ({label: s[1], value: s[0]})) : [];
    },
    filteredCount(){
      return this.$store.state.admin.totalLeaseProdCount;
    },
    filterParams(){
      let params = ""
      params = this.productNameFilter ? params + "n=" + encodeURIComponent(this.productNameFilter) : params;
      params = this.skuFilter ? params + "&sku=" + encodeURIComponent(this.skuFilter) : params;
      params = this.statusFilter ? params + "&s=" + encodeURIComponent(this.statusFilter) : params;
      params = this.leaseStatusFilter ? params + "&ls=" + encodeURIComponent(this.leaseStatusFilter) : params;
      params = this.groupFilter ? params + "&g=" + encodeURIComponent(this.groupFilter) : params;
      if(params.startsWith("&")){
        params = params.slice(1);
      }
      return params != "" ? params : null;
    }
  },
  watch: {
    filterParams: function(newVal, oldVal){
      if(newVal != oldVal){
        let hash = window.location.hash.substring(1);
        if(newVal != hash){
          window.location.hash = newVal && newVal.length > 1 ? newVal : "";
          this.hash = newVal ? newVal : "";
        }
        // Clear orders from VUEX and fetch first batch from back end
        this.$store.dispatch('admin/clearAndFetchLPs', {append: false, filters: newVal});
      }
    }
  },
  methods: {
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },

    getImageUrl(thumb_url){
      if(thumb_url == null){
        return "";
      }
      if(thumb_url && thumb_url.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return thumb_url;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + thumb_url;
      }
    },
    getStatus(status){
      return status == 1 ? "Draft" : status == 2 ? "Published" : "Archived";
    },
    getLeaseStatus(status){
      if(!this.options.lease_statuses){
        return "--"
      }
      const s = this.options.lease_statuses.find(o => o[0] == status);
      return s ? s[1] : "--"
    },
    getHashParams(hash){
      var params = {};
      hash.split('&').map(hk => {
        let temp = hk.split('=');
        params[temp[0]] = temp[1];
      });
      return params;
    },
    setFiltersWithHash(hash){
      var params = this.getHashParams(hash)
      window.hashparams = params;
      this.hash = hash;
      this.productNameFilter = params['n'] ? decodeURIComponent(params['n']) : null;
      this.skuFilter = params['sku'] ? decodeURIComponent(params['sku']) : null;
      this.statusFilter = params['s'] ? Number(decodeURIComponent(params['s'])) : null;
      this.leaseStatusFilter = params['ls'] ? Number(decodeURIComponent(params['ls'])) : null;
      this.groupFilter = params['g'] ? Number(decodeURIComponent(params['g'])) : null;
    },
    handleScroll(){
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight
      if(bottomOfWindow){
        this.$store.dispatch('admin/fetchLeaseProducts', {append: true});
      }
    },
    addNewItem() {
      this.$router.push({ name: 'storemanagement', params: { tab: 'leased-products', action: 'new' }});
    },
    openEdit(item) {
       this.$router.push({ name: 'storemanagement', params: { tab: 'leased-products', action: 'edit', itemId: item.id }});
    },

  }
}

</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>