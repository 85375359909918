<template>
  <div>
    <SamAccordion
        v-if="windowWidth < mobileView"
        :class="'.d-none .d-sm-block .d-md-none'"
        :title="`${filteredGroups.length > 1 ? 'User groups': 'User group'} (${groups.length}) `"
        title-payload="filters"
        accordionId="samAccordianGroups"
      >
       <v-select
          class="sam-input sam-select mb-4"
          v-model="groupName"
          :options="userGroupNamesList"
          :searchable="true"
          placeholder="All user group"
        ></v-select>
        <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new user group" noSpace/>
     </SamAccordion>
      <SamTableRow v-else headerRow>
      <SamTableColumn>
         <v-select
          class="sam-input sam-select"
          v-model="groupName"
          :options="userGroupNamesList"
          :searchable="true"
          placeholder="All user group"
          :disabled="!filteredGroups.length && groupName == null"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <SamParagraph text="Group description"/>
      </SamTableColumn>
       <SamTableColumn>
        <SamParagraph text="Number of users"/>
      </SamTableColumn>
     </SamTableRow>
    <div 
       @click="openEdit(group)"
        v-for="group in filteredGroups" 
        v-bind:key="group.id"
      >
      <SamTableRow hoverable noSpace clickableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Group name" bolded/>
            <SamParagraph :text="group.name" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Group description" bolded/>
            <SamParagraph :text="group.description" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Number of users" bolded/>
            <SamParagraph :text="usersInGroupDict[group.id]" tableItem/>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="openEdit(group)"  text="Edit" noSpace/>
          </SamTableColumn>
          <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(group)" />
        </SamTableRow>
     </div>
  </div>
</template>


<script>
import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'

export default {
  name: 'GroupListing',
  props: [
    'groups'
  ],
  components: {
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamParagraph,
    SamSecondaryButton
  },
  data() {
    return {
      groupName: null,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted: function () {
     //Check window width
      setTimeout(() => this.isFetching = true, 1000);
      window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
      window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    userGroupNamesList(){
      const names = this.groups.map(a => a.name)
      return [...names]
    },

    filteredGroups(){
      let groups = this.groups;
      
      if(this.groupName != null){
        groups = groups.filter(p => p.name == this.groupName)
      }
      return groups;
    },
    usersInGroupDict(){
      let users = {};
      for(let g of this.groups){
        const userIds = this.$store.getters['admin/storeMembers'].filter(m => g.members.includes(m.id)).map(m => m.user);
        // staff members are excluded from storeProfiles --> not counted to group users
        const profiles = this.$store.getters['admin/storeProfiles'].filter(p => userIds.includes(p.user.id));
        users[g.id] = profiles.length;
      }
      return users;
    }
  },
   methods: {
    addNewItem() {
      this.$router.push({ name: 'storemanagement', params: { tab: 'user-groups', action: 'new' }});
    },
     openEdit(item) {
       this.$router.push({ name: 'storemanagement', params: { tab: 'user-groups', action: 'edit', itemId: item.id }});
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
}
</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>
