<template>
  <div v-can="'management_cost_centers'">
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <CreateEditCC v-if="showCreateEditCC" v-bind:editCC=editCC v-bind:CCData=CCData />
      <CCListing v-else v-bind:ccs=ccs />
    </div>
  </div>
</template>

<script>

import CCListing from '../components/CCListing.vue'
import CreateEditCC from '../components/CreateEditCC.vue'

export default {
  name: 'CcManagement',
  components: {
    CCListing,
    CreateEditCC
  },
  data() {
    return {
    }
  },
  mounted: function(){
    if(!this.$store.getters.ccsFetched){
      this.$store.dispatch('fetchCostCenters');
    }
  },
  computed: {
    showCreateEditCC(){
      return this.$route.params.tab == "cost-centers" && (this.$route.params.action == "new" || this.$route.params.action == "edit");
    },
    ccs(){
      return this.$store.getters.costcenters;
    },
    editCC(){
      return this.$route.params.action == "edit" ? true : false;
    },
    CCData(){
      if(this.$route.params.action == "edit"){
        const ccId = this.$route.params.itemId;
        const cc = this.ccs.find(c => c.id == ccId);
        return cc;
      }
      return {};
    },
    updating(){
      return !this.$store.getters.ccsFetched
    }
  },
  methods: {
  }
}

</script>