<template>
  <div v-can="'management_tags'">
    <CreateEditTag v-if="showCreateEditTag" v-bind:editTag=editTag v-bind:tagData=tagData />
    <TagListing v-else v-bind:tags=tags />
  </div>
</template>

<script>

import TagListing from '../components/TagListing.vue'
import CreateEditTag from '../components/CreateEditTag.vue'

export default {
  name: 'TagManagement',
  components: {
    TagListing,
    CreateEditTag
  },
  data() {
    return {
    }
  },
  computed: {
    showCreateEditTag(){
      return this.$route.params.tab == "tags" && (this.$route.params.action == "new" || this.$route.params.action == "edit");
    },
    tags(){
      if(!this.$store.state.productTagsFetched){
        this.$store.dispatch('fetchTags');
      }
      return this.$store.getters.productTags;
    },
    editTag(){
      return this.$route.params.action == "edit" ? true : false;
    },
    tagData(){
      if(this.$route.params.action == "edit"){
        const tagId = this.$route.params.itemId;
        const tag = this.tags.find(t => t.id == tagId);
        return tag;
      }
      return {};
    },
  },
  methods: {
  }
}

</script>