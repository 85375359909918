<template>
  <div v-can="'management_users'">
    <CreateUser v-if="showCreateUser" v-bind:editUser=editUser v-bind:userData=userData />
    <UserListing v-else v-bind:profiles=profiles v-bind:members=members />
  </div>
</template>

<script>

import UserListing from '../components/UserListing.vue'
import CreateUser from '../components/CreateUser.vue'

export default {
  name: 'UserManagement',
  components: {
    UserListing,
    CreateUser
  },

  data() {
    return {
    }
  },
  computed: {
    members(){
      return this.$store.getters['admin/storeMembers'];
    },
    profiles(){
      if(!this.$store.getters['admin/profilesFetched']){
        this.$store.dispatch('admin/fetchMembers')
      }
      return this.$store.getters['admin/storeProfiles'];
    },
    userData(){
      if(this.$route.params.action == "edit"){
        const userId = this.$route.params.itemId;
        const profile = this.profiles.find(p => p.user.id == userId);
        const member = this.members.find(m => m.user == userId);
        return {member: member, profile: profile};
      }
      return {member: {}, profile: {}};
    },
    editUser(){
      return this.$route.params.action == "edit" ? true : false;
    },
    showCreateUser(){
      return this.$route.params.tab == "users" && (this.$route.params.action == "new" || this.$route.params.action == "edit");
    }
  },
  methods: {
  }
}

</script>
