<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="exportProductsModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add or edit translations</h4>
            <button type="button" class="close" @click="closeModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <!-- Tabs -->
            <div class="row">
              <div class="col-12">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a class="nav-link"
                       :class="{ active: language === 'fi' }"
                       @click="switchLanguage('fi')"
                    >Finnish</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"
                       :class="{ active: language === 'fr' }"
                       @click="switchLanguage('fr')"
                    >French</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"
                       :class="{ active: language === 'de' }"
                       @click="switchLanguage('de')"
                    >German</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"
                       :class="{ active: language === 'ja' }"
                       @click="switchLanguage('ja')"
                    >Japanese</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"
                       :class="{ active: language === 'sv' }"
                       @click="switchLanguage('sv')"
                    >Swedish</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"
                       :class="{ active: language === 'sp' }"
                       @click="switchLanguage('sp')"
                    >Spanish</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"
                       :class="{ active: language === 'pt' }"
                       @click="switchLanguage('pt')"
                    >Portuguese</a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Tabs end-->
            <div v-if="fetching || updating">
              <div class="d-flex justify-content-center">
                <div class="spinner-border mt-5 mb-3" style="width: 3rem; height: 3rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row mt-4">
                <div class="col-12 col-md-6">
                  <div class="font-weight-bold">Product name</div>
                  <input type="text" class="inputfield" v-model="product.name">
                  <div v-if="$v.product.name.$error">
                    <div class="form-field-error" v-if="!$v.product.name.required">Product name is required</div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0">
                  <div class="font-weight-bold">Product description</div>
                  <div><textarea rows=3 v-model="product.description" /></div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 mt-4"
                     v-for="(v, idx) in product.variants"
                     :key="idx"
                >
                  <div class="font-weight-bold">Variant '{{ defaultProduct.variants[idx].name }}'</div>
                  <input type="text" class="inputfield" v-model="v.name">
                  <div v-if="$v.product.variants.$each[idx].name.$error">
                    <div class="form-field-error">Variant name is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
            <div class="row w-100">
              <div class="col-12 col-md-6">
                <button type="button" class="btn btn-lg btn-primary w-100 mt-0" @click="saveTranslations">Save changes</button>
              </div>
              <div class="col-12 col-md-6">
                <button type="button" class="btn btn-lg btn-outline-primary w-100 mt-4 mt-md-0" @click="closeModal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
import _ from 'lodash'
import {required} from 'vuelidate/lib/validators'

export default {
  name: "ProductTranslationModal",
  props: ['defaultProduct'],
  data() {
    return {
      fetching: false,
      updating: false,
      language: 'fi',
      product: {
        name: null,
        description: null,
        variants: [],
      },
      originalProduct: {},
      productsToUpdate: [],
    }
  },
  validations: {
    product: {
      name: {
        required
      },
      variants: {
        $each: {
          name: {
            required
          }
        }
      },
    }
  },
  beforeMount() {
    this.getProduct()
  },
  // beforeDestroy() {
  //   this.product = null
  //   this.language = 'fi'
  // },
  methods: {
    closeModal() {
      return this.$emit('closeProductTranslationModal')
    },
    getProduct() {
      const vm = this
      this.fetching = true
      const productId = this.$route.params.itemId
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/products/' + productId + "/"
      this.$http({
        method: 'GET',
        url: api_url,
        params: {
          lang: vm.language
        },
      })
      .then(function (response) {
        const productData = response.data
        const existedProduct = vm.productsToUpdate.find(p => p.lang === vm.language)
        vm.product = existedProduct ? existedProduct : productData
        vm.originalProduct = _.cloneDeep(productData)
        vm.fetching = false
      })
      .catch(function (error) {
        if (error.request) {
          vm.$toastr.e(error.request.responseText)
        } else {
          vm.$toastr.e(error)
        }
        vm.fetching = false
      })
    },
    async updateProduct(product){
      let productData = {...product}
      delete productData.primary_image
      delete productData.primary_thumb_big
      delete productData.primary_thumb_small
      delete productData.print_file

      for(let v of productData.variants) {
        delete v.print_file
      }

      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/products/' + this.product.id + "/"

      try {
        await this.$http({
          method: 'PUT',
          url: api_url,
          data: productData,
          params: {
            lang: productData.lang
          },
        })
      } catch (error) {
        throw error
      }
    },
    productChanged() {
      return !_.isEqual(this.product, this.originalProduct)
    },
    checkCurrentTranslation() {
      if (this.productChanged()) {
        this.productsToUpdate = this.productsToUpdate.filter(p => p.lang !== this.language)
        this.product.lang = this.language
        this.productsToUpdate.push(this.product)
      }
    },
    switchLanguage(lang) {
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.$toastr.e('Please check all fields')
        return
      }
      this.checkCurrentTranslation()
      this.language = lang
      this.getProduct()
    },
    async saveTranslations() {
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.$toastr.e('Please check all fields')
        return
      }
      this.checkCurrentTranslation()
      if (this.productsToUpdate.length > 0) {
        const errors = []
        this.updating = true
        for (let p of this.productsToUpdate) {
          try {
            await this.updateProduct(p)
          } catch (error) {
            errors.push(error)
          }
        }
        this.updating = false

        if (errors.length > 0) {
          for (let e of errors) {
            this.$toastr.e(e)
          }
        } else {
          this.$toastr.s('Changes saved successfully.')
        }
      }
      this.closeModal()
    },
  },
}
</script>
