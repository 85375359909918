<template>
  <div v-can="'management_locations'">
    <CreateEditAddress v-if="showCreateEditAddress" v-bind:editAddress=editAddress v-bind:addressData=addressData />
    <AddressListing v-else v-bind:addresses=addresses />
  </div>
</template>

<script>

import AddressListing from '../components/AddressListing.vue'
import CreateEditAddress from '../components/CreateEditAddress.vue'

export default {
  name: 'AddressManagement',
  components: {
    AddressListing,
    CreateEditAddress
  },
  data() {
    return {
    }
  },
  mounted: function(){
    // Fetch groups if not fetched
    if(!this.$store.getters.groupsFetched){
      this.$store.dispatch('fetchGroups');
    }
  },
  computed: {
    addresses(){
      if(!this.$store.getters.addressesFetched){
        this.$store.dispatch('fetchAddresses');
      }
      return this.$store.getters.addresses;
    },
    showCreateEditAddress(){
      return this.$route.params.tab == "addresses" && (this.$route.params.action == "new" || this.$route.params.action == "edit");
    },
    addressData(){
      if(this.$route.params.action == "edit"){
        const addressId = this.$route.params.itemId;
        const address = this.addresses.find(a => a.id == addressId);
        return address;
      }
      return {};
    },
    editAddress(){
      return this.$route.params.action == "edit" ? true : false;
    },
  },
  methods: {
  }
}

</script>