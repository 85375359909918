<template>
  <div>
    <SamAccordion
        v-if="windowWidth < mobileView"
        :title="`${filteredCostCenters.length > 1 ? 'Cost centers': 'Cost centers'} (${filteredCostCenters.length}) `"
        title-payload="filters"
        accordionId="samAccordianCostCenters"
      >
       <v-select
          class="sam-input sam-select mb-4"
          v-model="costName"
          :options="costNameList"
          :searchable="true"
          placeholder="All addresses"
        ></v-select>
        <v-select
          class="sam-input sam-select mb-4"
          v-model="costId"
          :options="costIdList"
          :searchable="true"
          placeholder="All street addresses"
        ></v-select>
        <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new cost center" noSpace/>
     </SamAccordion>
     <SamTableRow v-else headerRow>
      <SamTableColumn>
         <v-select
          class="sam-input sam-select"
          v-model="costName"
          :options="costNameList"
          :searchable="true"
          placeholder="Cost center name"
          :disabled="!filteredCostCenters.length && costName == null"

        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="costId"
          :options="costIdList"
          :searchable="true"
          placeholder="Cost center ID or PO number"
          :disabled="!filteredCostCenters.length && costId == null"
        ></v-select>
      </SamTableColumn>
     </SamTableRow>
     <div 
       @click="openEdit(cc)"
        v-for="cc in filteredCostCenters" 
        v-bind:key="cc.id"
      >
      <SamTableRow hoverable noSpace clickableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Cost center name" bolded/>
            <SamParagraph :text="cc.name" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Cost center ID or PO number" bolded/>
            <SamParagraph :text="cc.cc_id" tableItem/>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="openEdit(cc)"  text="Edit" noSpace/>
          </SamTableColumn>
          <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(cc)" />
        </SamTableRow>
     </div>
  </div>
</template>

<script>

import router from '@/router'

import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'

export default {
  name: 'CCListing',
  components: {
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamParagraph,
    SamSecondaryButton
  },
  props: [
    'ccs'
  ],
  data() {
    return {
      costName: null,
      costId: null,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted: function () {
     //Check window width
    setTimeout(() => this.isFetching = true, 1000);
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    costNameList(){
      const names = this.ccs.map(a => a.name)
      return [...names]
    },
    costIdList(){
      const ids = this.ccs.map(a => a.cc_id)
      return [...ids]
    },
    filteredCostCenters(){
      let costCenters = this.ccs;
      
      if(this.costName != null){
        costCenters = costCenters.filter(p => p.name == this.costName)
      }
       if(this.costId != null){
        costCenters = costCenters.filter(p => p.cc_id == this.costId);
      }
      return costCenters;
    },
  },
  methods: {
    addNewItem() {
      this.$router.push({ name: 'storemanagement', params: { tab: 'cost-centers', action: 'new' }});
    },
     openEdit(item) {
      router.push({ name: 'storemanagement', params: { tab: 'cost-centers', action: 'edit', itemId: item.id }});
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
}
</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>