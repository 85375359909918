<template>
  <div>
    <!-- Modal  -->
    <div v-if="showModal" class="modal fade in show modal-active modal-open" id="usersFullModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Maximum number of users</h4>
            <button type="button" class="close" v-on:click="hideModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                You have maximum of {{maxUsers}} users included in your {{planName}} plan. Please contact Framme Store team to upgrade or delete some of the existing users.
              </div>
            </div>
          </div><!-- Modal body end -->
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="col-md-4"><button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="hideModal()">Close</button></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop show"></div>
    <SamAccordion
      v-if="windowWidth < mobileView"
      :title="`${filteredProfiles.length > 1 ? 'Users': 'User'} (${filteredProfiles.length}) `"
      title-payload="filters"
      accordionId="samAccordianProductList"
    >
      <v-select
        class="sam-input sam-select mb-4"
        v-model="userNameFilter"
        :options="userNameList"
        :clearable="userNameFilter != null"
        :reduce="u => u.value"
        :searchable="true"
        placeholder="All users"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="userEmailFilter"
        :options="userEmailList"
        :clearable="userEmailFilter != null"
        :reduce="e => e.label"
        :searchable="true"
        placeholder="All emails"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="groupFilter"
        label="name"
        :options="groups"
        :reduce="g => g.id"
        :clearable="groupFilter != null"
        :searchable="true"
        placeholder="All groups"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="statusFilter"
        :options="statusList"
        :reduce="u => u.value"
        placeholder="All statuses"
        :clearable="statusFilter != null"
      ></v-select>
      <SamSecondaryButton @buttonAction="addNewItem()"  text="+ Add new user" noSpace/>
    </SamAccordion>
    <SamTableRow v-else headerRow>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="userNameFilter"
          :options="userNameList"
          :reduce="u => u.value"
          :searchable="true"
          placeholder="All users"
          :disabled="!filteredProfiles.length"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="userEmailFilter"
          :options="userEmailList"
          :reduce="e => e.value"
          :searchable="true"
          placeholder="All emails"
          :disabled="!filteredProfiles.length"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="groupFilter"
          label="name"
          :options="groups"
          :reduce="g => g.id"
          :searchable="true"
          placeholder="All groups"
          :disabled="!filteredProfiles.length"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn>
        <v-select
          class="sam-input sam-select"
          v-model="statusFilter"
          :options="statusList"
          :reduce="u => u.value"
          placeholder="All statuses"
          :disabled="!filteredProfiles.length"
        ></v-select>
      </SamTableColumn>
    </SamTableRow>
    <div
      @click="openEdit(profile)"
      v-for="profile in filteredProfiles"
      v-bind:key="profile.id"
    >
      <SamTableRow hoverable noSpace clickableRow>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="User" bolded/>
          <SamParagraph :text="`${profile.user.first_name} ${profile.user.last_name}`" tableItem/>
        </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Username" bolded/>
          <SamParagraph :text="profile.user.email" tableItem/>
        </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Group" bolded/>
          <SamParagraph :text="groupIdForProfile[profile.id]" tableItem/>
        </SamTableColumn>
        <SamTableColumn :mobileColumn="windowWidth < mobileView">
          <SamParagraph v-if="windowWidth < mobileView" text="Status" bolded/>
          <SamParagraph :text="profile.user.is_active ? 'Active' : 'Inactive'" tableItem/>
        </SamTableColumn>
        <SamTableColumn v-if="windowWidth < mobileView">
          <SamSecondaryButton @buttonAction="openEdit(profile)"  text="Edit" noSpace/>
        </SamTableColumn>
        <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' @click="openEdit(profile)" />
      </SamTableRow>
    </div>
  </div>
</template>

<script>
import router from '@/router'

import SamAccordion from '../../../components/SamAccordion.vue'
// import SamSelect from '../../../components/SamSelect.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'

export default {
  name: 'UserListing',
  props: [
    'profiles',
    'members'
  ],
  components: {
    SamAccordion,
    // SamSelect,
    SamTableRow,
    SamTableColumn,
    SamParagraph,
    SamSecondaryButton
  },
  data() {
    return {
      showModal: false,
      roleList: [{"label":"Store manager","value":1},{"label":"User","value":2}],
      statusList: [{"label":"Active","value":true},{"label":"Inactive","value":false}],
      userNameFilter: null,
      userEmailFilter: null,
      groupFilter: null,
      statusFilter: null,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted: function () {
     //Check window width

    setTimeout(() => this.isFetching = true, 1000);
    window.addEventListener('resize', this.getDimensions);

    // Fetch productoptions if not fetched
    if(!this.$store.getters['admin/productOptsFetched']){
      this.$store.dispatch('admin/fetchProductOptions')
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    maxUsers(){
      return this.$store.getters.activeStore.features ? this.$store.getters.activeStore.features.max_users : 0;
    },
    planName(){
      if(!this.$store.getters.activeStore.subscription){ return "--"}

      var options = this.$store.getters['admin/productOptions'];
      var sub = options.subscription_types.find(s => s[0] == this.$store.getters.activeStore.subscription.subscription_type)
      return sub ? sub[1] : "--";
    },
    userNameList(){
      let names = this.profiles.map(p => ({label: p.user.first_name + ' ' + p.user.last_name, value: p.user.id}))
      return [...names]
    },
    userEmailList(){
      let emails = this.profiles.map(p => ({label: p.user.email, value: p.user.email}))
      return [...emails]
    },
    filteredProfiles(){
      let profiles = this.profiles;
      
      if(this.userNameFilter != null){
        profiles = profiles.filter(p => p.user.id == this.userNameFilter)
      }
       if(this.userEmailFilter != null ){
        profiles = profiles.filter(p => p.user.email == this.userEmailFilter);
      }
      if(this.groupFilter != null){
        profiles = profiles.filter(p => this.members.find(m => m.user == p.user.id).group == this.groupFilter)
      }
      if(this.statusFilter != null){
        profiles = profiles.filter(p => p.user.is_active == this.statusFilter)
      }
      return profiles;
    },
    groups(){
      if(this.$store.getters.groups.length < 1 && !this.$store.getters.groupsFetched){
        this.$store.dispatch('fetchGroups');
      }
      let groups = this.$store.state.groups
      return groups;
    },
    groupIdForProfile(){
      let groupNames = {}
      for(let p of this.profiles){
        let groupId = this.members.find(m => m.user == p.user.id).group;
        let group = this.groups.find(g => g.id == groupId)
        if(group){
          groupNames[p.id] = group.name;
        }
      }
      return groupNames;
    }
  },
  methods: {
    openEdit(item) {
      router.push({ name: 'storemanagement', params: { tab: 'users', action: 'edit', itemId: item.user.id }});
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    getRole(user_id){
      const member = this.members.find(m => m.user == user_id)
      return member ? member.role == 1 ? 'Store manager' : 'User' : "--"
    },
    addMember(){
      if(this.$store.getters.activeStore.features && (this.profiles.length < this.$store.getters.activeStore.features.max_users)){
        this.$router.push({ name: 'storemanagement', params: { tab: 'users', action: 'new' }});
      } else {
        this.showModal = true;
      }
    },
    hideModal(){
      this.showModal = false;
    },
    addNewItem() {
      this.$router.push({ name: 'storemanagement', params: { tab: 'users', action: 'new' }});
    }
  }
}

</script>

<style lang="scss" scoped>

.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: $mainTransition;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>
