<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav/>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row d-flex align-items-center justify-content-between">
        <div class="col-sm-12 col-md-6 mt-md-5">
          <h1 class="mt-4">Manage account</h1>
        </div>
        <div v-if="defaultTab !== 'settings' && defaultTab !== 'carriers'  && !$route.params.action"
             class="col-sm-12 col-md-4 col-lg-3 mt-md-5 d-none d-md-block">
          <SamSecondaryButton @buttonAction="addNewItem(defaultTab)"
                              :text="`+ Add new ${defaultTab === 'addresses' ? defaultTab.replace('-', ' ').slice(0, -2) : defaultTab === 'leased-products' ? 'lease product' : defaultTab.replace('-', ' ').slice(0, -1)}`"
                              noSpace/>
        </div>
      </div>
      <div v-can="'management'">
        <SamTabs
            :tabs="tabs"
            :defaultTab="defaultTab"
        />
        <ProductManagement v-if="productTab"/>
        <LeaseProductManagement v-if="leaseProductTab"/>
        <UserManagement v-if="usersTab"/>
        <GroupManagement v-if="userGroupsTab"/>
        <AddressManagement v-if="addressTab"/>
        <SupplierManagement v-if="suppliersTab"/>
        <CcManagement v-if="ccTab"/>
        <TagManagement v-if="tagTab"/>
        <CarrierManagement v-if="carrierTab"/>
        <StoreSettings v-if="settingsTab"/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import TopNav from '@/modules/general/components/TopNav.vue'
import ProductManagement from './ProductManagement.vue'
import LeaseProductManagement from './LeaseProductManagement.vue'
import UserManagement from './UserManagement.vue'
import GroupManagement from './GroupManagement.vue'
import AddressManagement from './AddressManagement.vue'
import CcManagement from './CcManagement.vue'
import TagManagement from './TagManagement.vue'
import StoreSettings from './StoreSettings.vue'
import Footer from '@/modules/general/components/Footer.vue'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'
import SamTabs from '../../../components/SamTabs.vue'
import CarrierManagement from "./CarrierManagement.vue";
import SupplierManagement from '@/modules/storemanagement/pages/SupplierManagement.vue'


export default {
  name: 'StoreManagement',
  components: {
    SupplierManagement,
    SidebarNav,
    TopNav,
    Footer,
    ProductManagement,
    LeaseProductManagement,
    UserManagement,
    GroupManagement,
    AddressManagement,
    CcManagement,
    TagManagement,
    CarrierManagement,
    StoreSettings,
    SamSecondaryButton,
    SamTabs
  },
  data() {
    return {
      currentTab: this.$route.params.tab,
      tabs: [
        {
          name: 'Products',
          access: 'management_products',
          path: 'storemanagement',
          tab: 'products'
        },
        {
          name: 'Lease products',
          access: 'management_lease_products',
          path: 'storemanagement',
          tab: 'leased-products'
        },
        {
          name: 'Users',
          access: 'management_users',
          path: 'storemanagement',
          tab: 'users'
        },
        {
          name: 'User groups',
          access: 'management_groups',
          path: 'storemanagement',
          tab: 'user-groups'
        },
        {
          name: 'Addresses',
          access: 'management_locations',
          path: 'storemanagement',
          tab: 'addresses'
        },
        {
          name: 'Suppliers',
          access: 'management_locations',
          path: 'storemanagement',
          tab: 'suppliers'
        },
        {
          name: 'Cost centers',
          access: 'management_cost_centers',
          path: 'storemanagement',
          tab: 'cost-centers'
        },
        {
          name: 'Product tags',
          access: 'management_tags',
          path: 'storemanagement',
          tab: 'tags'
        },
        {
          name: 'Carriers',
          access: 'management_carriers',
          path: 'storemanagement',
          tab: 'carriers'
        },
        {
          name: 'Settings',
          access: 'management_settings',
          path: 'storemanagement',
          tab: 'settings'
        }
      ]
    }
  },
  mounted: function () {
    if (!this.$store.state.admin.optionsFetched) {
      this.$store.dispatch('admin/fetchManagementOptions')
    }

    if (!this.$store.state.admin.storeCarriersFetched) {
      this.$store.dispatch('fetchStoreCarriers')
    }
    // redirect if group data fetched and no manage store permissions
    if (this.$store.state.membership.group_data && !this.$store.state.membership.group_data['management']) {
      this.$router.push({name: 'productlist'});
    }
  },
  computed: {
    productTab() {
      return (this.$route.params.tab || this.defaultTab) === "products"
    },
    leaseProductTab() {
      return (this.$route.params.tab || this.defaultTab) === "leased-products"
    },
    usersTab() {
      return (this.$route.params.tab || this.defaultTab) === "users"
    },
    userGroupsTab() {
      return (this.$route.params.tab || this.defaultTab) === "user-groups"
    },
    addressTab() {
      return (this.$route.params.tab || this.defaultTab) === "addresses"
    },
    suppliersTab() {
      return (this.$route.params.tab || this.defaultTab) === "suppliers"
    },
    ccTab() {
      return (this.$route.params.tab || this.defaultTab) === "cost-centers"
    },
    tagTab() {
      return (this.$route.params.tab || this.defaultTab) === "tags"
    },
    settingsTab() {
      return (this.$route.params.tab || this.defaultTab) === "settings"
    },
    carrierTab() {
      return (this.$route.params.tab || this.defaultTab) == "carriers" ? true : false;
    },
    defaultTab() {
      const match = this.$route.params.tab

      if (!match) {
        // find default tab based on permissions
        if (this.$store.state.membership.group_data.management_products) {
          return 'products'
        } else if (this.$store.state.membership.group_data.management_lease_products) {
          return "leased-products"
        } else if (this.$store.state.membership.group_data.management_users) {
          return "users"
        } else if (this.$store.state.membership.group_data.management_groups) {
          return "user-groups"
        } else if (this.$store.state.membership.group_data.management_locations) {
          return "addresses"
        } else if (this.$store.state.membership.group_data.management_suppliers) {
          return "suppliers"
        } else if (this.$store.state.membership.group_data.management_cost_centers) {
          return "cost-centers"
        } else if (this.$store.state.membership.group_data.management_tags) {
          return "tags"
        } else if (this.$store.state.membership.group_data.management_carriers) {
          return "carriers"
        } else if (this.$store.state.membership.group_data.management_settings) {
          return "settings"
        }
      }
      return match;
    },
    membership() {
      return this.$store.getters.membership;
    },
    updating() {
      return this.membership.role ? false : true;
    },
  },
  watch: {
    membership: function (newMem) {
      // when membership is updated, check if user has manage store permissions
      if (newMem.group_data && !newMem.group_data['management']) {
        this.$router.push({name: 'productlist'});
      }

    }
  },
  methods: {
    addNewItem(item) {
      this.$router.push({name: 'storemanagement', params: {tab: item, action: 'new'}});
    }
  }
}
</script>
