<script>
import {email, requiredIf} from 'vuelidate/lib/validators'

export default {
  name: "CreateEditSupplier",
  props: [
    "editSupplier",
    "supplierData",
    "suppliers",
    "supplierTypes"
  ],
  data() {
    return {
      supplier: {
        name: null,
        email: null,
        phone: null,
        type: null,
        products: [],
        stock_products: [],
        lease_products: [],
        address: null,
        zip_code: null,
        city: null,
        country: null,
      },
      updating: false,
      showDeleteSupplierModal: false,
      supplierProducingProducts: [],
      supplierWarehousingProducts: [],
      supplierDataProducingProducts: [],
      supplierDataWarehousingProducts: [],
    }
  },
  validations: {
    supplier: {
      name: {
        required: requiredIf(function () {
          return !this.editSupplier
        })
      },
      email: {
        required: requiredIf(function () {
          return !this.editSupplier
        }),
        email
      },
      phone: {
        required: requiredIf(function () {
          return !this.editSupplier
        }),
        phoneFormat(phone) {
          return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)
        }
      },
      address: {
        required: requiredIf(function () {
          return !this.editSupplier
        })
      },
      zip_code: {
        required: requiredIf(function () {
          return !this.editSupplier
        })
      },
      city: {
        required: requiredIf(function () {
          return !this.editSupplier
        })
      },
      country: {
        required: requiredIf(function () {
          return !this.editSupplier
        })
      }
    },
    supplierData: {
      name: {
        required: requiredIf(function () {
          return this.editSupplier
        })
      },
      email: {
        required: requiredIf(function () {
          return this.editSupplier
        }),
        email
      },
      phone: {
        required: requiredIf(function () {
          return this.editSupplier
        }),
        phoneFormat(phone) {
          return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(phone)
        }
      },
      address: {
        required: requiredIf(function () {
          return this.editSupplier
        })
      },
      zip_code: {
        required: requiredIf(function () {
          return this.editSupplier
        })
      },
      city: {
        required: requiredIf(function () {
          return this.editSupplier
        })
      },
      country: {
        required: requiredIf(function () {
          return this.editSupplier
        })
      }
    }
  },
  beforeMount() {
    if (this.editSupplier && (this.supplierData === undefined || this.supplierData === null)) {
      this.$router.push({name: 'storemanagement', params: {tab: 'suppliers'}})
    }
  },
  mounted() {
    if (this.editSupplier && this.supplierData) {
      if (this.supplierData.type === 1) {
        this.supplierDataProducingProducts = this.supplierData.products
      } else if (this.supplierData.type === 2) {
        this.supplierDataWarehousingProducts = [...this.supplierData.stock_products, ...this.supplierData.lease_products]
      }
    }
    // retrieve products and lease_products from the server
    if (this.storeProducts.length === 0 || this.storeLeaseProducts.length === 0) {
      this.$store.dispatch('admin/clearAndFetchProducts', {append: false, pageSize: 250})
      this.$store.dispatch('admin/clearAndFetchLPs', {append: false, pageSize: 250})
    }
  },
  //TODO: THIS SHOULD BE IMPROVED BECAUSE THIS CAUSES ADDITONAL REQUEST TO BACKEND
  beforeDestroy() {
    this.$store.dispatch('admin/clearSuppliers')
  },
  computed: {
    storeSuppliersProducts() {
      return this.suppliers.map(s => s.products).flat()
    },
    storeSupplierStockProducts() {
      return this.suppliers.map(s => s.stock_products).flat()
    },
    storeSuppliersLeaseProducts() {
      return this.suppliers.map(s => s.lease_products).flat()
    },
    storeProducts() {
      const storeSuppliersProductIds = this.storeSuppliersProducts.map(p => p.id)
      return this.$store.getters['admin/products'].filter(p => !p.stock_management && p.status !== 3 && !storeSuppliersProductIds.includes(p.id))
    },
    storeStockProducts() {
      const storeSuppliersStockProductIds = this.storeSupplierStockProducts.map(p => p.id)
      return this.$store.getters['admin/products'].filter(p => p.stock_management && p.status !== 3 && !storeSuppliersStockProductIds.includes(p.id))
    },
    storeLeaseProducts() {
      const storeSuppliersLeaseProductIds = this.storeSuppliersLeaseProducts.map(p => p.id)
      return this.$store.getters['admin/leaseProducts'].filter(lp => lp.status !== 3 && !storeSuppliersLeaseProductIds.includes(lp.id))
    },
    producingProducts() {
      return this.storeProducts
    },
    warehousingProducts() {
      const products = this.storeStockProducts.map(p => ({id: p.id, name: p.name, type: 'product'}))
      const lease_products = this.storeLeaseProducts.map(lp => ({id: lp.id, name: lp.name, type: 'lease_product'}))
      return [...products, ...lease_products].map((wp, idx) => ({id: `${wp.id}_${idx}`, name: wp.name, type: wp.type}))
    },
    productsFromWarehousingProducts() {
      if (this.editSupplier) {
        return this.supplierDataWarehousingProducts.filter(wp => wp.type === 'product').map(wp => wp.id)
      }
      return this.supplierWarehousingProducts.filter(wp => wp.type === 'product').map(wp => wp.id)
    },
    leaseProductsFromWarehousingProducts() {
      if (this.editSupplier) {
        return this.supplierDataWarehousingProducts.filter(wp => wp.type === 'lease_product').map(wp => wp.id)
      }
      return this.supplierWarehousingProducts.filter(wp => wp.type === 'lease_product').map(wp => wp.id)
    },
    countries() {
      if (!this.$store.getters['admin/countriesFetched']) {
        this.$store.dispatch('admin/fetchCountries')
      }
      const countries = this.$store.getters['admin/countries'].map(c => ({name: c[1], value: c[0]}))
      // remove duplicates
      return countries.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
    },
    canDeleteSupplier() {
      if (this.editSupplier && !this.isFrammeSupplier && this.supplierData) {
        return this.supplierData.products.length === 0 && this.supplierData.stock_products.length === 0 && this.supplierData.lease_products.length === 0
      }
      return false
    },
    supplierTypeWrapper() {
      return this.supplier.type
    },
    supplierDataType() {
      return this.supplierData ? this.supplierData.type : null
    },
    isFrammeSupplier() {
      return this.supplierData ? this.supplierData.is_framme : false
    },
  },
  watch: {
    supplierDataType: function () {
      this.clearSelectedProducts()
    },
    supplierTypeWrapper: function () {
      this.clearSelectedProducts()
    }
  },
  methods: {
    openDeleteSupplierModal() {
      this.showDeleteSupplierModal = true
    },
    closeDeleteSupplierModal() {
      this.showDeleteSupplierModal = false
    },
    clearSelectedProducts() {
      this.supplierWarehousingProducts = []
      this.supplierDataWarehousingProducts = []
      this.supplierProducingProducts = []
      this.supplierDataProducingProducts = []
    },
    createSupplier() {
      this.$v.$touch()
      if (this.$v.supplier.$invalid) {
        return
      }
      this.updating = true
      const storeId = this.$store.getters.activeStore.id
      const data = this.supplier
      data['store'] = storeId
      data['products'] = this.supplierProducingProducts.map(p => p.id)
      data['stock_products'] = this.productsFromWarehousingProducts
      data['lease_products'] = this.leaseProductsFromWarehousingProducts
      // console.log('data', data)
      const vm = this
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + storeId + "/storesuppliers/",
        method: 'POST',
        data: data
      })
          .then(function () {
            const data = {
              pushRoute: true,
              routeLocation: {name: 'storemanagement', params: {tab: 'suppliers'}}
            }
            vm.$toastr.s("New supplier was created.")
            vm.$store.dispatch('admin/clearProducts')
            vm.$store.dispatch('admin/clearLeaseProducts')
            vm.$store.dispatch('admin/clearAndFetchSuppliers', data)
            // setTimeout(function() {
            //   vm.$router.push({ name: 'storemanagement', params: { tab: 'suppliers' }})
            // },500)
          })
          .catch(err => {
            vm.updating = false
            if (err.response && err.response.data) {
              vm.$toastr.e(JSON.stringify(err.response.data))
            }
          })
    },
    saveSupplier() {
      this.$v.$touch()
      if (this.$v.supplierData.$invalid) {
        return
      }
      this.updating = true
      const storeId = this.$store.getters.activeStore.id
      const supplierId = this.supplierData.id
      const data = this.supplierData
      data['products'] = this.supplierDataProducingProducts.map(p => p.id)
      data['stock_products'] = this.productsFromWarehousingProducts
      data['lease_products'] = this.leaseProductsFromWarehousingProducts
      // console.log('data', data)
      const vm = this
      this.$http({
        url: process.env.VUE_APP_API_URL + `/api/stores/${storeId}/storesuppliers/${supplierId}/`,
        method: 'PUT',
        data: data
      })
          .then(function () {
            const data = {
              pushRoute: true,
              routeLocation: {name: 'storemanagement', params: {tab: 'suppliers'}}
            }
            vm.$toastr.s("Supplier was updated.")
            vm.$store.dispatch('admin/clearProducts')
            vm.$store.dispatch('admin/clearLeaseProducts')
            vm.$store.dispatch('admin/clearAndFetchSuppliers', data)
            // setTimeout(function() {
            //   vm.$router.push({ name: 'storemanagement', params: { tab: 'suppliers' }})
            // },500)
          })
          .catch(err => {
            vm.updating = false
            if (err.response && err.response.data) {
              vm.$toastr.e(JSON.stringify(err.response.data))
            }
          })
    },
    deleteSupplier() {
      if (!this.canDeleteSupplier) {
        this.$toastr.e('Supplier can’t be deleted as it’s supplying products.')
        return
      }
      this.updating = true
      const storeId = this.$store.getters.activeStore.id
      const supplierId = this.supplierData.id
      const vm = this
      this.$http({
        url: process.env.VUE_APP_API_URL + `/api/stores/${storeId}/storesuppliers/${supplierId}/`,
        method: 'DELETE'
      })
          .then(function () {
            const data = {
              pushRoute: true,
              routeLocation: {name: 'storemanagement', params: {tab: 'suppliers'}}
            }
            vm.$toastr.s("Supplier was deleted.")
            vm.$store.dispatch('admin/clearAndFetchSuppliers', data)
            // setTimeout(function() {
            //   vm.$router.push({ name: 'storemanagement', params: { tab: 'suppliers' }})
            // },2500)
          })
          .catch(err => {
            vm.updating = false
            if (err.response && err.response.data) {
              vm.$toastr.e(JSON.stringify(err.response.data))
            }
          })
    },
    getWarehousingProductId(wpId) {
      // to prevent duplicated keys in warehousing products
      // IDs represented as "productId_indexInArray"
      if (typeof wpId === "number") {
        return wpId
      } else if (typeof wpId === "string") {
        return wpId.split("_").map(Number)[0]
      }
      return null
    }
  },
}
</script>

<template>
  <div>
    <!-- Modal  -->
    <div v-if="showDeleteSupplierModal" class="modal fade in show modal-active modal-open" id="deleteMemberModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Are you sure you want to delete supplier?</h4>
            <button type="button" class="close" @click="closeDeleteSupplierModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Once a supplier is deleted, it will be gone from the system and can not be undone.
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="col-sm-4">
              <button type="button" class="btn btn-lg btn-outline-primary btn-block"
                      @click="closeDeleteSupplierModal()">Cancel
              </button>
            </div>
            <div class="col-sm-4">
              <button type="button" class="btn btn-lg btn-primary btn-block" @click="deleteSupplier()">Delete supplier
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDeleteSupplierModal" class="modal-backdrop show"></div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-sm-3">
          <div>Supplier name*</div>
          <input v-if="editSupplier && supplierData" type="text" class="inputfield" v-model.trim="supplierData.name"
                 :disabled="isFrammeSupplier">
          <input v-else type="text" class="inputfield" v-model.trim="supplier.name">
          <div v-if="$v.supplierData.name.$error">
            <div class="form-field-error" v-if="!$v.supplierData.name.required">Supplier name is required</div>
          </div>
          <div v-if="$v.supplier.name.$error">
            <div class="form-field-error" v-if="!$v.supplier.name.required">Supplier name is required</div>
          </div>
        </div>
        <div class="col-sm-3">
          <!-- Supplier email -->
          <div class="mt-4 mt-sm-0">Supplier email*</div>
          <input v-if="editSupplier && supplierData" type="text" class="inputfield" v-model.trim="supplierData.email"
                 :disabled="isFrammeSupplier">
          <input v-else type="text" class="inputfield" v-model.trim="supplier.email">
          <div v-if="$v.supplierData.email.$error">
            <div class="form-field-error" v-if="!$v.supplierData.email.required">Email is required</div>
            <div class="form-field-error" v-else-if="!$v.supplierData.email.email">Email seems invalid</div>
          </div>
          <div v-if="$v.supplier.email.$error">
            <div class="form-field-error" v-if="!$v.supplier.email.required">Email is required</div>
            <div class="form-field-error" v-else-if="!$v.supplier.email.email">Email seems invalid</div>
          </div>
          <!-- Supplier phone -->
          <div class="mt-4">Supplier phone*</div>
          <input v-if="editSupplier && supplierData" type="text" class="inputfield" v-model.trim="supplierData.phone"
                 :disabled="isFrammeSupplier">
          <input v-else type="text" class="inputfield" v-model.trim="supplier.phone">
          <div v-if="editSupplier && $v.supplierData.phone.$error">
            <div class="form-field-error" v-if="!$v.supplierData.phone.required">Phone number is required</div>
            <div class="form-field-error" v-else-if="!$v.supplierData.phone.phoneFormat">Phone number format seems
              invalid
            </div>
          </div>
          <div v-if="!editSupplier && $v.supplier.phone.$error">
            <div class="form-field-error" v-if="!$v.supplier.phone.required">Phone number is required</div>
            <div class="form-field-error" v-else-if="!$v.supplier.phone.phoneFormat">Phone number format seems invalid
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <!-- Supplier types -->
          <div class="mt-4 mt-sm-0">Supplier type(s)*</div>
          <v-select
              v-if="editSupplier && supplierData"
              class="sam-select"
              :options="supplierTypes"
              :reduce="t => t.value"
              v-model="supplierData.type"
              :disabled="isFrammeSupplier"
              placeholder="Select supplier type"
          ></v-select>
          <v-select
              v-else
              class="sam-select"
              :options="supplierTypes"
              :reduce="t => t.value"
              v-model="supplier.type"
              placeholder="Select supplier type"
          ></v-select>
          <!-- Producing products -->
          <div v-if="supplier.type === 1 || (editSupplier && supplierDataType === 1)">
            <div class="mt-4">Producing products</div>
            <v-select
                v-if="editSupplier"
                class="sam-select"
                multiple
                label="name"
                :options="producingProducts"
                :reduce="p => ({id: p.id, name: p.name, type: p.type})"
                v-model="supplierDataProducingProducts"
            ></v-select>
            <v-select
                v-else
                class="sam-select"
                multiple
                label="name"
                :options="producingProducts"
                :reduce="p => ({id: p.id, name: p.name, type: p.type})"
                v-model="supplierProducingProducts"
            ></v-select>
          </div>
          <!-- Warehousing products -->
          <div v-if="supplier.type === 2 || (editSupplier && supplierDataType === 2)">
            <div class="mt-4">Warehousing products</div>
            <v-select
                v-if="editSupplier"
                class="sam-select"
                multiple
                label="name"
                :options="warehousingProducts"
                :reduce="wp => ({id: getWarehousingProductId(wp.id), name: wp.name, type: wp.type})"
                v-model="supplierDataWarehousingProducts"
            ></v-select>
            <v-select
                v-else
                class="sam-select"
                multiple
                label="name"
                :options="warehousingProducts"
                :reduce="wp => ({id: getWarehousingProductId(wp.id), name: wp.name, type: wp.type})"
                v-model="supplierWarehousingProducts"
            ></v-select>
          </div>
        </div>
        <div class="col-sm-3">
          <!-- Street address -->
          <div class="mt-4 mt-sm-0">Street address*</div>
          <input v-if="editSupplier && supplierData" type="text" class="inputfield" v-model.trim="supplierData.address"
                 :disabled="isFrammeSupplier">
          <input v-else type="text" class="inputfield" v-model.trim="supplier.address">
          <div v-if="$v.supplierData.address.$error">
            <div class="form-field-error" v-if="!$v.supplierData.address.required">Address is required</div>
          </div>
          <div v-if="$v.supplier.address.$error">
            <div class="form-field-error" v-if="!$v.supplier.address.required">Address is required</div>
          </div>
          <!-- Postal code -->
          <div class="mt-4">Postal code*</div>
          <input v-if="editSupplier && supplierData" type="text" class="inputfield"
                 v-model.trim="supplierData.zip_code" :disabled="isFrammeSupplier">
          <input v-else type="text" class="inputfield" v-model.trim="supplier.zip_code">
          <div v-if="$v.supplierData.zip_code.$error">
            <div class="form-field-error" v-if="!$v.supplierData.zip_code.required">Zip code is required</div>
          </div>
          <div v-if="$v.supplier.zip_code.$error">
            <div class="form-field-error" v-if="!$v.supplier.zip_code.required">Zip code is required</div>
          </div>
          <!-- City -->
          <div class="mt-4">City*</div>
          <input v-if="editSupplier && supplierData" type="text" class="inputfield" v-model.trim="supplierData.city"
                 :disabled="isFrammeSupplier">
          <input v-else type="text" class="inputfield" v-model.trim="supplier.city">
          <div v-if="$v.supplierData.city.$error">
            <div class="form-field-error" v-if="!$v.supplierData.city.required">City is required</div>
          </div>
          <div v-if="$v.supplier.city.$error">
            <div class="form-field-error" v-if="!$v.supplier.city.required">City is required</div>
          </div>
          <!-- Country -->
          <div class="mt-4">Country*</div>
          <v-select
              v-if="editSupplier && supplierData"
              class="sam-select"
              label="name"
              :options="countries"
              :reduce="c => c.value"
              v-model="supplierData.country"
              placeholder="Select country"
              :disabled="isFrammeSupplier"
          ></v-select>
          <v-select
              v-else
              class="sam-select"
              label="name"
              :options="countries"
              :reduce="c => c.value"
              v-model="supplier.country"
              placeholder="Select country"
          ></v-select>
          <div v-if="$v.supplierData.country.$error">
            <div class="form-field-error" v-if="!$v.supplierData.country.required">Country is required</div>
          </div>
          <div v-if="$v.supplier.country.$error">
            <div class="form-field-error" v-if="!$v.supplier.country.required">Country is required</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <hr class="mt-4"/>
        </div>
      </div>
      <div class="row" :class="{'mb-5': !editSupplier}">
        <div class="col-sm-3">
          <button v-if="editSupplier" class="btn btn-lg btn-primary btn-block mt-3" @click="saveSupplier()">Save
            changes
          </button>
          <button v-else class="btn btn-lg btn-primary btn-block mt-3" @click="createSupplier()">Create supplier
          </button>
        </div>
        <div class="col-sm-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'suppliers' }}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-3">Back to all suppliers</button>
          </router-link>
        </div>
        <div class="offset-sm-3 col-sm-3">
          <button
              v-if="editSupplier && canDeleteSupplier"
              class="btn btn-lg btn-danger btn-block mt-3"
              @click="openDeleteSupplierModal()"
          >Delete supplier
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
