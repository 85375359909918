<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="archiveProductModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{product.name}} is ready to be published. Send an update to all store users?</h4>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Would you like to send an email update to all {{$store.state.activeStore.name}} store users about the new {{product.name}} item?
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
            <div class="col-3 pr-0">
              <button type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="userNotification(false)">No</button>
            </div>
            <div class="col-3 pl-0">
              <button type="button" class="btn btn-lg btn-primary mt-2 w-100" v-on:click="userNotification(true)">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>  
</template>

<script>
export default {
  name: "NotifyUsersModal",
  props: [
    "product"
  ],
  data(){
    return {
    }
  },
  methods: {
    closeModal(){
      this.$emit('closeNotifyUsersModal');
    },
    userNotification(send){
      this.$emit('userNotification', send);
    }
  }
}
</script>