<template>
  <div>
    <ArchiveProductModal v-if="showArchiveProductModal" @closeArchiveProductModal="closeArchiveProductModal" @archiveProduct="updateProduct(true)" />
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-sm-3">
          <div>Product image</div>
          <img v-if="productImage" class="img-width" :src="productImage" />
          <div>
            <label for="product_image" class="custom-file-upload mt-3">
              <i class="fa fa-cloud-upload"></i> Choose file
            </label>
            <input class="inputfile" type="file" id="product_image" ref="product_image" v-on:change="handleFileUpload(true)"/>
          </div>
        </div>
        <div class="col-sm-3">
          <div>Product name*</div>
          <input type="text" class="inputfield" v-model="product.name">
          <div v-if="$v.product.name.$error">
            <div class="form-field-error" v-if="!$v.product.name.required">Product name is required</div>
          </div>
          <div v-else-if="showNotUnique">
            <div class="form-field-error">Lease product with this name already exists</div>
          </div>
          <div class="mt-4">Product decription*</div>
          <div><textarea rows=10 v-model="product.description" /></div>
          <div v-if="$v.product.description.$error">
            <div class="form-field-error" v-if="!$v.product.description.required">Product description is required</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div>Product category*</div>
          <v-select
            label="name"
            v-model="product.category"
            :options="categoryArray"
            :reduce="c => c.value"
            :clearable="false"
            placeholder="Select category"
          ></v-select>
          <div v-if="$v.product.category.$error">
            <div class="form-field-error" v-if="!$v.product.category.required">Product category is required</div>
          </div>
          <div class="mt-4">Estimated delivery time*</div>
          <v-select
            label="name"
            v-model="product.delivery_time"
            :options="deliveryTimeArray"
            :reduce="d => d.value"
            :clearable="false"
            placeholder="Select delivery time"
          ></v-select>
          <div v-if="$v.product.delivery_time.$error">
            <div class="form-field-error" v-if="!$v.product.delivery_time.required">Product delivery time is required</div>
          </div>
          <div class="mt-4">SKU/Product ID</div>
          <input class="inputfield" v-model="product.ext_product_id" type="text">
        </div>
        <div class="col-sm-3">
          <div>Product tags</div>
          <v-select
            label="name"
            v-model="product.tags"
            :options="tags"
            :reduce="t => t.id"
            multiple
            placeholder="Select tags"
            @input="tagsChanged = true"
          ></v-select>
          <div class="mt-4">Status</div>
          <v-select
            label="name"
            v-model="product.status"
            :options="statusArray"
            :reduce="s => s.value"
            :clearable="false"
            placeholder="Select status"
          ></v-select>
          <div class="mt-4">Show only to user group(s)</div>
          <v-select
            label="name"
            v-model="product.groups"
            :options="$store.getters.nonAdminGroups"
            :reduce="s => s.id"
            :clearable="true"
            multiple
            placeholder="All user groups"
          ></v-select>
          <div v-if="$store.getters.isAdmin && !product.stock_management">
            <div class="mt-4">Warehouse (supplier)*</div>
            <v-select
              label="name"
              v-model="product.warehouse"
              :options="storeWarehouses"
              :reduce="s => s.id"
              :clearable="false"
              placeholder="Select warehouse"
            ></v-select>
            <div v-if="$v.product.warehouse.$error">
              <div class="form-field-error" v-if="!$v.product.warehouse.required">Warehouse is required</div>
            </div>
          </div>
<!--          <div class="mt-4">Order recipient email*</div>-->
<!--          <input type="text" class="inputfield" v-model="product.recipient_email">-->
<!--          <div v-if="$v.product.recipient_email.$error">-->
<!--            <div class="form-field-error" v-if="!$v.product.recipient_email.required">Recipient email is required</div>-->
<!--            <div class="form-field-error" v-else-if="!$v.product.recipient_email.email">Email doesn't seem valid</div>-->
<!--            <div class="form-field-error" v-else-if="!$v.product.recipient_email.validChars">å, ä, ö are not allowed characters</div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-4">
          <hr />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-sm-3">
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="updateProduct()" v-if="editProduct">Save changes</button>
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="createProduct()" v-else>Create leased product</button>
        </div>
        <div class="col-sm-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'leased-products' }, hash: urlHash}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to leased products</button>
          </router-link>
        </div>
        <div class="col-sm-3">
        </div>
        <div class="col-sm-3">
          <router-link v-if="editProduct" :to="{ name: 'storemanagement', params: { tab: 'leased-products', action: 'new', cloneProduct: true}}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Duplicate product</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchiveProductModal from './ArchiveProductModal.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditLeaseProduct',
  props: [
    "editProduct",
    "productData"
  ],
  components: {
    ArchiveProductModal
  },
  data() {
    return {
      newProduct: {
        name: null,
        description: null,
        category: null,
        delivery_time: null,
        ext_product_id: null,
        tags: [],
        groups: [],
        status: 1,
        recipient_email: null,
        warehouse: null,
      },
      product: {},
      product_image: null,
      product_images: [],
      showPreview: false,
      imagePreview: null,
      acceptedMimeTypes: [
        'image/gif',
        'image/jpeg',
        'image/png'
      ],
      updating: false,
      allSelected: false,
      showNotUnique: false,
      showArchiveProductModal: false,
      tagsChanged: false,
      warehouseChanged: false,
    }
  },
  validations: {
    product: {
      name: {
        required
      },
      description: {
        required
      },
      category: {
        required
      },
      delivery_time: {
        required
      },
      recipient_email: {
        required,
        email,
        validChars(email){
          if(email == "" || email == null){
            return true;
          }
          // Check email doesn't contain å, ä, ö
          return !['å', 'ä', 'ö'].some(e => email.includes(e));
        },
      },
      warehouse: {
        required
      }
    }
  },
  watch:{
    $route: function(to, from){
      if(to.params.cloneProduct){
        // create clone

        // save original image url
        const img_url = this.product.primary_image;
        // clear image related fields
        this.imagePreview = null;
        this.product_image = null;
        this.showPreview = false;
        // copy values to new product
        const copied = {
          'category': this.product.category,
          'delivery_time': this.product.delivery_time,
          'description': this.product.description,
          'name': this.product.name,
          'recipient_email': this.product.recipient_email,
          'status': this.product.status,
          'store': this.product.store
        }

        if(img_url){
          const vm = this;
          // modify url to make cors fetch work after img-tag
          // has already loaded and cached the image
          // fetch the image from copied url
          const myRequest = new Request(img_url+"?_");
          fetch(myRequest).then(function(response) {
            response.blob().then(function(myBlob) {
              const attName = img_url.split('/').pop();
              vm.product_image = myBlob;
              vm.product_image.name = attName;
              let thumbReader  = new FileReader();
              thumbReader.addEventListener("load", function () {
                vm.showPreview = true;
                vm.imagePreview = thumbReader.result;
              }.bind(this), false);
              thumbReader.readAsDataURL( myBlob );
            });
          }).catch(function(){
            vm.$toastr.e("Failed to copy image");
          });
        }
        this.product = {...this.newProduct, ...copied}
        this.$toastr.s("Duplicating content for a new product.");
      } else if(to.params.action == 'edit' && from.params.cloneProduct == true){
        // restore product
        this.product = {...this.productData};
      }
    },
    product_image: function(newVal){
      if(newVal && newVal.name){
        if(newVal.name.substring(0,newVal.name.lastIndexOf(".")).length > 80){
          // Make sure the filename is max 80char + extension
          let newFileName = newVal.name.substring(0,newVal.name.lastIndexOf(".")).substring(0,80);
          const fileExt = newVal.name.substring(newVal.name.lastIndexOf(".")+1)
          if(!newVal.name.lastIndexOf(".")){
            // no dot in filename, just shorten the string
            newFileName = newVal.name.substring(0,80);
          } else {
            // add file extension to name
            newFileName += "." + fileExt;
          }
          // replace the file with new one to update the name
          this.product_image = new File([this.product_image], newFileName, {
            type: this.product_image.type,
            lastModified: this.product_image.lastModified,
          });
        }
      }
    },
    productWarehouse: function (newVal, oldVal) {
      this.warehouseChanged = newVal !== oldVal
    }
  },
  mounted(){
    if(this.editProduct){
      if(this.productData && this.productData.id){
        // make a copy of productData
        this.product = {...this.productData}
      } else {
        // No product data available, fetch it
        this.getLeaseProduct();
      }
    } else {
      this.product = {...this.newProduct}
    }
  },
  computed: {
    options(){
      return this.$store.getters['admin/productOptions'];
    },
    productImage(){
      if(!this.showPreview && this.product.primary_image){
        if(this.product.primary_image.startsWith(process.env.VUE_APP_IMAGE_HOST)){
          return this.product.primary_image;
        } else {
          return process.env.VUE_APP_IMAGE_HOST + this.product.primary_image;
        }
      } else if(this.showPreview && this.imagePreview){
        return this.imagePreview;
      }
      return null;
    },
    isUniqueName(){
      if(this.$store.state.admin.managementOptions && this.$store.state.admin.managementOptions.lease_products){
        const found = this.$store.state.admin.managementOptions.lease_products.find(lp => (lp.name == this.product.name) && (lp.id != this.product.id))
        return found ? false : true;
      }
      return true;
    },
    categoryArray(){
      if(!this.options.categories){
        return [];
      }
      return this.options.categories.map(c => ({name: c[1], value: c[0]}))
    },
    deliveryTimeArray(){
      if(!this.options.delivery_times){
        return [];
      }
      return this.options.delivery_times.map(d => ({name: d[1], value: d[0]}))
    },
    statusArray(){
      if(!this.options.statuses){
        return [];
      }
      return this.options.statuses.map(s => ({name: s[1], value: s[0]}))
    },
    tags(){
      return this.$store.getters.productTags;
    },
    urlHash(){
      if(this.$route.params.hash){
        return '#' + this.$route.params.hash
      }
      return ""
    },
    storeSuppliers() {
      if (!this.$store.getters['admin/suppliersFetched']) {
        this.$store.dispatch('admin/fetchSuppliers')
      }
      return this.$store.getters['admin/suppliers']
    },
    storeWarehouses() {
      // 2 - Warehouse (all store products that has "Manage Stock" on and lease products)
      return this.storeSuppliers.filter(s => s.type === 2)
    },
    productWarehouse() {
      return this.product.warehouse
    },
  },
  methods: {
    createProduct(){
      this.updating = true;
      if(!this.isUniqueName){
        this.updating = false;
        this.showNotUnique = true;
        return;
      }
      this.showNotUnique = false;
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.updating = false;
        return;
      }
      const vm = this;
      let data = {...this.product};
      data.store = this.$store.getters.activeStore.id;
      // update picture separately
      delete data.primary_image;
      delete data.primary_thumb_big;
      delete data.primary_thumb_small;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + data.store + '/leaseproducts/';
      let method = 'POST';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        // refresh suppliers in global state
        vm.$store.dispatch('admin/clearAndFetchSuppliers')

        if(vm.tagsChanged){
          vm.$store.dispatch('updateProductToTags', {productId: data.id, tagIds: data.tags, isLeaseProduct: true})
          vm.tagsChanged = false;
        }
        // add picture after product info
        if(vm.product_image && vm.product_image.name){
          var api_url = process.env.VUE_APP_API_URL + '/api/stores/' + data.store + '/leaseproducts/' + response.data.id + "/";
          const prodId = response.data.id;
          vm.sendPicture(api_url)
          .then(function(result) {
            // add product to VUEX with new picture included
            vm.$store.dispatch('admin/addLeaseProduct', result.data);
            vm.$store.dispatch('admin/fetchManagementOptions');
            vm.$toastr.s("New lease product has been created.");
            vm.updating = false;
            document.body.style.cursor='default';
            // move to edit product after creation
            vm.$router.push({ name: 'storemanagement', params: { tab: 'leased-products', action: 'edit', itemId: prodId }});
          })
          .catch(function () {
            vm.$toastr.w("New product was successfully created, but picture upload failed.");
            // move to edit product after creation, even if picture upload fails
            vm.$router.push({ name: 'storemanagement', params: { tab: 'leased-products', action: 'edit', itemId: prodId }});
            document.body.style.cursor='default';
            vm.updating = false;
          });
        } else {
          // add product to VUEX if no new picture
          vm.$store.dispatch('admin/addLeaseProduct', response.data);
          vm.$store.dispatch('admin/fetchManagementOptions');
          vm.$toastr.s("New product has been created.");
          vm.updating = false;
          document.body.style.cursor='default';
          // move to edit product after creation
          vm.$router.push({ name: 'storemanagement', params: { tab: 'leased-products', action: 'edit', itemId: response.data.id }});
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    updateProduct(archiveConfirm=false){
      this.updating = true;
      if(!this.isUniqueName){
        this.updating = false;
        this.showNotUnique = true;
        return;
      }
      this.showNotUnique = false;
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.updating = false;
        return;
      }
      if([1,3].includes(this.product.status)){
        // Status is draft or archive
        if(![1,9].includes(this.product.lease_status)){
          // lease status not in stock or oos
          this.$toastr.e("Lease product can only be archived or drafted when its lease status is 'In stock' or 'Out of Service.'");
          this.updating = false;
          return;
        }
      }
      if(this.product.status == 3 && !archiveConfirm){
        // Show modal if trying to archive without confirm
        this.updating = false;
        this.showArchiveProductModal = true;
        return;
      } else if(this.product.status == 3 && archiveConfirm){
        // close modal and update
        this.showArchiveProductModal = false;
        // remove tags from product when archiving
        this.product.tags = [];
        this.tagsChanged = true;
      }
      const vm = this;
      let data = {...this.product};
      // update picture separately
      delete data.primary_image;
      delete data.primary_thumb_big;
      delete data.primary_thumb_small;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + data.store + '/leaseproducts/' + this.product.id + "/"
      let method = 'PUT';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        if (vm.warehouseChanged) {
          // refresh suppliers in global state
          vm.$store.dispatch('admin/clearAndFetchSuppliers')
        }

        var api_url = process.env.VUE_APP_API_URL + '/api/stores/' + data.store + '/leaseproducts/' + response.data.id + "/";
        if(vm.tagsChanged){
          vm.$store.dispatch('updateProductToTags', {productId: data.id, tagIds: data.tags, isLeaseProduct: true});
          vm.tagsChanged = false;
        }
        // update picture after product info
        if(vm.product_image && vm.product_image.name){
          vm.sendPicture(api_url).then(function(result) {
            // update product to VUEX with new picture included
            vm.$store.dispatch('admin/updateLeaseProduct', result.data)
            // update also to catalog
            vm.$store.dispatch('updateLeaseProduct', result.data)
            vm.$toastr.s("Your changes have been saved.");
            vm.updating = false;
            document.body.style.cursor='default';
          } );
        } else {
          // update product to VUEX if no new picture
          vm.$store.dispatch('admin/updateLeaseProduct', response.data)
          // update also to catalog
          vm.$store.dispatch('updateLeaseProduct', response.data)
          vm.$toastr.s("Your changes have been saved.");
          vm.updating = false;
          document.body.style.cursor='default';
        }
        if(archiveConfirm){
          // redirect to productlist if archived
          vm.$router.push({ name: 'storemanagement', params: { tab: 'leased-products' }});
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    handleFileUpload(primary){
      if(primary && this.$refs.product_image.files.length == 0){
        return;
      }else if(!primary && this.$refs.product_images.files.length == 0){
        return;
      }
      let file = primary ? this.$refs.product_image.files[0] : this.$refs.product_images.files[0];

      // Check mime type and file size
      if(!this.acceptedMimeTypes.includes(file.type)){
        if(primary){
          this.$refs.product_image.value = null;
        }else{
          this.$refs.product_images.value = null;
        }
        this.$toastr.e("Please choose gif, jpg or png file.");
        return;
      } else if(file.size > 4000000){
        if(primary){
          this.$refs.product_image.value = null;
        }else{
          this.$refs.product_images.value = null;
        }
        this.$toastr.e("File size must be under 4MB");
        return;
      }

      if(primary){
        this.product_image = this.$refs.product_image.files[0];
        // Show preview of the image
        let reader  = new FileReader();
        reader.addEventListener("load", function () {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this), false);
        if( this.product_image ){
          if ( /\.(jpe?g|png|gif)$/i.test( this.product_image.name ) ) {
            reader.readAsDataURL( this.product_image );
          }
        }
      }else{
        let newImg = {file: this.$refs.product_images.files[0]}
        let reader  = new FileReader();
        const vm = this;
        reader.addEventListener("load", function () {
          newImg['thumb_big'] = reader.result;
          newImg['id'] = null;
          vm.product_images.push(newImg);
        }.bind(this), false);
        if( newImg.file ){
          if ( /\.(jpe?g|png|gif)$/i.test( newImg.file.name ) ) {
            reader.readAsDataURL( newImg.file );
          }
        }
        this.$refs.product_images.value = null;
      }
    },
    sendPicture(api_url){
      let productFormData = new FormData();
      const vm = this;
      const store = this.$store.getters.activeStore;
      if(this.product_image instanceof Blob){
        productFormData.append('primary_image', this.product_image, this.product_image.name);
      } else {
        productFormData.append('primary_image', this.product_image);
      }
      productFormData.append('store', store.id);
      let headers = {};
      headers['Content-Type'] = 'multipart/form-data';
      return this.$http({
        method: 'PATCH',
        url: api_url,
        data: productFormData,
        headers: headers
      })
      .then(function (response) {
        //return vm.sendGalleryImages(api_url).then(function(){
          return response;
        //})
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        }
        vm.$toastr.e(error);
        document.body.style.cursor='default';
      });
    },
    closeArchiveProductModal(){
      this.showArchiveProductModal = false;
    },
    getLeaseProduct(){
      const vm = this;
      const leaseId = this.$route.params.itemId;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/leaseproducts/' + leaseId + "/";
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
      .then(function (response) {
        //  update lease order
        vm.product = response.data;
        vm.updating = false;
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    }
  }
}

</script>
