<template>
  <div>
    <DeleteTagModal v-if="showDeleteTagModal" v-bind:delTag="delTag" @closeDeleteTagModal="closeDeleteTagModal" @deleteTag="deleteTag" />
    <div v-if="updating || fetching">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-md-3">
          <div>Product tag name*</div>
          <input type="text" class="inputfield" v-model.trim="tag.name">
          <div v-if="$v.tag.name.$error">
            <div class="form-field-error" v-if="!$v.tag.name.required">Tag name is required</div>
          </div>
        </div>
        <div class="col-md-3">
          <div>Products with this tag</div>
          <v-select multiple
                    label="name"
                    :options="products"
                    :reduce="p => p.id"
                    v-model="tag.products"
                    @input="productsChanged = true">
          </v-select>
        </div>
        <div class="col-md-3">
          <div>Lease products with this tag</div>
          <v-select multiple
                    label="name"
                    :options="leaseProducts"
                    :reduce="lp => lp.id"
                    v-model="tag.lease_products"
                    @input="leaseProductsChanged = true">

          </v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-4">
          <hr />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-3">
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveTag(create=false)" v-if="editTag">Save changes</button>
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="saveTag(create=true)" v-else>Create new tag</button>
        </div>
        <div class="col-md-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'tags' }}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to tags</button>
          </router-link>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <button v-if="editTag" class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="deleteTagModal()">Delete this product tag</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import DeleteTagModal from './DeleteTagModal.vue'

export default {
  name: 'CreateEditTag',
  props: [
    "editTag",
    "tagData"
  ],
  components: {
    DeleteTagModal
  },
  data() {
    return {
      tag: {},
      newTag: {
        name: null,
        products: [],
        lease_products: [],
        store: null
      },
      tagProducts: [],
      updating: false,
      showDeleteTagModal: false,
      delTag: {},
      productsChanged: false,
      leaseProductsChanged: false
    }
  },
  validations: {
    tag: {
      name: {
        required
      },
    }
  },
  mounted(){
    if(this.editTag && this.tagData && this.tagData.id){
      // make a copy of CCData
      this.tag = {...this.tagData}
    } else {
      this.tag = {...this.newTag}
      this.tag.store = this.$store.getters.activeStore.id;
    }

    if (this.products.length === 0 || this.leaseProducts.length === 0) {
      this.$store.dispatch('admin/clearAndFetchProducts', {append: false, pageSize: 250})
      this.$store.dispatch('admin/clearAndFetchLPs', {append: false, pageSize: 250})
    }
  },
  watch: {
    tagData: function(newVal){
      // Watch if tagData is updated later after page refresh
      if(this.editTag && !this.tag.id){
        this.tag = {...newVal};
      }
    }
  },
  computed: {
    products(){
      return this.$store.getters['admin/products'].filter(p => p.status !== 3)
    },
    leaseProducts(){
      return this.$store.getters['admin/leaseProducts'].filter(lp => lp.status !== 3)
    },
    fetching(){
      return (this.$store.getters['admin/fetchingProducts'] || this.$store.getters['admin/fetchingLeaseProducts'])
    },
  },
  methods: {
    saveTag(create){
      this.updating = true;
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.updating = false;
        return;
      }
      const vm = this;
      let data = {...this.tag};

      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.tag.store + '/tags/' + this.tag.id + '/';
      let method = 'PUT';
      if(create) {
        api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.tag.store + '/tags/';
        method = 'POST';
      }
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        if(create){
          // add new tag to VUEX
          vm.$store.dispatch('addProductTag', response.data)
          vm.$toastr.s("New tag created.");
          setTimeout(function() {
            vm.updating = false;
            vm.$router.push({ name: 'storemanagement', params: { tab: 'tags' }});
          },2500);
        } else {
          // update tag to VUEX
          vm.$store.dispatch('updateProductTag', response.data)
          vm.$toastr.s("Your changes have been saved.");
          vm.updating = false;
        }
        if(vm.productsChanged || vm.leaseProductsChanged){
          const upd = {
            tagId: data.id ? data.id : response.data.id,
            productIds: data.products,
            leaseProductIds: data.lease_products,
            productsChanged: vm.productsChanged,
            leaseProductsChanged: vm.leaseProductsChanged
          }
          vm.$store.dispatch('updateTagToProducts', upd)
          vm.productsChanged = false;
          vm.leaseProductsChanged = false;
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    deleteTagModal(){
      this.delTag = {...this.tag}
      this.showDeleteTagModal = true;
    },
    closeDeleteTagModal(){
      this.delTag = {};
      this.showDeleteTagModal = false;
    },
    deleteTag(){
      this.updating = true;
      this.showDeleteTagModal = false;
      const tagId = this.delTag.id;
      const vm = this;
      const storeId = this.$store.getters.activeStore.id;
      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + storeId + '/tags/' + tagId + '/';
      const method = 'delete';
      this.$http({
        method: method,
        url: api_url
      })
      .then(function () {
        // update VUEX
        vm.$store.dispatch('deleteProductTag', {tagId: tagId})
        vm.$toastr.s("Product tag deleted.");
        setTimeout(function() {
          vm.updating = false;
          vm.$router.push({ name: 'storemanagement', params: { tab: 'tags' }});
        },2500);
      })
      .catch(function (error) {
        vm.closeDeleteTagModal();
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    }
  }
}

</script>