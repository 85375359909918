<template>
  <div>
    <!-- Modal  -->
    <div v-if="showArchiveCCModal" class="modal fade in show modal-active modal-open" id="deleteMemberModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Are you sure you want to delete cost center?</h4>
            <button type="button" class="close" @click="closeArchiveCCModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Once a cost center is deleted, it will be gone from the system and can not be undone.
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="col-sm-4">
              <button type="button" class="btn btn-lg btn-outline-primary btn-block"
                      @click="closeArchiveCCModal()">Cancel
              </button>
            </div>
            <div class="col-sm-4">
              <button type="button" class="btn btn-lg btn-primary btn-block" @click="archiveCC()">Delete cost center
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showArchiveCCModal" class="modal-backdrop show"></div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-sm-3">
          <div>Cost center name*</div>
          <input type="text" class="inputfield" v-model.trim="cc.name" :disabled="!canDeleteCC">
          <div v-if="$v.cc.name.$error">
            <div class="form-field-error" v-if="!$v.cc.name.required">Cost center name is required</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="mt-4 mt-sm-0">Cost center ID or PO number*</div>
          <input type="text" class="inputfield" v-model.trim="cc.cc_id" :disabled="!canDeleteCC">
          <div v-if="$v.cc.cc_id.$error">
            <div class="form-field-error" v-if="!$v.cc.cc_id.required">ID or PO number is required</div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="mt-4 mt-sm-0">Official company name</div>
          <input type="text" class="inputfield" v-model.trim="formattedCompanyName" :disabled="!canDeleteCC">

          <div class="mt-4">Company ID</div>
          <input type="text" class="inputfield" v-model.trim="formattedCompanyID" :disabled="!canDeleteCC">

          <div class="mt-4">Company VAT</div>
          <input type="text" class="inputfield" v-model.trim="formattedCompanyVat" :disabled="!canDeleteCC">

          <div class="mt-4">Contact name</div>
          <input type="text" class="inputfield" v-model.trim="formattedContactName" :disabled="!canDeleteCC">

        </div>
        <div class="col-sm-3">
          <div class="mt-4 mt-sm-0">Street address</div>
          <input type="text" class="inputfield" v-model.trim="formattedAddress" :disabled="!canDeleteCC">

          <div class="mt-4">Zip code</div>
          <input type="text" class="inputfield" v-model.trim="formattedZipcode" :disabled="!canDeleteCC">

          <div class="mt-4">City</div>
          <input type="text" class="inputfield" v-model.trim="formattedCity" :disabled="!canDeleteCC" >

          <div class="mt-4">Country</div>
          <v-select
              class="sam-select"
              label="name"
              :options="countries"
              :reduce="c => c.value"
              v-model="cc.country"
              placeholder="Select country"
              :disabled="!canDeleteCC"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <hr class="mt-4" />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-sm-3">
          <button class="btn btn-lg btn-primary btn-block mt-3" v-on:click="saveCC(create=false)" v-if="editCC">Save changes</button>
          <button class="btn btn-lg btn-primary btn-block mt-3" v-on:click="saveCC(create=true)" v-else>Create new cost center</button>
        </div>
        <div class="col-sm-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'cost-centers' }}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-3">Back to cost centers</button>
          </router-link>
        </div>
        <div class="offset-sm-3 col-sm-3">
          <button
              v-if="editCC && canDeleteCC"
              class="btn btn-lg btn-danger btn-block mt-3"
              @click="openArchiveCCModal()"
          >Delete cost center
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditCC',
  props: [
    "editCC",
    "CCData"
  ],
  data() {
    return {
      cc: {},
      newCC: {
        name: null,
        cc_id: null,
        company_name: null,
        company_id: null,
        company_vat: null,
        contact_name: null,
        address: null,
        city: null,
        zip_code: null,
        country: null,
        store: null
      },
      updating: false,
      showArchiveCCModal: false
    }
  },
  validations: {
    cc: {
      name: {
        required
      },
      cc_id: {
        required
      }
    }
  },
  mounted(){
    if(this.editCC && this.CCData && this.CCData.id){
      // make a copy of CCData
      this.cc = {...this.CCData}
    } else {
      this.cc = {...this.newCC}
      this.cc.store = this.$store.getters.activeStore.id;
    }
  },
  watch: {
    CCData: function(newVal){
      // Watch if addressData is updated later after page refresh
      if(this.editCC && !this.cc.id){
        this.cc = {...newVal};
      }
    }
  },
  computed: {
    formattedCompanyName: {
      get() {
        return this.cc.company_name || "";  // convert null to empty string for the input field
      },
      set(value) {
        this.cc.company_name = value === "" ? null : value;  // convert empty string back to null
      },
    },
    formattedCompanyID: {
      get() {
        return this.cc.company_id || "";  // convert null to empty string for the input field
      },
      set(value) {
        this.cc.company_id = value === "" ? null : value;  // convert empty string back to null
      },
    },
    formattedCompanyVat: {
      get() {
        return this.cc.company_vat || "";  // convert null to empty string for the input field
      },
      set(value) {
        this.cc.company_vat = value === "" ? null : value;  // convert empty string back to null
      },
    },
    formattedContactName: {
      get() {
        return this.cc.contact_name || "";  // convert null to empty string for the input field
      },
      set(value) {
        this.cc.contact_name = value === "" ? null : value;  // convert empty string back to null
      },
    },
    formattedAddress: {
      get() {
        return this.cc.address || "";  // convert null to empty string for the input field
      },
      set(value) {
        this.cc.address = value === "" ? null : value;  // convert empty string back to null
      },
    },
    formattedZipcode: {
      get() {
        return this.cc.zip_code || "";  // convert null to empty string for the input field
      },
      set(value) {
        this.cc.zip_code = value === "" ? null : value;  // convert empty string back to null
      },
    },
    formattedCity: {
      get() {
        return this.cc.city || "";  // convert null to empty string for the input field
      },
      set(value) {
        this.cc.city = value === "" ? null : value;  // convert empty string back to null
      },
    },
    countries() {
      if (!this.$store.getters['admin/countriesFetched']) {
        this.$store.dispatch('admin/fetchCountries')
      }
      const countries = this.$store.getters['admin/countries'].map(c => ({name: c[1], value: c[0]}))
      // remove duplicates
      return countries.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
    },
    canDeleteCC() {
      return this.editCC && !this.cc.default_cc || !this.editCC;
    }
  },
  methods: {
    openArchiveCCModal() {
      this.showArchiveCCModal = true
    },
    closeArchiveCCModal() {
      this.showArchiveCCModal = false
    },
    archiveCC() {
      const vm = this;
      const cc_id = this.cc.id
      if(!this.cc.is_active){
        this.updating = false;
        vm.$toastr.e("The cost center cannot be deleted.");
        return;
      }
      this.updating = true;
      this.closeArchiveCCModal()
      this.cc.is_active = false;
      let data = {...this.cc};
      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/costcenters/' + cc_id + '/';
      let method = 'PUT';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function () {
          document.body.style.cursor='default';
          vm.$store.dispatch('removeCC', {cc_id:cc_id})
          if(vm.$store.getters["admin/membersFetched"]) {
            vm.$store.dispatch('admin/fetchMembers')
          }
          if(vm.$store.state.admin.campaignsFetched) {
          vm.$store.dispatch('admin/clearCampaigns')
          vm.$store.dispatch('admin/fetchCampaigns')
          }
          vm.$toastr.s("Cost center removed.");
          setTimeout(function() {
          vm.$router.push({ name: 'storemanagement', params: { tab: 'cost-centers' }});
        },2500);
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    saveCC(create){
      this.updating = true;
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.updating = false;
        return;
      }
      const vm = this;
      let data = {...this.cc};

      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/costcenters/' + this.cc.id + '/';
      let method = 'PUT';
      if(create) {
        api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/costcenters/';
        method = 'POST';
        data.store = this.$store.getters.activeStore.id;
      }
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        if(create){
          // add new cc to VUEX
          vm.$store.dispatch('addCC', response.data)
          vm.$toastr.s("New cost center created.");
          document.body.style.cursor='default';
          setTimeout(function() {
            vm.updating = false;
            vm.$router.push({ name: 'storemanagement', params: { tab: 'cost-centers' }});
          },2500);
        } else {
          // update address to VUEX
          vm.$store.dispatch('updateCC', response.data)
          vm.$toastr.s("Your changes have been saved.");
          vm.updating = false;
          document.body.style.cursor='default';
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
  }
}

</script>
