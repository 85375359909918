<script>
import CreateEditSupplier from '@/modules/storemanagement/components/CreateEditSupplier.vue'
import SupplierList from '@/modules/storemanagement/components/SupplierList.vue'

export default {
  name: "SupplierManagement",
  components: {
    SupplierList,
    CreateEditSupplier
  },
  data() {
    return {
      supplierTypes: [{"label": "Producer", "value": 1}, {"label": "Warehouse", "value": 2}],
    }
  },
  computed: {
    createSupplier() {
      return this.$route.params.tab === "suppliers" && this.$route.params.action === "new"
    },
    editSupplier() {
      return this.$route.params.tab === "suppliers" && this.$route.params.action === "edit"
    },
    suppliers() {
      if(!this.$store.getters['admin/suppliersFetched']) {
        this.$store.dispatch('admin/fetchSuppliers')
      }
      return this.$store.getters['admin/suppliers']
    },
    supplierData() {
      const supplierId = this.$route.params.itemId
      return this.suppliers.find(s => s.id === supplierId)
    },
  },
}
</script>

<template>
  <div>
    <CreateEditSupplier
      v-if="createSupplier || editSupplier"
      :editSupplier="editSupplier"
      :supplierData="supplierData"
      :suppliers="suppliers"
      :supplierTypes="supplierTypes"
    />
    <SupplierList
      v-else
      :suppliers="suppliers"
      :supplierTypes="supplierTypes"
    />
  </div>
</template>
