<template>
  <div v-can="'management_products'">
    <CreateEditProduct v-if="showCreateEditProduct" v-bind:editProduct=editProduct v-bind:productData=productData />
    <ProductListing v-else />
  </div>
</template>

<script>

import ProductListing from '../components/ProductListing.vue'
import CreateEditProduct from '../components/CreateEditProduct.vue'

export default {
  name: 'ProductManagement',
  components: {
    ProductListing,
    CreateEditProduct
  },
  data() {
    return {
    }
  },
  mounted: function () {
    // Fetch productoptions if not fetched
    if(!this.$store.getters['admin/productOptsFetched']){
      this.$store.dispatch('admin/fetchProductOptions')
    }
    // Fetch staff productoptions if applicable
    if(!this.$store.getters['admin/staffProductOptsFetched']){
      this.$store.dispatch('admin/fetchStaffProductOptions')
    }
    // Fetch groups if not fetched
    if(!this.$store.getters.groupsFetched){
      this.$store.dispatch('fetchGroups');
    }
  },
  computed: {
    products(){
      return this.$store.state.admin.products;
    },
    showCreateEditProduct(){
      return this.$route.params.tab == "products" && (this.$route.params.action == "new" || this.$route.params.action == "edit");
    },
    productData(){
      if(this.$route.params.action == "edit"){
        const productId = this.$route.params.itemId;
        const product = this.products.find(p => p.id == productId);
        return product;
      }
      return {};
    },
    editProduct(){
      return this.$route.params.action == "edit" ? true : false;
    },
  },
  methods: {
  }
}

</script>