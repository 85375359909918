<template>
  <div>
    <div v-if="updating || fetching">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="editstoreCarrier">
        <SamTableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <div class="d-flex align-items-center">
              <img :src="`${env.VUE_APP_IMAGE_HOST}${this.carrierData.logo}`" class="mr-2" width="30"
                   height="30"/>
              <SamParagraph :text="this.carrierData.name" tableItem/>
            </div>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView" class="offset-md-3">
            <SamParagraph text="Connection status" bolded/>
            <!-- <SamTag
                     v-bind:text="getStatus(storeCarrierStatus())"
             /> -->
            <component :is="renderSAMTag(storeCarrierStatus()).component"
                       v-bind="renderSAMTag(storeCarrierStatus()).props"/>
          </SamTableColumn>
        </SamTableRow>
        <div class="row">
          <div class="col-sm-12">
            <SamAccordion
                title="Connect carrier"
                accordionId="samAccordionCarrierData"
                :defaultOpen="true"
            >
              <div class="row">
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-12 col-md-4 mt-4" v-if="showApiKey">
                      <div class="font-weight-bold">API key* <img id="api-key-tooltip"
                                                                  src="@/assets/images/tooltip.svg"
                                                                  title="Tooltip"/>
                        <b-tooltip target="api-key-tooltip" triggers="hover"> Ask your carrier to create a API key for
                          you
                        </b-tooltip>
                      </div>
                      <input type="text" class="inputfield"
                             v-model="storeCarrier.carriersettings[0].api_key">
                      <div v-if="$v.storeCarrier.carriersettings[0].api_key.$error">
                        <div class="form-field-error"
                             v-if="!$v.storeCarrier.carriersettings[0].api_key.required">This
                          field is required.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mt-4" v-if="showApiSecret">
                      <div class="font-weight-bold">API secret* <img id="api-secret-tooltip"
                                                                     src="@/assets/images/tooltip.svg"
                                                                     title="Tooltip"/>
                        <b-tooltip target="api-secret-tooltip" triggers="hover">Carrier can help you to create unique
                          API
                          Secret
                        </b-tooltip>
                      </div>
                      <input type="text" class="inputfield"
                             v-model="storeCarrier.carriersettings[0].api_secret">
                      <div v-if="$v.storeCarrier.carriersettings[0].api_secret.$error">
                        <div class="form-field-error"
                             v-if="!$v.storeCarrier.carriersettings[0].api_secret.required">This
                          field is required.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mt-4" v-if="showAccountNumber">
                      <div class="font-weight-bold">Account number* <img id="account-number-tooltip"
                                                                         src="@/assets/images/tooltip.svg"
                                                                         title="Tooltip"/>
                        <b-tooltip target="account-number-tooltip" triggers="hover">Your account number with this
                          carrier
                        </b-tooltip>
                      </div>
                      <input type="text" class="inputfield"
                             v-model="storeCarrier.carriersettings[0].account_number">
                      <div v-if="$v.storeCarrier.carriersettings[0].account_number.$error">
                        <div class="form-field-error"
                             v-if="!$v.storeCarrier.carriersettings[0].account_number.required">
                          This
                          field is required.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mt-4" v-if="showToken">
                      <div class="font-weight-bold">Token* <img id="token-tooltip"
                                                                src="@/assets/images/tooltip.svg"
                                                                title="Tooltip"/>
                        <b-tooltip target="token-tooltip" triggers="hover">You can get your token from your carrier
                        </b-tooltip>
                      </div>
                      <input type="text" class="inputfield"
                             v-model="storeCarrier.carriersettings[0].token">
                      <div v-if="$v.storeCarrier.carriersettings[0].token.$error">
                        <div class="form-field-error"
                             v-if="!$v.storeCarrier.carriersettings[0].token.required">This
                          field is required.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mt-4" v-if="showUserName">
                      <div class="font-weight-bold">Username/ UserID* <img id="username-tooltip"
                                                                           src="@/assets/images/tooltip.svg"
                                                                           title="Tooltip"/>
                        <b-tooltip target="username-tooltip" triggers="hover">Get username/ userID from your carrier
                        </b-tooltip>
                      </div>
                      <input type="text" class="inputfield"
                             v-model="storeCarrier.carriersettings[0].username">
                      <div v-if="$v.storeCarrier.carriersettings[0].username.$error">
                        <div class="form-field-error"
                             v-if="!$v.storeCarrier.carriersettings[0].username.required">This
                          field is required.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mt-4" v-if="showPassword">
                      <div class="font-weight-bold">Password* <img id="password-tooltip"
                                                                   src="@/assets/images/tooltip.svg"
                                                                   title="Tooltip"/>
                        <b-tooltip target="password-tooltip" triggers="hover">Get password from your carrier
                        </b-tooltip>
                      </div>
                      <input type="text" class="inputfield"
                             v-model="storeCarrier.carriersettings[0].password">
                      <div v-if="$v.storeCarrier.carriersettings[0].password.$error">
                        <div class="form-field-error"
                             v-if="!$v.storeCarrier.carriersettings[0].password.required">This
                          field is required.
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 mt-4">
                      <div class="font-weight-bold">Shipping label type <img id="shipping-label-tooltip"
                                                                             src="@/assets/images/tooltip.svg"
                                                                             title="Tooltip"/>
                        <b-tooltip target="shipping-label-tooltip" triggers="hover">Choose what shipping label type your
                          suppliers will use with deliveries. If you're unsure, select PDF
                        </b-tooltip>
                      </div>
                      <SamSelect
                          :model="storeCarrier.carriersettings[0].label_type"
                          label="name"
                          :options=labelOptions
                          :clearable="false"
                          @select="selectLabelType"
                          placeholder="Select single choice"
                      />
                    </div>
                    <div class="col-12 col-md-4 mt-4" v-if="showReturnAccountNumber">
                      <div class="font-weight-bold">Return account number <img id="return-account-number-tooltip"
                                                                               src="@/assets/images/tooltip.svg"
                                                                               title="Tooltip"/>
                        <b-tooltip target="return-account-number-tooltip" triggers="hover">Some carriers, like DHL, have
                          another account for returns or import
                        </b-tooltip>
                      </div>
                      <input type="text" class="inputfield"
                             v-model="storeCarrier.carriersettings[0].return_account_number">
                    </div>
                    <div class="col-12 col-md-4 mt-4">
                      <div class="font-weight-bold">Carrier contract country* <img
                          id="carrier-regions-tooltip"
                          src="@/assets/images/tooltip.svg"
                          title="Tooltip"/>
                        <b-tooltip target="carrier-regions-tooltip" triggers="hover">Country where your contract with
                          this
                          carrier is agreed upon
                        </b-tooltip>
                      </div>
                      <v-select
                          class="sam-select"
                          label="name"
                          :options=countries
                          :reduce="c => c.value"
                          v-model="storeCarrier.carriersettings[0].account_country"
                          placeholder="Select country"
                      ></v-select>
                      <div v-if="$v.storeCarrier.carriersettings[0].account_country.$error">
                        <div class="form-field-error"
                             v-if="!$v.storeCarrier.carriersettings[0].account_country.required">Contract country is
                          required
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-3 mt-4">
                  <div class="font-weight-bold">Carrier services* <img id="carrier-services-tooltip"
                                                                       src="@/assets/images/tooltip.svg"
                                                                       title="Tooltip"/>
                    <b-tooltip target="carrier-services-tooltip" triggers="hover">Select the carrier services your users
                      can use
                    </b-tooltip>
                  </div>
                  <SamSelect
                      label="value"
                      :options=carrierServicesOptions
                      :items="carrierServicesOptions.map(option => option.value)"
                      :reduce="p => p.value"
                      :model="selectedServiceOptions"
                      placeholder="Select multiple choices"
                      :multiple=true
                      @select="selectServiceType"
                  />
                  <div v-if="$v.storeCarrier.carrierservices_set.$error">
                    <div class="form-field-error"
                         v-if="!$v.storeCarrier.carrierservices_set.required">Carrier services are
                      required
                    </div>
                  </div>
                </div>

              </div>

            </SamAccordion>
          </div>
        </div>

        <!-- <div class="row">
           <div class="col-sm-12">
             <SamAccordion
                 title="Set region and shipping rules"
                 accordionId="samAccordionCarrierRules"
                 :defaultOpen="true"
             >
               <div class="row">
                 <div class="col-12 col-md-3">
                   <div class="font-weight-bold">Regions and countries <img
                       id="carrier-regions-tooltip"
                       src="@/assets/images/tooltip.svg"
                       title="Tooltip"/>
                     <b-tooltip target="carrier-regions-tooltip" triggers="hover">Select the regions
                       to make a rule.
                     </b-tooltip>
                   </div>
                   <SamSelect
                       label="name"
                       :options=countries
                       :reduce="p => p.id"
                       :model="selectedCountry"
                       placeholder="Select multiple choices"
                       :multiple=true
                       @select="selectCountry"
                       :disabled="storeCarrierStatus() < 2"
                   />
                 </div>
                 <div class="col-12 col-md-3">
                   <div class="font-weight-bold">Shipping fee type <img
                       id="shipping-fee-tooltip"
                       src="@/assets/images/tooltip.svg"
                       title="Tooltip"/>
                     <b-tooltip target="shipping-fee-tooltip" triggers="hover">Select shipping fee
                       type.
                     </b-tooltip>
                   </div>
                   <SamSelect
                       label="name"
                       :options=feeTypes
                       :reduce="p => p.id"
                       :model="selectedFeeType"
                       placeholder="Select single choice"
                       :multiple=false
                       @select="selectFeeType"
                       :disabled="storeCarrierStatus() < 2"
                   />
                 </div>
                 <div class="col-md-3"></div>

                 <div class="col-12 col-md-3 d-flex align-items-center">
                   <button class="btn btn-lg btn-primary btn-block mt-0" v-on:click="addNewRule()"
                           :disabled="storeCarrierStatus() < 2">
                     {{ "Add new rule" }}
                   </button>
                 </div>
               </div>
               <div class="row">
                 <ul>
                   <li v-for="(country, fee_type) in groupedRulesData" :key="fee_type">
                     Fee Type {{ fee_type }}: {{ country.join(', ') }}
                     <span class="remove-x" @click="removeRule(fee_type)">X</span>
                   </li>
                 </ul>
               </div>

             </SamAccordion>
           </div>
         </div> -->

        <div class="row mb-5">
          <div class="col-12 col-md-3">
            <button class="btn btn-lg btn-primary btn-block mt-0" v-on:click="saveCarrier()">
              {{ "Save changes" }}
            </button>
          </div>
          <div class="col-12 col-md-3">
            <router-link :to="{ name: 'storemanagement', params:{tab: 'carriers'}}">
              <button class="btn btn-lg btn-outline-primary btn-block mt-3 mt-md-0">Back to all carriers
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SamTableRow from "../../../components/table/SamTableRow.vue";
import SamTableColumn from "../../../components/table/SamTableColumn.vue";
import SamParagraph from "../../../components/SamParagraph.vue";
import SamTag from "../../../components/SamTag.vue";
import SamAccordion from '../../../components/SamAccordion.vue'
import SamSelect from "../../../components/SamSelect.vue";
import {requiredIf} from 'vuelidate/lib/validators'
import mixin from '../mixins/mixin'

export default {
  name: 'CreateEditCarrier',
  mixins: [mixin],
  components: {SamTableRow, SamTableColumn, SamTag, SamParagraph, SamAccordion, SamSelect},
  props: [
    "carrierData",
    "storeCarrierData"
  ],
  data() {
    return {
      storeCarrier: {},
      newStoreCarrier: {
        carrier: null,
        store: null,
        status: 1,
        carriersettings: [{
          "api_key": null,
          "api_secret": null,
          "account_number": null,
          "account_country": null,
          "return_account_number": null,
          "token": null,
          "username": null,
          "password": null,
          "label_type": null
        }],
        carrierservices_set: [],
        carrierrules_set: []
      },

      carrierServicesOptions: [],
      selectedServiceOption: null,
      selectedServiceOptions: [],
      labelOptions: [
        {id: 0, name: "PDF"}, {id: 1, name: "ZPL"}
      ],
      selectedLabelOptionIndex: null,
      selectedReturnType: null,
      showApiKey: false,
      showApiSecret: false,
      showAccountNumber: false,
      showReturnAccountNumber: false,
      showToken: false,
      showUserName: false,
      showPassword: false,
      selectedValue: 1,
      address: {country: {}},
      selectedContractCountry: 0,
      selectedCountries: [],
      selectedFeeType: null,
      shippingRules: [],
      updating: false,
      updatestoreCarrier: false,
      env: process.env,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  validations: {
    storeCarrier: {
      carrierservices_set: {
        required: requiredIf(function () {
          return true;
        })
      },
      carriersettings: {
        0: {
          api_key: {
            required: requiredIf(function () {
              return this.showApiKey;
            })
          },
          api_secret: {
            required: requiredIf(function () {
              return this.showApiSecret;
            })
          },
          account_number: {
            required: requiredIf(function () {
              return this.showAccountNumber;
            })
          },
          account_country: {
            required: requiredIf(function () {
              return true;
            })
          },
          token: {
            required: requiredIf(function () {
              return this.showToken;
            })
          },
          username: {
            required: requiredIf(function () {
              return this.showUserName;
            })
          },
          password: {
            required: requiredIf(function () {
              return this.showPassword;
            })
          },
        },
      },
    }
  },
  mounted() {
    //debugger
    this.carrierSettings = this.carrierData.carriersettings_set[0];
    const services = this.carrierData.carrier_services
    this.carrierServicesOptions = services.map((value, index) => {
      return {id: index, value: value};
    });
    this.showApiKey = !!this.carrierSettings.api_key;
    this.showApiSecret = !!this.carrierSettings.api_secret;
    this.showAccountNumber = !!this.carrierSettings.account_number;
    this.showReturnAccountNumber = !!this.carrierSettings.return_account_number;
    this.showToken = !!this.carrierSettings.token;
    this.showUserName = !!this.carrierSettings.username;
    this.showPassword = !!this.carrierSettings.password;


    if (this.editstoreCarrier && this.storeCarrierData && this.storeCarrierData.id) {
      this.storeCarrier = {...this.storeCarrierData}
      this.selectedServiceOptions = this.storeCarrier.carrierservices_set.map(obj => parseInt(obj.service));
      this.updatestoreCarrier = true;
    } else {
      this.storeCarrier = {...this.newStoreCarrier};
      this.storeCarrier.store = this.$store.getters.activeStore.id;
      this.storeCarrier.carrier = this.carrierData.id;
    }
  },
  watch: {
    carrierSettings: {
      handler(newVal) {
        if (newVal && newVal.api_key) {
          this.showApiKey = true;
        }
        if (newVal && newVal.api_secret) {
          this.showApiSecret = true;
        }
        if (newVal && newVal.account_number) {
          this.showAccountNumber = true;
        }
        if (newVal && newVal.return_account_number) {
          this.showReturnAccountNumber = true;
        }
        if (newVal && newVal.token) {
          this.showToken = true;
        }
        if (newVal && newVal.username) {
          this.showUserName = true;
        }
        if (newVal && newVal.password) {
          this.showPassword = true;
        }
      },
      deep: true, // enable deep watching of the object
    },
    onBoardingStatus: false,

  },
  computed: {
    fetching() {
      return (this.$store.getters['admin/fetchingCarriers'])
    },
    editstoreCarrier() {
      return this.$route.params && this.$route.params.action === "edit";
    },
    countries() {
      if (!this.$store.getters['admin/countriesFetched']) {
        this.$store.dispatch('admin/fetchCountries')
      }
      const countries = this.$store.getters['admin/countries'].map((c) => ({id: c[0], name: c[1], value: c[0]}))
      return countries.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
    },
    feeTypes() {
      return [{id: 0, name: "Live rates"}]
    },
    groupedRulesData() {
      const result = {};
      for (const item of this.storeCarrier.carrierrules_set) {
        const {fee_type, country} = item;
        if (result[fee_type]) {
          result[fee_type].push(country);
        } else {
          result[fee_type] = [country];
        }
      }
      return result;
    }
  },
  methods: {
    selectLabelType(id) {
      this.storeCarrier.carriersettings[0].label_type = id;
    },
    selectReturnType(id) {
      this.selectedReturnType = id;
    },
    selectServiceType(selectedValues) {
      this.storeCarrier.carrierservices_set = selectedValues.map(key => ({service: key}));
    },
    selectCountry(selectedValues) {
      this.selectedCountries = [...selectedValues]
    },
    selectFeeType(selectedValue) {
      this.selectedFeeType = selectedValue
    },
    addNewRule() {
      let newRule = {fee_type: this.selectedFeeType, country: this.selectedCountries}
      const ruleArray = newRule.country.map(country => ({
        fee_type: newRule.fee_type,
        country: country
      }));
      this.storeCarrier.carrierrules_set = [...ruleArray]

    },
    removeRule(feeType) {
      let newArray = this.storeCarrier.carrierrules_set.filter(item => {
        item.fee_type !== feeType
      });
      this.storeCarrier.carrierrules_set = newArray;
    },
    storeCarrierStatus() {
      if (this.storeCarrierData.status) {
        return this.storeCarrierData.status;
      } else {
        return 0
      }
    },
    getStatus(value) {
      return this.$store.getters['admin/storeCarrierStatuses'].find(c => c[0] == value)[1]
    },
    saveCarrier() {
      this.updating = true;
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.updating = false;
        return;
      }
      const vm = this;
      let data = {...this.storeCarrier};

      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/carriers/' + this.storeCarrier.id + '/';
      let method = 'PUT';
      if (!this.updatestoreCarrier) {
        api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/carriers/';
        method = 'POST';
      }
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
          .then(() => {
            const data = {
              pushRoute: true,
              routeLocation: {name: 'storemanagement', params: {tab: 'carriers'}}
            }
            if (method === "POST") {
              vm.$toastr.s("New carrier was initialized.")
            } else {
              vm.$toastr.s("The carrier was updated.")
            }
            this.$store.dispatch('clearAndFetchStoreCarriers', data);
          })
          .catch(error => {
            vm.updating = false;
            if (error.response && error.response.data) {
              vm.$toastr.e(JSON.stringify(error.response.data))
            }
          });
    }
  },
  created() {
    //debugger
    //this.getStoreCarriers()
  },
}
</script>
<style scoped>
.remove-x {
  cursor: pointer;
  margin-left: 10px;
}
</style>