<template>
  <div v-can="'management_settings'">
    <SamModal
        :showExportModal="showClientSecretModal"
        title="Client secret"
        id="clientSecretModal"
        @close="closeClientSecretModal()"
        @save="copyPublicApiClientSecretToClipboard()"
        saveButtonText="Copy and Close"
    >
      <div class="row mb-4">
        <div class="col-12">
          {{publicApiClientSecret}}
        </div>
      </div>
    </SamModal>
    <SamModal
        :showExportModal="showWebhookModal"
        title="Webhook"
        id="webhookModal"
        @close="closeWebhookModal()"
        @save="newWebhook ? createWebhook() : updateWebhook()"
        saveButtonText="Save"
    >
      <div class="row mb-4">
        <div v-if="newWebhook" class="col-12">
          <div class="row">
            <div class="col-12 col-md-4 mt-4 mt-md-0">
              <div class="font-weight-bold">Event*</div>
              <v-select
                  label="description"
                  v-model="webhook.event"
                  :options="webhookEvents"
                  :reduce="s => s.event"
                  :clearable="false"
                  placeholder="Select webhook event"
              ></v-select>
              <div v-if="$v.webhook.event.$error">
                <div v-if="!$v.webhook.event.required" class="form-field-error">This field is required</div>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-4 mt-md-0">
              <div class="font-weight-bold">URI*</div>
              <input type="text" class="inputfield" v-model="webhook.hook_uri">
              <div v-if="$v.webhook.hook_uri.$error">
                <div v-if="!$v.webhook.hook_uri.required" class="form-field-error">This field is required</div>
                <div v-if="!$v.webhook.hook_uri.url" class="form-field-error">This field must be a valid URL</div>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-4 mt-md-0">
              <div class="font-weight-bold">Token</div>
              <input type="text" class="inputfield" v-model="webhook.token">
            </div>
          </div>
        </div>
        <div v-else class="col-12">
          <div class="row">
            <div class="col-12 col-md-4 mt-4 mt-md-0">
              <div class="font-weight-bold">Event*</div>
              <v-select
                  label="description"
                  v-model="webhook.event"
                  :options="webhookEvents"
                  :reduce="s => s.event"
                  :clearable="false"
                  placeholder="Select webhook event"
              ></v-select>
              <div v-if="$v.webhook.event.$error">
                <div v-if="!$v.webhook.event.required" class="form-field-error">This field is required</div>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-4 mt-md-0">
              <div class="font-weight-bold">URI*</div>
              <input type="text" class="inputfield" v-model="webhook.hook_uri">
              <div v-if="$v.webhook.hook_uri.$error">
                <div v-if="!$v.webhook.hook_uri.required" class="form-field-error">This field is required</div>
                <div v-if="!$v.webhook.hook_uri.url" class="form-field-error">This field must be a valid URL</div>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-4 mt-md-0">
              <div class="font-weight-bold">Token*</div>
              <input type="text" class="inputfield" v-model="webhook.token">
              <div v-if="$v.webhook.token.$error">
                <div v-if="!$v.webhook.token.required" class="form-field-error">This field required</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 mt-4">
              <button class="btn btn-lg btn-dark btn-block mt-0" @click="testWebhook()">
                <span>Test</span>
              </button>
            </div>
            <div class="col-12 col-md-4 mt-4">
              <button class="btn btn-lg btn-danger btn-block mt-0" @click="deleteWebhook()">
                <span>Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </SamModal>
    <div v-if="updating || fetching">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-12">
          <div><h4>Company and invoicing details</h4></div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3">
          <div>Official company name</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.company_name"></div>
          <div class="mt-4">Company ID</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.company_id"></div>
          <div class="mt-4">Company VAT number</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.vat_number"></div>
        </div>
        <div class="col-md-3">
          <div>Street address</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.street_address"></div>
          <div class="mt-4">City</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.city"></div>
          <div class="mt-4">Postal code</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.zip_code"></div>
          <div class="mt-4">Country</div>
          <v-select
              label="name"
              v-model="invoicing_details.country.code"
              :options="countries"
              :reduce="s => s.value"
              placeholder="Select country"
          ></v-select>
        </div>
        <div class="col-md-3">
          <div>Payment terms</div>
          <v-select
              label="name"
              v-model="invoicing_details.payment_terms"
              :options="paymentTermsArray"
              :reduce="s => s.value"
              placeholder="Select payment terms"
          ></v-select>
          <div class="mt-4">Delivery terms</div>
          <v-select
              label="name"
              v-model="invoicing_details.delivery_terms"
              :options="deliveryTermsArray"
              :reduce="dt => dt.value"
              placeholder="Select delivery terms"
          ></v-select>
          <div class="mt-4">Invoice contact phone</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.contact_phone"></div>
          <div class="mt-4">Invoice contact email</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.contact_email"></div>
          <div v-if="$v.invoicing_details.contact_email.$error">
            <div class="form-field-error" v-if="!$v.invoicing_details.contact_email.email">Please enter valid email</div>
            <div class="form-field-error" v-else-if="!$v.invoicing_details.contact_email.validChars">å, ä, ö are not allowed characters</div>
          </div>
        </div>
        <div class="col-md-3">
          <div>Bank name</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.bank_name"></div>
          <div class="mt-4">Bank IBAN</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.bank_iban"></div>
          <div class="mt-4">Bank SWIFT</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.bank_swift"></div>
          <div class="mt-4">Invoice VAT % (e.g. 24)</div>
          <div><input type="text" class="inputfield" v-model.trim="invoicing_details.invoice_vat"></div>
          <div v-if="$v.invoicing_details.invoice_vat.$error">
            <div class="form-field-error" v-if="!$v.invoicing_details.invoice_vat.decimal">Please enter valid VAT value</div>
          </div>
        </div>
      </div>
      <div v-if="this.$store.getters.storeFeatures.payments_enabled">
        <div class="row">
          <div class="col-md-12">
            <hr class="mt-4" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div><h4>Collect payments from users or Magic Link recipients</h4></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div>By connecting your Store with financial service Stripe, you can start collecting payments from users and Magic Link Campaign recipients.</div>
            <div class="mt-3">They can safely pay with their credit cards when placing an order of your merchandise or marketing materials. Your country’s VAT is added to each user and recipient order (all product prices in Store are 0% VAT).</div>
          </div>
          <div class="col-md-6">
            <div>Stripe transfers money to your bank account every X weeks.</div>
            <div class="mt-3">By connecting to Stripe, you agree to their terms and conditions and transaction fees.</div>
            <div v-if="$store.getters.stripeEnabled" class="mt-3 font-green"><b>&#9989; Your Store is now connected to Stripe.</b></div>
            <div v-if="$store.getters.stripeInitiated && !$store.getters.stripeEnabled" class="mt-3 text-danger"><b>Your Stripe account is incomplete. Finalise account setup to collect payments.</b></div>
            <button class="btn btn-lg btn-outline-primary btn-block mt-3 w-50" @click="initConnecting">
              <span v-if="!$store.getters.stripeInitiated">Connect with Stripe</span>
              <span v-if="$store.getters.stripeInitiated && !$store.getters.stripeEnabled">Finalise Stripe account</span>
              <span v-if="$store.getters.stripeInitiated && $store.getters.stripeEnabled">Edit Stripe account</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-4" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <div><h4>Customise order confirmation email</h4></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div>Your user support contact name</div>
          <input type="text" class="inputfield" v-model.trim="store.support_contact_name">
        </div>
        <div class="col-md-3">
          <div>Your user support email</div>
          <input type="text" class="inputfield" v-model.trim="store.support_email">
          <div v-if="$v.store.support_email.$error">
            <div class="form-field-error" v-if="!$v.store.support_email.email">Please enter valid email</div>
            <div class="form-field-error" v-else-if="!$v.store.support_email.validChars">å, ä, ö are not allowed characters</div>
          </div>
        </div>
        <div class="col-md-3">
          <div>Your user support phone</div>
          <input type="text" class="inputfield" v-model.trim="store.support_phone">
        </div>
        <div class="col-md-3">
          <div>CC order confirmation to email</div>
          <input type="text" class="inputfield" v-model.trim="store.order_conf_cc_email">
          <div v-if="$v.store.order_conf_cc_email.$error">
            <div class="form-field-error" v-if="!$v.store.order_conf_cc_email.email">Please enter valid email</div>
            <div class="form-field-error" v-else-if="!$v.store.order_conf_cc_email.validChars">å, ä, ö are not allowed characters</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-4" />
        </div>
      </div>
      <div v-if="$store.state.activeStore.features && $store.state.activeStore.features.leased_products">
        <div class="row mt-4">
          <div class="col-12">
            <div><h4>Lease product notifications</h4></div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <div>Enable notifications</div>
            <v-select
                label="name"
                v-model="store.lease_notifications"
                :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                :reduce="s => s.value"
                :clearable="false"
                placeholder="Select lease notifications"
            ></v-select>
          </div>
          <div class="col-md-3" v-if="store.lease_notifications">
            <div>Notification after shipping (days)</div>
            <div><input type="number" class="inputfield" v-model="store.days_before_notify"></div>
            <div v-if="$v.store.days_before_notify.$error">
              <div class="form-field-error" v-if="!$v.store.days_before_notify.required">Please enter value</div>
            </div>
          </div>
          <div class="col-md-3" v-if="store.lease_notifications">
            <div>Notification email</div>
            <div><input type="text" class="inputfield" v-model="store.lease_notification_email"></div>
            <div v-if="$v.store.lease_notification_email.$error">
              <div class="form-field-error" v-if="!$v.store.lease_notification_email.required">Please enter notification email</div>
              <div class="form-field-error" v-else-if="!$v.store.lease_notification_email.email">Please enter valid email</div>
              <div class="form-field-error" v-else-if="!$v.store.lease_notification_email.validChars">å, ä, ö are not allowed characters</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr class="mt-4" />
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <div><h4>Customise shipping costs message</h4></div>
          <div>Customise Shipping Costs -message in the 'Delivery details' page.</div>
          <textarea class="textarea" rows=6 v-model="store.shipping_message"></textarea>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col">
              <div><h4>Set store currency</h4></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div>Currency <img id="currency-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                <b-tooltip target="currency-tooltip" triggers="hover">
                  Attn!! If you change your store's currency, please convert and update product prices to the new currency manually.
                </b-tooltip>
              </div>
              <v-select
                  label="name"
                  v-model="store.currency"
                  :options="currencyOptions"
                  :reduce="s => s.value"
                  :clearable="false"
                  placeholder="Store currency"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-4" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <div><h4>API credentials</h4></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div>Grant Type</div>
          <div>{{publicApiCredentials.grant_type}}</div>
        </div>
        <div class="col-md-3">
          <div>Company ID</div>
          <div>{{publicApiCredentials.company_id}}</div>
        </div>
        <div class="col-md-3">
          <div>Client ID</div>
          <div>{{publicApiCredentials.client_id}}</div>
        </div>
        <div v-if="!publicApiCredentialsReceived" class="col-md-3">
          <button class="btn btn-lg btn-outline-primary btn-block mt-0" @click="retrievePublicApiCredentials()">
            <span>Show credentials</span>
          </button>
        </div>
        <div v-else class="col-md-3">
          <button class="btn btn-lg btn-outline-primary btn-block mt-0" @click="retrievePublicApiClientSecret()">
            <span>Generate secret</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-4" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6 col-md-9">
          <div><h4>Webhooks</h4></div>
        </div>
        <div class="col-6 col-md-3">
          <button class="btn btn-lg btn-outline-primary btn-block mt-0" @click="createWebhookModal()">
            <span>Add webhook</span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 d-none d-md-block ml-2">
          <div>Event</div>
        </div>
        <div class="col-md-3 d-none d-md-block">
          <div>Endpoint</div>
        </div>
        <div class="col-md-3 d-none d-md-block">
          <div>Token</div>
        </div>
      </div>
      <div
          v-for="(wh, idx) in webhooks"
          v-bind:key="idx"
          @click="editWebhookModal(wh)"
      >
        <SamTableRow hoverable noSpace clickableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Event" bolded/>
            <SamParagraph :text="wh.event" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="URI" bolded/>
            <SamParagraph :text="wh.hook_uri" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView">
            <SamParagraph v-if="windowWidth < mobileView" text="Token" bolded/>
            <SamParagraph :text="wh.token" tableItem/>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton text="Edit" noSpace/>
          </SamTableColumn>
          <img v-if="windowWidth >= mobileView" class="sam-table-icon" src='@/assets/images/edit.svg' alt="edit"  />
        </SamTableRow>
      </div>
      <div class="row mb-5">
        <div class="col-md-3">
          <button class="btn btn-lg btn-primary btn-block mt-3" v-on:click="save()">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {email, decimal, requiredIf, url} from 'vuelidate/lib/validators'
import SamModal from "@/components/SamModal.vue";
import SamParagraph from "@/components/SamParagraph.vue";
import SamSecondaryButton from "@/components/SamSecondaryButton.vue";
import SamTableColumn from "@/components/table/SamTableColumn.vue";
import SamTableRow from "@/components/table/SamTableRow.vue";

export default {
  name: 'StoreSettings',
  components: {SamTableRow, SamTableColumn, SamSecondaryButton, SamParagraph, SamModal},
  data() {
    return {
      cc_email: null,
      updating: false,
      shipping_message: null,
      invoicing_details: {country:{code:null,name:null}},
      inv_details_changed: false,
      store: {},
      stripeChecked: false,
      publicApiCredentials: {},
      publicApiClientSecret: null,
      showClientSecretModal: false,
      webhooks: [],
      webhookEvents: [],
      webhook: {
        id: null,
        event: null,
        hook_uri: null,
        token: null,
      },
      newWebhook: false,
      showWebhookModal: false,
      mobileView: 768,
      tabletView: 1024,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted() {
    this.store = {
      id: this.$store.getters.activeStore.id,
      currency: this.$store.getters.activeStore.currency,
      order_conf_cc_email: this.$store.getters.activeStore.order_conf_cc_email,
      shipping_message: this.$store.getters.activeStore.shipping_message,
      lease_notifications: this.$store.getters.activeStore.lease_notifications,
      days_before_notify: this.$store.getters.activeStore.days_before_notify,
      lease_notification_email: this.$store.getters.activeStore.lease_notification_email,
      support_contact_name: this.$store.getters.activeStore.support_contact_name,
      support_email: this.$store.getters.activeStore.support_email,
      support_phone: this.$store.getters.activeStore.support_phone
    }
    if(!this.$store.getters['admin/invoiceDetailsFetched']){
      this.$store.dispatch('admin/fetchInvoiceDetails')
    } else {
      if(this.$store.getters['admin/invoicingDetails'].country == ""){
        this.invoicing_details = {...this.$store.getters['admin/invoicingDetails'], ...{country: {code:null,name:null}}}
      } else {
        this.invoicing_details = {...this.$store.getters['admin/invoicingDetails']}
      }
    }
    // Fetch productoptions if not fetched
    if(!this.$store.getters['admin/productOptsFetched']){
      this.$store.dispatch('admin/fetchProductOptions')
    }
    // Fetch staff productoptions if not fetched
    if(!this.$store.getters['admin/staffProductOptsFetched']){
      this.$store.dispatch('admin/fetchStaffProductOptions')
    }
    // Check if Stripe status should be updated
    if(this.$store.getters.stripeInitiated && !this.$store.getters.stripeEnabled){
      // Stripe connection is initiated, but not completed for payment. Refetch current status from Stripe
      this.fetchStripeStatus()
    } else {
      this.stripeChecked = true;
    }
    // Fetch store webhooks
    this.retrieveWebhooks()
  },
  validations: {
    store: {
      order_conf_cc_email: {
        email,
        validChars(email){
          if(email == "" || email == null){
            return true;
          }
          // Check email doesn't contain å, ä, ö
          return !['å', 'ä', 'ö'].some(e => email.includes(e));
        },
      },
      days_before_notify: {
        required: requiredIf(function(store){
          return store.lease_notifications
        }),
      },
      lease_notification_email: {
        required: requiredIf(function(store){
          return store.lease_notifications
        }),
        email,
        validChars(email){
          if(email == "" || email == null){
            return true;
          }
          // Check email doesn't contain å, ä, ö
          return !['å', 'ä', 'ö'].some(e => email.includes(e));
        },
      },
      support_email: {
        email,
        validChars(email){
          if(email == "" || email == null){
            return true;
          }
          // Check email doesn't contain å, ä, ö
          return !['å', 'ä', 'ö'].some(e => email.includes(e));
        },
      },
    },
    invoicing_details: {
      invoice_vat: {
        decimal
      },
      contact_email: {
        email,
        validChars(email){
          if(email == "" || email == null){
            return true;
          }
          // Check email doesn't contain å, ä, ö
          return !['å', 'ä', 'ö'].some(e => email.includes(e));
        },
      }
    },
    webhook: {
      event: {
        required: requiredIf(function() {
          return this.showWebhookModal
        })
      },
      hook_uri: {
        required: requiredIf(function() {
          return this.showWebhookModal
        }),
        url
      },
      token: {
        required: requiredIf(function() {
          return this.showWebhookModal && !this.newWebhook
        })
      }
    }
  },
  watch:{
    invoicing_details: function(){
      this.inv_details_changed = true;
    },
    invDetails: function(){
      if(this.invDetails.country == ""){
        this.invoicing_details = {...this.invDetails, ...{country: {code:null,name:null}}}
      } else {
        this.invoicing_details = {...this.invDetails}
      }
    }
  },
  computed: {
    countries(){
      if(!this.$store.getters['admin/countriesFetched']){
        this.$store.dispatch('admin/fetchCountries')
      }
      const countries = this.$store.getters['admin/countries'].map(c => ({name: c[1], value: c[0]}))
      // remove duplicates
      return countries.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i);
    },
    options(){
      return this.$store.getters['admin/productOptions'];
    },
    invDetails(){
      return this.$store.getters['admin/invoicingDetails'];
    },
    fetching(){
      return !this.$store.getters['admin/invoiceDetailsFetched'] || !this.$store.getters['admin/productOptsFetched']
        || !this.stripeChecked
    },
    paymentTermsArray(){
      if(!this.options.invoice_payment_terms){
        return [];
      }
      return this.options.invoice_payment_terms.map(pt => ({name: pt[1], value: pt[0]}))
    },
    deliveryTermsArray(){
      if(!this.options.invoice_delivery_terms){
        return [];
      }
      return this.options.invoice_delivery_terms.map(d => ({name: d[1], value: d[0]}))
    },
    currencyOptions(){
      if(this.$store.getters['admin/productOptsFetched']){
        return this.$store.getters['admin/productOptions'].currencies.map(m => ({name: m[1], value: m[0]}));
      } else {
        return []
      }
    },
    publicApiCredentialsReceived() {
      return this.publicApiCredentials
          && this.publicApiCredentials.grant_type
          && this.publicApiCredentials.company_id
          && this.publicApiCredentials.client_id
    },
  },
  methods: {
    save(){
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.$toastr.e("Please check all fields");
        return;
      }
      this.updating = true;
      document.body.style.cursor='wait';
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + "/";
      let method = 'PATCH';
      if(!this.shipping_message || this.shipping_message.length == 0){
        this.shipping_message = null;
      }
      const data = {...this.store};
      data['store'] = this.store.id;
      if(!data.lease_notifications){
        // clear if notifications disabled
        data.days_before_notify = null;
        data.lease_notification_email = null;
      }

      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        vm.$store.dispatch('updateStore', response.data)
        if(vm.inv_details_changed){
          vm.saveInvoiceSettings().then(function(result) {
            // update invoice settings to VUEX
            vm.$store.dispatch('admin/updateInvoiceDetails', result.data)
            // If invoicing details were created, update id to store
            if(!vm.$store.getters.activeStore.invoicing_details){
              const storeData = {
                id: vm.$store.getters.activeStore.id,
                invoicing_details: result.data.id
              }
              vm.$store.dispatch('updateStore', storeData);
            }
            vm.$toastr.s("Settings saved");
            vm.invoicing_details = {...result.data};
            vm.updating = false;
            document.body.style.cursor='default';
          })
          .catch(function (error) {
            if (error.request){
              vm.$toastr.e(
                error.request.responseText
              );
            }
            vm.$toastr.e(
              error
            );
            document.body.style.cursor='default';
            vm.updating = false;
          });
        } else {
          vm.$toastr.s("Settings saved");
          document.body.style.cursor='default';
          vm.updating = false;
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    saveInvoiceSettings(){
      const vm = this;
      const data = this.invoicing_details;
      const storeId = this.$store.getters.activeStore.id;
      data.store = storeId;
      let api_url = process.env.VUE_APP_API_URL + '/api/stores/' + storeId + "/invoicing_details/";
      let method = "POST";
      if(data.id){
        api_url = api_url + data.id + "/"
        method = 'PUT'
      }
      return this.$http({
        method: method,
        url: api_url,
        data: data
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        }
        vm.$toastr.e(error);
        document.body.style.cursor='default';
      });
    },
    initConnecting(){
      const vm = this;
      // Post to api to get connection url
      let api_url = process.env.VUE_APP_API_URL + '/api/payments/onboard-user/';
      const data = {
        storeId: this.$store.getters.activeStore.id
      }
      let method = "POST";
      vm.updating = true;
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        window.open(response.data.redirect_url, '_self');
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        }
        vm.$toastr.e(error);
        vm.updating = false;
      });
    },
    fetchStripeStatus(){
      const vm = this;
      this.$store.dispatch('fetchStripeStatus')
      .then(function(){
        vm.stripeChecked = true;
      });
    },
    retrievePublicApiCredentials() {
      document.body.style.cursor='wait'
      const vm = this
      const apiURL = process.env.VUE_APP_API_URL + '/account/store/' + vm.store.id + '/apicredentials/'
      return this.$http({
        method: 'POST',
        url: apiURL
      })
          .then(function (response) {
            // console.log('CREDENTIALS_DATA', response.data)
            vm.publicApiCredentials = response.data
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(error.request.responseText)
            }
            vm.$toastr.e(error)
          })
          .finally(() => {
            document.body.style.cursor='default'
          })
    },
    retrievePublicApiClientSecret() {
      document.body.style.cursor='wait'
      const vm = this
      const apiURL = process.env.VUE_APP_API_URL + '/account/store/' + vm.store.id + '/apiclientsecret/'
      return this.$http({
        method: 'POST',
        url: apiURL
      })
      .then(function (response) {
        // console.log('CLIENT_SECRET_DATA', response.data)
        vm.publicApiClientSecret = response.data.client_secret
        if (vm.publicApiClientSecret) {
          vm.showClientSecretModal = true
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        }
        vm.$toastr.e(error)
      })
      .finally(() => {
        document.body.style.cursor='default'
      })
    },
    copyPublicApiClientSecretToClipboard() {
      if (this.publicApiClientSecret) {
        navigator.clipboard.writeText(this.publicApiClientSecret)
        .then(() => {
          this.$toastr.s('Client secret copied')
        })
        .catch(() => {
          this.$toastr.e('Could not copy client secret')
        })
        .finally(() => {
          this.showClientSecretModal = false
          this.publicApiClientSecret = null
        })
      }
    },
    closeClientSecretModal() {
      this.showClientSecretModal = false
    },
    retrieveWebhooks() {
      document.body.style.cursor='wait'
      const vm = this
      const apiURL = process.env.VUE_APP_API_URL + '/api/stores/' + vm.store.id + '/webhooks/'
      return this.$http({
        method: 'GET',
        url: apiURL
      })
      .then(function (response) {
        // console.log('WEBHOOKS_DATA', response.data)
        vm.webhooks = response.data
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        } else {
          vm.$toastr.e(error)
        }
      })
      .finally(() => {
        document.body.style.cursor='default'
      })
    },
    retrieveWebhookEvents() {
      document.body.style.cursor='wait'
      const vm = this
      const apiURL = process.env.VUE_APP_API_URL + '/api/stores/' + vm.store.id + '/webhooks/events/'
      return this.$http({
        method: 'GET',
        url: apiURL
      })
      .then(function (response) {
        // console.log('WEBHOOK_EVENTS_DATA', response.data)
        vm.webhookEvents = response.data
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        } else {
          vm.$toastr.e(error)
        }
      })
      .finally(() => {
        document.body.style.cursor='default'
      })
    },
    resetWebhookObject() {
      this.webhook.id = null
      this.webhook.event = null
      this.webhook.hook_uri = null
      this.webhook.token = null
    },
    createWebhookModal() {
      this.retrieveWebhookEvents()
      this.resetWebhookObject()
      this.newWebhook = true
      this.showWebhookModal = true
    },
    editWebhookModal(webhook) {
      this.retrieveWebhookEvents()
      this.webhook.id = webhook.id
      this.webhook.event = webhook.event
      this.webhook.hook_uri = webhook.hook_uri
      this.webhook.token = webhook.token
      this.showWebhookModal = true
    },
    closeWebhookModal() {
      this.resetWebhookObject()
      this.newWebhook = false
      this.showWebhookModal = false
    },
    createWebhook() {
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.$toastr.e('Please check all fields')
        return
      }
      document.body.style.cursor='wait'
      const vm = this
      const apiURL = process.env.VUE_APP_API_URL + '/api/stores/' + vm.store.id + '/webhooks/'
      return this.$http({
        method: 'POST',
        url: apiURL,
        data: this.webhook,
      })
      .then(function () {
        // console.log('CREATE_WEBHOOK_DATA_RESPONSE', response.data)
        vm.retrieveWebhooks()
        vm.closeWebhookModal()
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        } else {
          vm.$toastr.e(error)
        }
      })
      .finally(() => {
        document.body.style.cursor='default'
      })
    },
    updateWebhook() {
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.$toastr.e('Please check all fields')
        return
      }
      document.body.style.cursor='wait'
      const vm = this
      const apiURL = process.env.VUE_APP_API_URL + '/api/stores/' + vm.store.id + '/webhooks/' + vm.webhook.id + '/'
      return this.$http({
        method: 'PUT',
        url: apiURL,
        data: this.webhook,
      })
      .then(function () {
        // console.log('UPDATE_WEBHOOK_DATA_RESPONSE', response.data)
        vm.retrieveWebhooks()
        vm.closeWebhookModal()
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        } else {
          vm.$toastr.e(error)
        }
      })
      .finally(() => {
        document.body.style.cursor='default'
      })
    },
    testWebhook() {
      document.body.style.cursor='wait'
      const vm = this
      const apiURL = process.env.VUE_APP_API_URL + '/api/stores/webhooks/test-event/' + vm.webhook.id + '/'
      return this.$http({
        method: 'POST',
        url: apiURL,
      })
      .then(function () {
        vm.$toastr.s("Event was executed successfully")
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        } else {
          vm.$toastr.e(error)
        }
      })
      .finally(() => {
        document.body.style.cursor='default'
      })
    },
    deleteWebhook() {
      document.body.style.cursor='wait'
      const vm = this
      const apiURL = process.env.VUE_APP_API_URL + '/api/stores/' + vm.store.id + '/webhooks/' + vm.webhook.id + '/'
      return this.$http({
        method: 'DELETE',
        url: apiURL,
      })
      .then(function () {
        vm.retrieveWebhooks()
        vm.closeWebhookModal()
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        }
        vm.$toastr.e(error)
      })
      .finally(() => {
        document.body.style.cursor='default'
      })
    }
  },
}

</script>

<style scoped>
.sam-table-icon {
  position: absolute;
  right: 25px;
  opacity: 0.3;
  transition: 0.3s;
}
.sam-table-icon:hover {
  opacity: 1;
}
</style>
